import "./CustomTable.css";
import React, { useContext, useState, useEffect } from "react";
import TableScrollbar from "react-table-scrollbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {
  Box,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GlobalData } from "../App";
import { AnimatePresence, motion } from "framer-motion";
import ProtectComponent from "../middleware/ProtectComponent";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getOrderById } from "../api/OrderAPI";
import { FcPaid } from "react-icons/fc";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      paddingTop: "-10px",
      height: "40px",
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      paddingTop: "-10px",
      height: "40px",
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      height: "40px",
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

function CustomTable(props) {
  const {
    baseUrl,
    navigate,
    showLoader,
    hideLoader,
    flashMessage,
  } = useContext(GlobalData);

  const [showDesc, setShowDesc] = useState([]);
  const [showAllDesc, setShowAllDesc] = useState(false);
  const [getData, setGetData] = useState(-1);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      setGetData(1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (getData > -1) {
      setShowDesc([...props.data]);
    }
  }, [getData]);

  const handleShowDesc = (index) => {
    if (index === selectedRowIndex) {
      setSelectedRowIndex(index + 1);
    } else {
      setSelectedRowIndex(index);
    }
    setShowDesc((sd) => {
      if (sd[index] !== undefined) {
        if (
          sd[index].showDescription !== undefined
            ? sd[index].showDescription !== "true"
            : true
        ) {
          sd[index].showDescription = "true";
        } else {
          sd[index].showDescription = "false";
        }
      }
      return sd;
    });
  };

  const viewOptionClicked = (d) => {
    props.view(d);
  };

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  function getDate(created_at) {
    let date = new Date(created_at);
    var d = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var strDate = d + "-" + month + "-" + year;
    return strDate;
  }

  function getTime(created_at) {
    let date = new Date(created_at);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + seconds + " " + ampm;
    return strTime;
  }

  const handleUpdateStatus = (status, id, desc) => {
    props.updateStatus(status, id, desc);
  };

  const handleNavigateToOrderDetail = (data) => {
    if (data["transaction_type"] === "Order Debit") {
      let formData = {
        order_id: data["order_id"],
      };
      showLoader();
      getOrderById(formData, (resp) => {
        if (resp.statusCode === 200) {
          navigate("/orders/order-detail", {
            state: { orderData: resp.successData.order, orderStatus: "All" },
          });
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    } else {
    }
  };

  return (
    <div id="table_scrollbar__inventory">
      <TableScrollbar>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ marginBottom: "30px" }}
        >
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className="header__custom_table fontColorLight_header__custom_table"
                >
                  {column.label}
                </TableCell>
              ))}
              {props.hideActions !== true && (
                <TableCell
                  className="header__custom_table fontColorDark_header__custom_table"
                  align="right"
                  style={{ minWidth: "60px" }}
                >
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => {
              return (
                <>
                  <span className="row_space__custom_table" />
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    id="body_row__custom_table"
                    className={`${props.rowRed !== undefined &&
                      ((props.rowRed.condition === "="
                      ? row[props.rowRed.id] === props.rowRed.value
                      : props.rowRed.condition === ">"
                      ? row[props.rowRed.id] > props.rowRed.value
                      : props.rowRed.condition === "<"
                      ? row[props.rowRed.id] < props.rowRed.value
                      : props.rowRed.condition === ">="
                      ? row[props.rowRed.id] >= props.rowRed.value
                      : props.rowRed.condition === "<="
                      ? row[props.rowRed.id] <= props.rowRed.value
                      : row[props.rowRed.id] !== props.rowRed.value)
                        ? "mark_red__custom_table"
                        : "")}`}
                  >
                    {props.columns.map((column, ci) => {
                      const value = row[column.id];

                      if (value !== null) {
                        return (
                          <TableCell
                            style={
                              ci === 0
                                ? {
                                    borderTopLeftRadius: "20px",
                                    borderBottomLeftRadius: "20px",
                                    borderLeft:
                                      "1.5px solid rgba(0, 0, 0, 0.05)",
                                  }
                                : {}
                            }
                            key={column.id}
                            align={column.align}
                            className="cell__custom_table"
                            onClick={() => {
                              handleNavigateToOrderDetail(row);
                            }}
                          >
                            {column.id === "description" ? (
                              <>
                                {row["name"]}
                                <br />
                                {value}
                              </>
                            ) : column.id === "image" ? (
                              <>
                                <img
                                  alt=""
                                  src={baseUrl + value}
                                  width="40px"
                                  height="40px"
                                  style={{
                                    borderRadius: "20px",
                                    border: "1px solid rgba(0,0,0,0.2)",
                                  }}
                                />
                              </>
                            ) : column.id === "sr_no" ? (
                              <Box
                                color={`${
                                  row["is_any_variant_cost_price_zero"] !==
                                    undefined &&
                                  row["is_any_variant_cost_price_zero"] !== null
                                    ? row["is_any_variant_cost_price_zero"]
                                      ? "red"
                                      : ""
                                    : ""
                                }`}
                              >
                                {row["invoice_code"] !== undefined &&
                                row["invoice_code"] !== null ? (
                                  <Box display="flex">
                                    {pad(index + 1)}
                                    {row.is_order_has_gift ? (
                                      <FcPaid size={28} style={{marginLeft:'5px',marginTop:'-5px'}} />
                                    ) : null}
                                  </Box>
                                ) : (
                                  pad(index + 1)
                                )}
                              </Box>
                            ) : column.type === "date" ||
                              column.type === "date&time" ? (
                              `${getDate(value)}
                              ${
                                column.type === "date&time"
                                  ? `/ ${getTime(value)}`
                                  : ""
                              }
                              `
                            ) : (
                              <>
                                {column.id === "permissions" ? (
                                  <Box className="lngtxt__dsh">{value}</Box>
                                ) : column.id === "name" ||
                                  column.id === "invoice_code" ? (
                                  <span
                                    onClick={() => {
                                      viewOptionClicked(row);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {value}
                                  </span>
                                ) : column.id === "status" ? (
                                  <Box position="relative">
                                    {/* {index === 0 && (
                                    <>
                                      {value === "Cancelled" ? (
                                        <Box position="absolute">
                                          <Checkbox
                                            style={{
                                              top: "-59px",
                                              right: "-40px",
                                              zIndex: "9",
                                            }}
                                            checked={showAllDesc}
                                            onClick={() => {
                                              handleShowAllDesc();
                                            }}
                                          />
                                        </Box>
                                      ) : (
                                        <Box position="absolute">
                                          <Checkbox
                                            style={{
                                              top: "-68px",
                                              right: "-40px",
                                              zIndex: "9",
                                            }}
                                            checked={showAllDesc}
                                            onClick={() => {
                                              handleShowAllDesc();
                                            }}
                                          />
                                        </Box>
                                      )}
                                    </>
                                  )} */}
                                    {value === "Cancelled" ? (
                                      <>
                                        <Button
                                          variant="outlined"
                                          onClick={() => {
                                            handleShowDesc(index);
                                          }}
                                          className="ord_st_desc__orders"
                                        >
                                          {value}
                                        </Button>
                                        <AnimatePresence>
                                          <motion.div
                                            initial={{
                                              height: "0px",
                                              width: "0px",
                                              opacity: 0,
                                            }}
                                            animate={
                                              showAllDesc ||
                                              (showDesc.length > 0 &&
                                              showDesc[index] !== undefined
                                                ? showDesc[index]
                                                    .showDescription !==
                                                  undefined
                                                  ? showDesc[index]
                                                      .showDescription ===
                                                    "true"
                                                  : false
                                                : false)
                                                ? {
                                                    height: "100%",
                                                    width: "200px",
                                                    opacity: 1,
                                                    transition: {
                                                      duration: 0.5,
                                                    },
                                                  }
                                                : {
                                                    height: "0px",
                                                    width: "0px",
                                                    opacity: 0,
                                                    transition: {
                                                      duration: 0.5,
                                                    },
                                                  }
                                            }
                                            exit={{
                                              height: "0px",
                                              width: "0px",
                                              opacity: 0,
                                            }}
                                          >
                                            <br />
                                            <Box className="ord_desc__orders">
                                              {row["transaction_description"]}
                                            </Box>
                                          </motion.div>
                                        </AnimatePresence>
                                      </>
                                    ) : (
                                      value
                                    )}
                                  </Box>
                                ) : value === "cash_in" ? (
                                  "Cash In"
                                ) : value === "cash_out" ? (
                                  "Cash Out"
                                ) : column.type === "dropDown" ? (
                                  row["transaction_type"] === "Cheque" ? (
                                    <FormControl className="textField_status__bank">
                                      <CustomTextField
                                        select
                                        value={value}
                                        name="status"
                                        id="status"
                                        key="status"
                                        className="status_txt__bank"
                                        SelectProps={{
                                          IconComponent: () => (
                                            <ExpandMoreIcon className="bnk_sts_icon__cstbl" />
                                          ),
                                        }}
                                      >
                                        {column.options.map((option) => (
                                          <MenuItem
                                            className=""
                                            key={option}
                                            value={option}
                                            onClick={() => {
                                              handleUpdateStatus(
                                                option,
                                                row.id,
                                                row.transaction_description
                                              );
                                            }}
                                          >
                                            {option}
                                          </MenuItem>
                                        ))}
                                      </CustomTextField>
                                    </FormControl>
                                  ) : (
                                    <Box textAlign="center">--</Box>
                                  )
                                ) : column.id === "transaction_type" ? (
                                  <>
                                    <Button
                                      variant="outlined"
                                      onClick={() => {
                                        handleShowDesc(index);
                                      }}
                                      className="ord_st_desc__orders"
                                    >
                                      {value}
                                    </Button>
                                    <AnimatePresence>
                                      <motion.div
                                        initial={{
                                          height: "0px",
                                          width: "0px",
                                          opacity: 0,
                                        }}
                                        animate={
                                          showAllDesc ||
                                          (showDesc.length > 0 &&
                                          showDesc[index] !== undefined
                                            ? showDesc[index]
                                                .showDescription !== undefined
                                              ? showDesc[index]
                                                  .showDescription === "true"
                                              : false
                                            : false)
                                            ? {
                                                height: "100%",
                                                width: "200px",
                                                opacity: 1,
                                                transition: {
                                                  duration: 0.5,
                                                },
                                              }
                                            : {
                                                height: "0px",
                                                width: "0px",
                                                opacity: 0,
                                                transition: {
                                                  duration: 0.5,
                                                },
                                              }
                                        }
                                        exit={{
                                          height: "0px",
                                          width: "0px",
                                          opacity: 0,
                                        }}
                                      >
                                        <br />
                                        <Box className="ord_desc__orders">
                                          {row["cheque_date"] != null ? (
                                            <>
                                              {row["cheque_date"].substring(
                                                0,
                                                16
                                              )}{" "}
                                              -
                                            </>
                                          ) : (
                                            ""
                                          )}{" "}
                                          {row["transaction_description"]}
                                        </Box>
                                      </motion.div>
                                    </AnimatePresence>
                                  </>
                                ) : column.id === "remaining_no_of_days" ? (
                                  row["transaction_type"] === "Cheque" ? (
                                    value > 10 ? (
                                      <Box className="chds_crd_scc__cstbl">
                                        {value}
                                      </Box>
                                    ) : value > 5 && value < 11 ? (
                                      <Box className="chds_crd_wrn__cstbl">
                                        {value}
                                      </Box>
                                    ) : (
                                      <Box className="chds_crd_err__cstbl">
                                        {value}
                                      </Box>
                                    )
                                  ) : (
                                    <Box textAlign="center">--</Box>
                                  )
                                ) : column.id === "transaction_id" ? (
                                  value === null || value === "" ? (
                                    <Box textAlign="center">--</Box>
                                  ) : (
                                    <Box textAlign="center">{value}</Box>
                                  )
                                ) : column.id === "variant_sale_prices" ? (
                                  <Box className="brlngtxt__dsh">{value}</Box>
                                ) : (
                                  <Box>{value}</Box>
                                )}
                              </>
                            )}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell>
                            {" "}
                            <Box textAlign="center">--</Box>
                          </TableCell>
                        );
                      }
                    })}
                    {props.hideActions !== true && (
                      <TableCell
                        style={{
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                          borderRight: "1.5px solid rgba(0, 0, 0, 0.05)",
                        }}
                        align="right"
                        className="cell__custom_table"
                      >
                        <ActionMenu props={props} row={row} />
                      </TableCell>
                    )}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableScrollbar>
    </div>
  );
}

const ActionMenu = (aprops) => {
  let props = aprops.props;
  let row = aprops.row;

  const [data, setData] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const ITEM_HEIGHT = 48;

  const open = Boolean(anchorEl);

  const handleClick = (event, product) => {
    setAnchorEl(event.currentTarget);
    setData(product);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(()=>{

  //   console.log(row);
  // },[])

  const menuOptionClicked = (method) => {
    if (method === "view") {
      props.view(data);
    } else if (method === "edit") {
      props.edit(data);
    } else if (method === "status") {
      props.status(data);
    } else if (method === "delete") {
      props.delete(data);
    } else if (method === "return") {
      props.return(data);
    } else if (method === "print") {
      props.print(data);
    } else if (method === "viewOrders") {
      props.viewOrders(data);
    } else {
      console.log(method);
    }
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          handleClick(e, row);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: "20ch",
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.15)",
          },
        }}
      >
        {props.options.map((option, oi) =>
          row["status"] === "Returned" || row["status"] === "Cancelled" ? (
            option.method !== "status" && option.method !== "edit" ? (
              <Box
                style={{
                  borderTop: `${oi === 0 ? "1px solid rgba(0,0,0,0.15)" : ""}`,
                  paddingTop: `${oi === 0 ? "3px" : ""}`,
                }}
              >
                <ProtectComponent
                  id={`action_${option.method}`}
                  componentLocation={true}
                  componentNavigate={false}
                  component={
                    <MenuItem
                      key={option.method}
                      selected={option === "Pyxis"}
                      onClick={() => {
                        menuOptionClicked(option.method);
                      }}
                      style={{
                        borderBottom: "1px solid rgba(0,0,0,0.15)",
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <Box marginTop={1} marginRight={2}>
                        {option.icon}
                      </Box>
                      {option.type === "icon" && option.label}
                    </MenuItem>
                  }
                />
              </Box>
            ) : null
          ) : option.method !== "status" ||
            (row["status"] !== "Paid" || row["order_type"] !== "Credit") ? (
            <Box
              style={{
                borderTop: `${oi === 0 ? "1px solid rgba(0,0,0,0.15)" : ""}`,
                paddingTop: `${oi === 0 ? "3px" : ""}`,
              }}
            >
              <ProtectComponent
                id={`action_${option.method}`}
                componentLocation={true}
                componentNavigate={false}
                component={
                  <MenuItem
                    key={option.method}
                    selected={option === "Pyxis"}
                    onClick={() => {
                      menuOptionClicked(option.method);
                    }}
                    style={{
                      borderBottom: "1px solid rgba(0,0,0,0.15)",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <Box marginTop={1} marginRight={2}>
                      {option.icon}
                    </Box>
                    {option.type === "icon" && option.label}
                  </MenuItem>
                }
              />
            </Box>
          ) : null
        )}
      </Menu>
    </>
  );
};

export default CustomTable;
