import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { InputAdornment, Box } from "@mui/material";
import "./SearchField.css";

export default function SearchField(props) {
  const handleSearchField = (e) => {
    const search_text = e.target.value;
    props.onChange(search_text);
  };

  return (
    <Paper className="base__search_field" component="form">
      <IconButton sx={{ marginTop: "-1.5px" }} aria-label="menu">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_605_307)">
            <path
              d="M19.7561 18.5775L14.782 13.6033C16.1375 11.9455 16.804 9.83008 16.6435 7.69464C16.4831 5.55919 15.508 3.56711 13.92 2.13044C12.3319 0.69376 10.2525 -0.0775884 8.11167 -0.0240619C5.97088 0.0294646 3.93256 0.903771 2.41832 2.41801C0.904076 3.93225 0.0297698 5.97058 -0.0237567 8.11137C-0.0772832 10.2522 0.694065 12.3316 2.13074 13.9197C3.56742 15.5077 5.5595 16.4828 7.69494 16.6432C9.83038 16.8037 11.9458 16.1372 13.6036 14.7817L18.5778 19.7558C18.735 19.9076 18.9455 19.9916 19.164 19.9897C19.3825 19.9878 19.5915 19.9002 19.746 19.7457C19.9005 19.5912 19.9881 19.3822 19.99 19.1637C19.9919 18.9452 19.9079 18.7347 19.7561 18.5775ZM8.33364 15C7.0151 15 5.72617 14.609 4.62984 13.8765C3.53351 13.1439 2.67903 12.1027 2.17444 10.8846C1.66986 9.66638 1.53784 8.32594 1.79507 7.03273C2.05231 5.73953 2.68724 4.55164 3.61959 3.61929C4.55194 2.68694 5.73983 2.052 7.03304 1.79477C8.32624 1.53753 9.66669 1.66955 10.8849 2.17414C12.103 2.67872 13.1442 3.53321 13.8768 4.62953C14.6093 5.72586 15.0003 7.01479 15.0003 8.33333C14.9983 10.1008 14.2953 11.7954 13.0455 13.0452C11.7957 14.295 10.1011 14.998 8.33364 15Z"
              fill="#1E2738"
            />
          </g>
          <defs>
            <clipPath id="clip0_605_307">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </IconButton>
      <InputBase
        className="input__search_field"
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        onChange={handleSearchField}
        name="search"
        id="search"
        key="search"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        endAdornment={
          <InputAdornment position="end">
            <Box className="totalDataCount__srchfield">
              {props.tableDataCount}{props.tableDataCount!==undefined&&'/'}{props.totalAvailableDataCount}
            </Box>
          </InputAdornment>
        }
      />
    </Paper>
  );
}
