import "./Dashboard.css";
import {
  InventoryWorth,
  DashboardMobile,
  OrderStatus,
  StaffImageDashBoard,
  TsaleImageDashboard,
  TcashImageDashboard,
  TotalCreditImageDashboard,
  TotalDebitImageDashboard,
  TotalCashOut,
} from "../assets/SVG/DashboardSVG";
import { ReactComponent as MainCheque } from "../assets/SVG/MainChequeIcon.svg";
import { ReactComponent as SecndCheque } from "../assets/SVG/SecondaryChequeIcon.svg";
import { Textfit } from "react-textfit";
import { Grid, Box, Button, Menu, MenuItem } from "@mui/material";
import TableScrollbar from "react-table-scrollbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { GlobalData } from "../App";
import React, { useState, useEffect, useContext, useMemo } from "react";
import NavigationBar from "../components/NavigationBar";
import TotalProductPNG from "../assets/Images/totalProduct.png";
import TotalOrdersPNG from "../assets/Images/totalOrders.png";
import AutoScrollContainer from "auto-scroll-container";
import TotalProfit from "../assets/Images/totalProfit.png";
import { getDashboardData } from "../api/DashboardAPI";
import { DateRangePicker } from "mui-daterange-picker";

function Dashboard(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    onlyXs,
    smDown,
    mdDown,
    onlyMd,
    baseUrl,
  } = useContext(GlobalData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [dashboardDate, setDashboardDate] = useState("This Month");
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState({});

  const fittedTextWidth = "120px";

  const ITEM_HEIGHT = 48;

  const [dashboardData, setDashboardData] = useState("");
  const [totalInventory, setTotalInventory] = useState([]);
  const [
    totalInventoryGroupedByCategory,
    setTotalInventoryGroupedByCategory,
  ] = useState([]);
  const [ordersCount, setOrdersCount] = useState({
    Paid: 0,
    Unpaid: 0,
    Cancelled: 0,
    Returned: 0,
    All: 0,
  });

  const [accountInfo, setAccountsInfo] = useState({});

  const [allStaffMembers, setAllStaffMembers] = useState([]);

  const [cheques, setCheques] = useState([]);

  const ordersStatus = ["Paid", "Cancelled", "Unpaid", "Returned"];

  const handleGetDashboardData = () => {
    showLoader();
    let formData = new FormData();
    formData.append("date", JSON.stringify(dashboardDate));
    getDashboardData(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        setDashboardData(resp.successData);
        setTotalInventory(resp.successData.total_inventory);
        setTotalInventoryGroupedByCategory(
          resp.successData.total_inventory_grouped_by_category
        );
        setOrdersCount(resp.successData.orders_count);
        setAllStaffMembers(resp.successData.all_staff_members);
        setAccountsInfo(resp.successData.accounts_info);
        setCheques(resp.successData.cheques);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  useEffect(() => {
    handleGetDashboardData();
  }, []);

  const toggleDateRangerPicker = () =>
    setOpenDateRangePicker(!openDateRangePicker);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDashboardDateFilter = (option) => {
    handleClose();
    if (option === "Custom") {
      toggleDateRangerPicker();
    } else {
      setDashboardDate(option);
    }
  };

  useEffect(() => {
    handleGetDashboardData();
  }, [dashboardDate]);

  const handleDateRange = (range) => {
    setDateRange(range);
    setDashboardDate(range);
    setTimeout(() => {
      toggleDateRangerPicker();
    }, 500);
  };

  return (
    <Grid container className="container__dsh">
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <NavigationBar path={"Dashboard"} paddingBottom={3} />

        <Grid container marginBottom={2}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item />
        </Grid>

        <Grid container marginBottom={2}>
          <Grid
            xl={6}
            lg={6}
            md={5}
            sm={12}
            xs={12}
            item
            paddingRight={onlyXs ? "5px" : "10px"}
          >
            <Box className="i-title">Dashboard</Box>
          </Grid>
          <Grid
            xl={6}
            lg={6}
            md={7}
            sm={12}
            xs={12}
            item
            display="flex"
            justifyContent={smDown ? "center" : "end"}
            paddingTop={smDown ? 1 : 0}
            paddingRight={smDown ? "0px" : "10px"}
          >
            <Button
              variant="contained"
              endIcon={
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.34156 0.5H10.6582C10.823 0.500691 10.9839 0.550237 11.1206 0.642372C11.2572 0.734508 11.3635 0.865096 11.426 1.01762C11.4884 1.17015 11.5042 1.33777 11.4714 1.49928C11.4386 1.66079 11.3586 1.80894 11.2416 1.925L6.59156 6.575C6.51409 6.65311 6.42192 6.7151 6.32037 6.75741C6.21882 6.79972 6.1099 6.8215 5.99989 6.8215C5.88988 6.8215 5.78096 6.79972 5.67941 6.75741C5.57786 6.7151 5.48569 6.65311 5.40823 6.575L0.758225 1.925C0.641207 1.80894 0.561231 1.66079 0.52841 1.49928C0.495589 1.33777 0.511397 1.17015 0.573835 1.01762C0.636273 0.865096 0.742537 0.734508 0.87919 0.642372C1.01584 0.550237 1.17675 0.500691 1.34156 0.5Z"
                    fill="black"
                  />
                </svg>
              }
              className="download_report_btn__orders"
              onClick={handleClick}
            >
              {typeof dashboardDate === "string" ? dashboardDate : "Custom"}
            </Button>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 6,
                  width: "20ch",
                  borderRadius: "10px",
                  boxShadow: "none",
                  border: "1px solid rgba(0,0,0,0.15)",
                },
              }}
            >
              {["Today", "This Week", "This Month", "Custom"].map(
                (option, oi) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={() => {
                      handleDashboardDateFilter(option);
                    }}
                    style={
                      oi + 1 < 4
                        ? {
                            borderBottom: "1px solid rgba(0,0,0,0.15)",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }
                        : { paddingTop: 0, paddingBottom: 0 }
                    }
                  >
                    <Box marginTop={1} marginRight={2}>
                      {option}
                    </Box>
                  </MenuItem>
                )
              )}
            </Menu>
            {openDateRangePicker ? (
              <Box position="absolute" top="20%">
                <DateRangePicker
                  open={openDateRangePicker}
                  toggle={toggleDateRangerPicker}
                  onChange={(range) => handleDateRange(range)}
                />
              </Box>
            ) : null}
            <Button
              variant="contained"
              style={{ marginLeft: "10px" }}
              endIcon={
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.34156 0.5H10.6582C10.823 0.500691 10.9839 0.550237 11.1206 0.642372C11.2572 0.734508 11.3635 0.865096 11.426 1.01762C11.4884 1.17015 11.5042 1.33777 11.4714 1.49928C11.4386 1.66079 11.3586 1.80894 11.2416 1.925L6.59156 6.575C6.51409 6.65311 6.42192 6.7151 6.32037 6.75741C6.21882 6.79972 6.1099 6.8215 5.99989 6.8215C5.88988 6.8215 5.78096 6.79972 5.67941 6.75741C5.57786 6.7151 5.48569 6.65311 5.40823 6.575L0.758225 1.925C0.641207 1.80894 0.561231 1.66079 0.52841 1.49928C0.495589 1.33777 0.511397 1.17015 0.573835 1.01762C0.636273 0.865096 0.742537 0.734508 0.87919 0.642372C1.01584 0.550237 1.17675 0.500691 1.34156 0.5Z"
                    fill="black"
                  />
                </svg>
              }
              className="download_report_btn__orders"
            >
              {onlyXs || onlyMd ? (
                <div>
                  <div>Download</div>
                  <div style={{ marginTop: "-8px" }}>Report</div>
                </div>
              ) : (
                "Download Report"
              )}
            </Button>
          </Grid>
        </Grid>
        {/*Adding another Box Row At starting*/}
        <Box id="scroll__dsh">
          <TableScrollbar>
            <Table>
              <TableBody>
                <Box
                  className={`scone_con__dsh ${!smDown && "scone_br_con__dsh"}`}
                >
                  <Box
                    className="scone_box_con__dsh"
                    onClick={() => {
                      navigate("bank");
                    }}
                  >
                    <Box className="scone_box__dsh">
                      <Grid container marginLeft="16px">
                        <Grid item padding="5px" paddingRight="10px">
                          <MainCheque />
                        </Grid>
                        <Grid item padding="5px">
                          <Box>
                            <Box fontSize="14px">Total No. Of Cheques</Box>
                            <Box
                              fontSize="20px"
                              color="rgba(0,0,0,0.8)"
                              textAlign="start"
                              pt={1}
                            >
                              <Textfit
                                max={18}
                                style={{ width: "200px" }}
                                mode="single"
                              >
                                {cheques.length}
                              </Textfit>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box className="scone_box_con__dsh">
                    {cheques.map((value, index) => (
                      <>
                        {index % 2 === 0 ? (
                          <Box paddingLeft="10px">
                            <Box
                              className="scdbone_box__dsh"
                              marginBottom="6px"
                            >
                              <Grid container>
                                <Grid item padding="15px" paddingRight="10px">
                                  <SecndCheque />
                                </Grid>
                                <Grid
                                  item
                                  padding="5px"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Box
                                    marginTop="-10px"
                                    fontSize="20px"
                                    color="rgba(0,0,0,0.8)"
                                    className="lngtxt__dsh"
                                  >
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        color: "#1E2738",
                                      }}
                                    >
                                      {value.recipient_name}
                                    </span>
                                  </Box>
                                  <span
                                    style={{
                                      marginTop: "-1%",
                                      marginLeft: "5px",
                                      fontSize: "25px",
                                      color: "#1E2738",
                                    }}
                                  >
                                    {" "}
                                    &nbsp;{value["remaining_no_of_days"]}
                                  </span>
                                  <span
                                    style={{
                                      marginTop: "5px",
                                      marginLeft: "10px",
                                      fontSize: "8px",
                                      color: "black",
                                    }}
                                  >
                                    Days Left
                                  </span>
                                </Grid>
                              </Grid>
                            </Box>
                            {cheques[index + 1] !== undefined ? (
                              <Box
                                className="scdbone_box__dsh"
                                marginBottom="6px"
                              >
                                <Grid container>
                                  <Grid item padding="15px" paddingRight="10px">
                                    <SecndCheque />
                                  </Grid>
                                  <Grid
                                    item
                                    padding="5px"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Box
                                      marginTop="-10px"
                                      fontSize="20px"
                                      color="rgba(0,0,0,0.8)"
                                      className="lngtxt__dsh"
                                    >
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          color: "#1E2738",
                                        }}
                                      >
                                        {cheques[index + 1].recipient_name}
                                      </span>{" "}
                                    </Box>
                                    <span
                                      style={{
                                        marginTop: "-1%",
                                        marginLeft: "5px",
                                        fontSize: "25px",
                                        color: "#1E2738",
                                      }}
                                    >
                                      {" "}
                                      &nbsp;
                                      {cheques[index + 1].remaining_no_of_days}
                                    </span>
                                    <span
                                      style={{
                                        marginTop: "5px",
                                        marginLeft: "10px",
                                        fontSize: "8px",
                                        color: "black",
                                      }}
                                    >
                                      Days Left
                                      {/* {ordersCount[value]} */}
                                    </span>
                                  </Grid>
                                </Grid>
                              </Box>
                            ) : (
                              <Box className="scdbone_box_emty__dsh" />
                            )}
                          </Box>
                        ) : null}
                      </>
                    ))}
                  </Box>
                </Box>
              </TableBody>
            </Table>
          </TableScrollbar>
        </Box>
        {/* First Box Row Start */}
        <Box id="scroll__dsh">
          <TableScrollbar>
            <Table>
              <TableBody>
                <Box
                  className={`fsone_con__dsh ${!smDown && "fsone_br_con__dsh"}`}
                >
                  <Box
                    className="fsone_box_con__dsh"
                    onClick={() => {
                      navigate("products");
                    }}
                  >
                    <Box className="fsone_box__dsh">
                      <Box>
                        <Grid container marginLeft="16px">
                          <Grid item padding="5px" paddingRight="10px">
                            <InventoryWorth />
                          </Grid>
                          <Grid item padding="5px">
                            <Box>
                              <Box fontSize="14px">Inventory Worth</Box>
                              <Box fontSize="20px" color="rgba(0,0,0,0.8)">
                                <Textfit
                                  max={18}
                                  style={{ width: "200px" }}
                                  mode="single"
                                >
                                  {dashboardData.total_inventory_worth_cost} AED
                                </Textfit>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container marginLeft="16px">
                          <Grid item padding="5px" paddingRight="15px">
                            <img
                              alt=""
                              src={TotalProductPNG}
                              width="55"
                              height="55"
                            />
                          </Grid>
                          <Grid item padding="5px">
                            <Box>
                              <Box fontSize="14px">Total Products</Box>
                              <Box
                                fontSize="20px"
                                color="rgba(0,0,0,0.8)"
                                pt={1}
                              >
                                <Textfit
                                  max={18}
                                  style={{ width: "200px" }}
                                  mode="single"
                                >
                                  {dashboardData.total_inventory_products_count}{" "}
                                  AED
                                </Textfit>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="fsone_box_con__dsh">
                    {totalInventoryGroupedByCategory.map((value, index) => (
                      <>
                        {index % 2 === 0 ? (
                          <Box paddingLeft="10px">
                            <Box
                              className="fsdbone_box__dsh"
                              marginBottom="6px"
                            >
                              <Grid
                                container
                                paddingTop="10px"
                                paddingLeft="10px"
                              >
                                <Grid item padding="5px" paddingRight="10px">
                                  <img
                                    alt=""
                                    src={baseUrl + value.image}
                                    width="55"
                                    height="55"
                                    style={{ borderRadius: "50%" }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  padding="5px"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Box>
                                    <Box
                                      fontSize="14px"
                                      className="lngtxt__dsh"
                                    >
                                      {value.category}
                                    </Box>
                                    <Box
                                      fontSize="20px"
                                      color="rgba(0,0,0,0.8)"
                                    >
                                      <Textfit
                                        max={18}
                                        style={{ width: "210px" }}
                                        mode="single"
                                      >
                                        {value.total_cost !== null
                                          ? value.total_cost
                                          : 0}{" "}
                                        AED
                                      </Textfit>
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          color: "#1E2738",
                                        }}
                                      />

                                      <Textfit
                                        max={18}
                                        style={{ width: "210px" }}
                                        mode="single"
                                      >
                                        {value.quantity !== null
                                          ? value.quantity
                                          : 0}{" "}
                                        Pcs
                                      </Textfit>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            {totalInventoryGroupedByCategory[index + 1] !==
                            undefined ? (
                              <Box className="fsdbone_box__dsh">
                                <Grid
                                  container
                                  paddingTop="10px"
                                  paddingLeft="10px"
                                >
                                  <Grid item padding="5px" paddingRight="10px">
                                    <img
                                      alt=""
                                      src={
                                        baseUrl +
                                        totalInventoryGroupedByCategory[
                                          index + 1
                                        ].image
                                      }
                                      width="55"
                                      height="55"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    padding="5px"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Box>
                                      <Box fontSize="14px">
                                        {
                                          totalInventoryGroupedByCategory[
                                            index + 1
                                          ].category
                                        }
                                      </Box>
                                      <Box
                                        fontSize="20px"
                                        color="rgba(0,0,0,0.8)"
                                      >
                                        <Textfit
                                          max={18}
                                          style={{ width: "210px" }}
                                          mode="single"
                                        >
                                          {totalInventoryGroupedByCategory[
                                            index + 1
                                          ].total_cost !== null
                                            ? totalInventoryGroupedByCategory[
                                                index + 1
                                              ].total_cost
                                            : 0}{" "}
                                          AED
                                        </Textfit>
                                        <Textfit
                                          max={18}
                                          style={{ width: "210px" }}
                                          mode="single"
                                        >
                                          {totalInventoryGroupedByCategory[
                                            index + 1
                                          ].quantity !== null
                                            ? totalInventoryGroupedByCategory[
                                                index + 1
                                              ].quantity
                                            : 0}{" "}
                                          Pcs
                                        </Textfit>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            ) : (
                              <Box className="fsdbone_box_emty__dsh" />
                            )}
                          </Box>
                        ) : null}
                      </>
                    ))}
                  </Box>
                </Box>
              </TableBody>
            </Table>
          </TableScrollbar>
        </Box>
        {/* First Box Row End */}
        {/* Second Box Row Start */}

        <Box id="scroll__dsh">
          <TableScrollbar>
            <Table>
              <TableBody>
                <Box
                  className={`scone_con__dsh ${!smDown && "scone_br_con__dsh"}`}
                >
                  <Box
                    className="scone_box_con__dsh"
                    onClick={() => {
                      navigate("orders");
                    }}
                  >
                    <Box className="scone_box__dsh">
                      <Grid container marginLeft="16px">
                        <Grid item padding="5px" paddingRight="10px">
                          <img
                            alt=""
                            src={TotalOrdersPNG}
                            width="55"
                            height="55"
                          />
                        </Grid>
                        <Grid item padding="5px">
                          <Box>
                            <Box fontSize="14px">Total No. Of Orders</Box>
                            <Box
                              fontSize="20px"
                              color="rgba(0,0,0,0.8)"
                              textAlign="start"
                              pt={1}
                            >
                              <Textfit
                                max={18}
                                style={{ width: "200px" }}
                                mode="single"
                              >
                                <span>{ordersCount.All}</span>
                              </Textfit>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box className="scone_box_con__dsh">
                    {ordersStatus.map((value, index) => (
                      <>
                        {index % 2 === 0 ? (
                          <Box paddingLeft="10px">
                            <Box
                              className="scdbone_box__dsh"
                              marginBottom="6px"
                            >
                              <Grid container>
                                <Grid item padding="5px" paddingRight="10px">
                                  <OrderStatus />
                                </Grid>
                                <Grid
                                  item
                                  padding="5px"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Box
                                    marginTop="-10px"
                                    fontSize="20px"
                                    color="rgba(0,0,0,0.8)"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        color: "#1E2738",
                                      }}
                                    >
                                      {value}
                                    </span>
                                    &nbsp; - &nbsp;
                                    <Textfit
                                      max={18}
                                      style={{ width: "140px" }}
                                      mode="single"
                                    >
                                      {ordersCount[value]}
                                    </Textfit>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            {ordersStatus[index + 1] !== undefined ? (
                              <Box
                                className="scdbone_box__dsh"
                                marginBottom="6px"
                              >
                                <Grid container>
                                  <Grid item padding="5px" paddingRight="10px">
                                    <OrderStatus />
                                  </Grid>
                                  <Grid
                                    item
                                    padding="5px"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Box
                                      marginTop="-10px"
                                      fontSize="20px"
                                      color="rgba(0,0,0,0.8)"
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          color: "#1E2738",
                                        }}
                                      >
                                        {ordersStatus[index + 1]}
                                      </span>
                                      &nbsp; - &nbsp;
                                      <Textfit
                                        max={18}
                                        style={{ width: "120px" }}
                                        mode="single"
                                      >
                                        {ordersCount[ordersStatus[index + 1]]}
                                      </Textfit>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            ) : (
                              <Box className="scdbone_box_emty__dsh" />
                            )}
                          </Box>
                        ) : null}
                      </>
                    ))}
                  </Box>
                </Box>
              </TableBody>
            </Table>
          </TableScrollbar>
        </Box>

        {/* Second Box Row End */}
        {/* Third Box Row Start */}

        <Box id="scroll__dsh">
          <TableScrollbar>
            <Table>
              <TableBody>
                <Box
                  className={`scone_con__dsh ${!smDown && "scone_br_con__dsh"}`}
                >
                  <Box
                    className="throne_box_con__dsh"
                    onClick={() => {
                      navigate("vendors");
                    }}
                  >
                    <Box>
                      <Box className="throne_box1__dsh" marginBottom="8px">
                        <Grid container paddingTop="21px" paddingLeft="10px">
                          <Grid item padding="5px" paddingRight="10px">
                            <TotalCreditImageDashboard />
                          </Grid>
                          <Grid
                            item
                            padding="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box>
                              <Box fontSize="14px">Total Today's Credit</Box>
                              <Box
                                fontSize="20px"
                                color="rgba(0,0,0,0.8)"
                                pt={1}
                              >
                                <Textfit
                                  max={18}
                                  style={{ width: "200px" }}
                                  mode="single"
                                >
                                  {dashboardData.total_vendors_credit} AED
                                </Textfit>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="throne_box2__dsh">
                        <Grid container paddingTop="21px" paddingLeft="10px">
                          <Grid item padding="5px" paddingRight="10px">
                            <TotalDebitImageDashboard />
                          </Grid>
                          <Grid
                            item
                            padding="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box>
                              <Box fontSize="14px">Total Today's Debit</Box>
                              <Box
                                fontSize="20px"
                                color="rgba(0,0,0,0.8)"
                                pt={1}
                              >
                                <Textfit
                                  max={18}
                                  style={{ width: "200px" }}
                                  mode="single"
                                >
                                  {dashboardData.total_vendors_debit} AED
                                </Textfit>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="throne_box_con__dsh"
                    onClick={() => {
                      navigate("accounts");
                    }}
                  >
                    <Box paddingLeft="10px">
                      <Box className="thrdbone_box__dsh" marginBottom="6px">
                        <Grid container>
                          <Grid item padding="5px" paddingRight="10px">
                            <TsaleImageDashboard />
                          </Grid>
                          <Grid
                            item
                            padding="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              marginTop="-10px"
                              fontSize="20px"
                              color="rgba(0,0,0,0.8)"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#1E2738",
                                }}
                              >
                                Total Incoming Cash
                              </span>
                              <Textfit
                                max={18}
                                style={{ width: "230px" }}
                                mode="single"
                              >
                                {parseFloat(
                                  accountInfo.total_orders_sale_bank_amount
                                ) +
                                  parseFloat(
                                    accountInfo.total_bank_vat_amount
                                  ) +
                                  parseFloat(
                                    accountInfo.total_debit_amount_bank
                                  ) +
                                  (parseFloat(
                                    accountInfo.total_orders_sale_cash_amount
                                  ) +
                                    parseFloat(
                                      accountInfo.total_cash_in_amount
                                    ) +
                                    parseFloat(
                                      accountInfo.total_cash_vat_amount
                                    ))}{" "}
                                AED
                              </Textfit>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="thrdbone_box__dsh" marginBottom="6px">
                        <Grid container>
                          <Grid item padding="5px" paddingRight="10px">
                            <TotalCashOut />
                          </Grid>
                          <Grid
                            item
                            padding="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              marginTop="-10px"
                              fontSize="20px"
                              color="rgba(0,0,0,0.8)"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#1E2738",
                                }}
                              >
                                Total Expense
                              </span>
                              <Textfit
                                max={18}
                                style={{ width: "230px" }}
                                mode="single"
                              >
                                {parseFloat(
                                  accountInfo.total_expense_amount_cash
                                ) +
                                  parseFloat(
                                    accountInfo.total_expense_amount_bank
                                  )}{" "}
                                AED
                              </Textfit>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="thrdbone_box__dsh" marginBottom="6px">
                        <Grid container>
                          <Grid item padding="5px" paddingRight="10px">
                            <img
                              alt=""
                              src={TotalProfit}
                              width="44"
                              height="44"
                            />
                          </Grid>
                          <Grid
                            item
                            padding="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              marginTop="-10px"
                              fontSize="20px"
                              color="rgba(0,0,0,0.8)"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#1E2738",
                                }}
                              >
                                Total Orders Sale Profit
                              </span>
                              <Textfit
                                max={18}
                                style={{ width: "230px" }}
                                mode="single"
                              >
                                {accountInfo.total_orders_sale_profit} AED
                              </Textfit>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="thrdbone_box__dsh" marginBottom="6px">
                        <Grid container>
                          <Grid item padding="5px" paddingRight="10px">
                            <img
                              alt=""
                              src={TotalProfit}
                              width="44"
                              height="44"
                            />
                          </Grid>
                          <Grid
                            item
                            padding="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              marginTop="-10px"
                              fontSize="20px"
                              color="rgba(0,0,0,0.8)"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#1E2738",
                                }}
                              >
                                Total profit
                              </span>
                              <Textfit
                                max={18}
                                style={{ width: "230px" }}
                                mode="single"
                              >
                                {accountInfo.totalProfit} AED
                              </Textfit>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="throne_box_con__dsh">
                    <Box
                      className="thrstf_box__dsh"
                      onClick={() => {
                        navigate("staff");
                      }}
                    >
                      <Grid
                        padding="10px"
                        paddingBottom="5px"
                        container
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Box display="flex" alignItems="center">
                            <StaffImageDashBoard />
                            <Box paddingLeft="10px">Total Staff</Box>
                          </Box>
                        </Grid>

                        <Grid item>
                          <Box
                            display="flex"
                            alignItems="center"
                            paddingTop="8px"
                            paddingLeft="10px"
                            paddingRight="10px"
                          >
                            <Box fontSize="20px" marginRight="5px">
                              {allStaffMembers.length}
                            </Box>
                            <Box>Members</Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box paddingRight="16px" paddingLeft="16px">
                        <hr
                          style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                        />
                      </Box>
                      <AutoScrollContainer marginTop={0.01}>
                        <Box className="stf_con__dsh">
                          {allStaffMembers.map((value, index) => (
                            <Grid
                              padding="20px"
                              paddingTop="5px"
                              paddingBottom="5px"
                              container
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Grid item>
                                <Box display="flex" alignItems="center">
                                  {value.member_name}
                                </Box>
                              </Grid>

                              <Grid item>
                                <Box display="flex" alignItems="center">
                                  <Box color="rgb(0,0,0,0.8)">
                                    {value.member_phone}
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      </AutoScrollContainer>
                    </Box>
                  </Box>
                </Box>
              </TableBody>
            </Table>
          </TableScrollbar>
        </Box>
        {/* Third Box Row End */}
      </Grid>
    </Grid>
  );
}

export default Dashboard;
