import { get, post } from "./API";

export function register(data, callback) {
  post({ endPoint: "auth/register" }, data, function(response) {
    callback(response);
  });
}

export function login(data, callback) {
  post({ endPoint: "auth/login" }, data, function(response) {
    callback(response);
  });
}

export function logout(data, callback) {
  post({ endPoint: "auth/logout" }, data, function(response) {
    callback(response);
  });
}

export const isAuthenticated = JSON.parse(localStorage.getItem("user"))?true:false;