import "./Welcome.css";
import anime from "animejs";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import logo from "../assets/Images/logo.png"

function Welcome(props) {
  const [secondText, setSecondText] = useState(false);
  const [thirdText, setThirdText] = useState(false);

  function animate() {
    var tl = anime.timeline({
      easing: "easeOutExpo",
      duration: 5000,
    });
    tl.add({
      targets: "#textAnimation",
      opacity: [1, 0],
      easing: "easeInOutExpo",
      duration: 4000,
      direction: "linear",
    }).add({
      targets: "#signatureBackground",
      opacity: 0,
      zIndex: {
        value: [100, 0],
        round: true,
      },
      display: "none",
      duration: 1000,
    });
  }

  useEffect(() => {
    animate();
    setTimeout(() => {
      props.showWebsite();
    }, 3000);
    setTimeout(() => {
      setSecondText(true);
    }, 1000);
    setTimeout(() => {
      setThirdText(true);
    }, 2000);
  }, []);

  return (
    <Grid
      container
      style={{
        position: "absolute",
        backgroundColor: "#1E2738",
        width: "100vw",
        height: "100vh",
      }}
      id="signatureBackground"
    >
      <Grid
        xs={12}
        display="flex"
        justifyContent="center"
        item
        className="textContainer"
      >
        <div class="textCenter">
          <div class="textCenter">
            <div class="textCenter">
              <span class="firstText">
                <Typewriter
                  words={["Welcome to"]}
                  loop={1}
                  cursor
                  cursorStyle={!secondText?<img alt="" src={logo} width="80px" height="80px" style={{marginBottom:'-30px',marginLeft:'10px'}} />:''}
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
              <br />
              {secondText && (
                <span class="secondText">
                  <Typewriter
                    words={["Waqas Dubai Wala"]}
                    loop={1}
                    cursor
                    cursorStyle={!thirdText?<img alt="" src={logo} width="120px" height="120px" style={{marginBottom:'-30px',marginLeft:'10px'}} />:''}
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={1000}
                  />
                </span>
              )}
              <br />
              {thirdText && (
                <span class="thirdText">
                  <Typewriter
                    words={["Shop"]}
                    loop={1}
                    cursor
                    cursorStyle={<img alt="" src={logo} width="80px" height="80px" style={{marginBottom:'-10px',marginLeft:'5px'}} />}
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={1000}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default Welcome;
