import { get, post } from "./API";

export function orderInvoice(data, callback) {
  post({ endPoint: "order/report/orderInvoice" }, data, function(response) {
    callback(response);
  });
}

export function inventoryReport(data, callback) {
  post({ endPoint: "inventory/report/inventoryReport" }, data, function(
    response
  ) {
    callback(response);
  });
}
