let CryptoJS = require("crypto-js");

let CryptoJSAesJson = {
  stringify: function(cipherParams) {
    var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    if (cipherParams.iv) j.iv = cipherParams.iv.toString();
    if (cipherParams.salt) j.s = cipherParams.salt.toString();
    return JSON.stringify(j);
  },
  parse: function(jsonStr) {
    var j = JSON.parse(jsonStr);
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.ct),
    });
    if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
    if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
    return cipherParams;
  },
};

export const encrypt = (data) => {
  const key = process.env.REACT_APP_USER_ROLE_ENCRYPTION_KEY;
  let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    format: CryptoJSAesJson,
  }).toString();

  return encrypted;
};

export const decrypt = (data) => {
  const key = process.env.REACT_APP_USER_ROLE_ENCRYPTION_KEY;
  var decrypted = JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: CryptoJSAesJson,
    }).toString(CryptoJS.enc.Utf8)
  );
  return decrypted;
};
