import { get, post, delte } from "./API";

export function createTransaction(data, callback) {
  post({ endPoint: "expense/transaction/createTransaction" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function getAllTransaction(data, callback) {
  post({ endPoint: "expense/transaction/getAllTransaction" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function deleteTransaction(data, callback) {
  delte({ endPoint: "expense/transaction/deleteTransaction" }, data, function(
    response
  ) {
    callback(response);
  });
}
