import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Menu,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import "./AddOrder.css";
import { IoMdArrowDropleft } from "react-icons/io";
import { styled } from "@mui/material/styles";
import { GlobalData } from "../../App";
import { updateOrder } from "../../api/OrderAPI";
import NavigationBar from "../NavigationBar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import AutoScrollContainer from "auto-scroll-container";
import ProductMultiLevelMenu from "../../widgets/ProductMultiLevelMenu";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as GiftRedIcon } from "../../assets/SVG/GiftRedIcon.svg";
import { ReactComponent as DialogBoxDeleteIcon } from "../../assets/SVG/DialogBoxDeleteIcon.svg";
import Checkbox from "@mui/material/Checkbox";
import { FcRating, FcPaid } from "react-icons/fc";
import { GiReturnArrow } from "react-icons/gi";
import { AnimatePresence, motion } from "framer-motion";
import { borderRadius } from "@mui/system";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const orderObject = {
  order_type: "",
  invoice_type: "",
  tre_no: "",
  invoice_code: "",
  customer_name: "",
  customer_phone: "",
  order_date: "",
};

const productObject = {
  order: "0",
  product: "",
  variant: "",
  order_price: 0,
  total_order_price: 0,
  order_quantity: 1,
  product_imei: "",
  order_product_description: "",
  order_status: "Paid",
  order_gifted: false,
  order_return_quantity: 0,
  order_return_description: "",
};

// Text Field Styling

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const CustomTextFieldReturnedQuantity = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `transparent`,
    },
    "&:hover fieldset": {
      borderColor: `transparent`,
    },
    "&.Mui-focused fieldset": {
      borderColor: `transparent`,
    },
  },
});

function EditOrder(props) {
  const {
    flashMessage,
    smDown,
    query,
    navigate,
    showLoader,
    hideLoader,
    baseUrl,
    location,
  } = useContext(GlobalData);

  const [order, setOrder] = useState(orderObject);

  const [updateUi, setUpdateUi] = useState(1);

  const [orderProducts, setOrderProducts] = useState([]);
  const [product, setProduct] = useState(productObject);
  const [subTotal, setSubTotal] = useState(0);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [quantityGreaterThanError, setQuantityGreaterThanError] = useState(
    false
  );
  const [orderPriceSmallerThanError, setOrderPriceSmallerThanError] = useState(
    false
  );

  const [deleteProductDialog, setDeleteProductDialog] = useState(false);

  const [returnOrderDescription, setReturnOrderDescription] = useState("");
  const [selectedProductIndex, setSelectedProductIndex] = useState("");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [returnOrderBalance, setReturnOrderBalance] = useState(0);

  const [returnedOrderProducts, setReturnedOrderProducts] = useState([]);

  const updateOrderData = location.state.orderData;
  // Order Status Dialog Methods

  const handleReturnOrderDialogOpen = (index) => {
    setReturnOrderDescription(orderProducts[index].order_product_description);
    setSelectedOrderStatus(orderProducts[index].order_status);
    setSelectedProductIndex(index);
    setIsChangeOrderStatusDialogOpen(true);
  };

  const handleReturnOrderDialogClose = () => {
    setIsChangeOrderStatusDialogOpen(false);
  };

  const changeReturnOrderDescription = (e) => {
    setReturnOrderDescription(e.target.value);
  };

  const handleChangeOrderStatus = () => {
    let orderProductsData = orderProducts;
    orderProductsData[
      selectedProductIndex
    ].order_product_description = returnOrderDescription;
    orderProductsData[selectedProductIndex].order_status = selectedOrderStatus;
    setOrderProducts(orderProductsData);
    let totalOrderPrice = 0;
    orderProducts.map((v, i) => {
      if (v.order_status !== "Returned") {
        totalOrderPrice += Number(v.total_order_price);
      }
    });
    setSubTotal(totalOrderPrice);
    handleReturnOrderDialogClose();
  };

  const [
    isChangeOrderStatusDialogOpen,
    setIsChangeOrderStatusDialogOpen,
  ] = useState(false);

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const openDeleteProductDialog = (index) => {
    if (orderProducts[index].order !== "0") {
      setSelectedProductIndex(index);
      setDeleteProductDialog(true);
    } else {
      handleDeleteProductByIndex(index);
    }
  };

  // Input Change Handling Methods

  const handleOrderInputChange = (e) => {
    const { name, value } = e.target;
    setOrder({
      ...order,
      [name]: value,
    });
  };

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  // *** Update Order Methods ***
  // #########################################################################

  const handleUpdateOrder = () => {
    showLoader();
    let formData = new FormData();
    formData.append("order_id", updateOrderData.id);
    formData.append("order_type", order.order_type);
    formData.append("invoice_type", order.invoice_type);
    if (order.invoice_type === "With VAT") {
      formData.append("tre_no", order.tre_no);
    }
    formData.append("invoice_code", order.invoice_code);
    formData.append("customer_name", order.customer_name);
    formData.append("customer_phone", order.customer_phone);
    formData.append("order_date", order.order_date);
    formData.append("sub_total", subTotal);
    if (orderProducts.length > 0) {
      formData.append("products", JSON.stringify(orderProducts));
    }
    updateOrder(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        if (location.state.path === "/staff/orders") {
          navigate("/staff/orders", {
            state: {
              path: location.pathname,
              memberData: location.state.memberData,
            },
          });
        } else {
          navigate("/orders", {
            state: {
              path: location.pathname,
            },
          });
        }
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  // Use Effect

  useEffect(() => {
    showLoader();
    setOrder({
      ...order,
      ["order_type"]: updateOrderData.order_type,
      ["invoice_code"]: updateOrderData.invoice_code,
      ["tre_no"]: updateOrderData.tre_no,
      ["invoice_type"]: updateOrderData.invoice_type,
      ["customer_name"]: updateOrderData.customer_name,
      ["customer_phone"]: updateOrderData.customer_phone,
      ["order_date"]: updateOrderData.order_date,
    });
    updateOrderData.order_products.forEach((value, index) => {
      const po = {
        order: value,
        product: value.variant.inventory,
        variant: value.variant,
        order_price: value.order_price,
        total_order_price: value.total_order_price,
        order_quantity: value.quantity,
        product_imei: value.imei,
        order_product_description: value.description,
        order_status: value.status,
        order_gifted: value.order_gifted,
        order_returned: value.order_returned,
        order_return_quantity: value.order_returned_quantity,
        order_return_description: value.order_returned_description,
      };
      let productsData = orderProducts;
      productsData.push(po);
      setOrderProducts(productsData);
    });
    setSubTotal(updateOrderData.sub_total);
    if (updateOrderData.order_discount) {
      setTimeout(() => {
        let orderProductsData = [...orderProducts];
        orderProductsData.map((value, index) => {
          value.order_returned = 1;
          value.order_return_quantity = value.order_quantity;
        });
        hideLoader();
      }, 1000);
    } else {
      hideLoader();
    }
  }, []);

  useEffect(() => {
    if (Number(product.order_quantity) > Number(product.variant.quantity)) {
      setQuantityGreaterThanError(true);
    } else {
      setQuantityGreaterThanError(false);
      let total = Number(product.order_quantity) * product.order_price;
      setProduct({
        ...product,
        ["total_order_price"]: total,
      });
    }
  }, [product.order_quantity, product.order_price]);

  useEffect(() => {
    if (Number(product.order_price) < Number(product.variant.min_sale_price)) {
      setOrderPriceSmallerThanError(true);
    } else {
      setOrderPriceSmallerThanError(false);
    }
  }, [product.order_price]);

  // Add Product to Products and create Empty Product Fields

  const handleAddProduct = () => {
    if (product.product === "") {
      flashMessage("error", "Please Select Product");
    } else if (product.variant === "") {
      flashMessage("error", "Please Select Product Variant");
    } else if (product.order_price === "") {
      flashMessage("error", "Please Enter Product Price");
    } else if (product.order_quantity < 1) {
      flashMessage("error", "Please Enter Product Quantity");
    } else if (quantityGreaterThanError) {
      flashMessage(
        "error",
        "Product quantity is greater than the available stock!"
      );
    } else if (orderPriceSmallerThanError) {
      flashMessage(
        "error",
        "Product price must be greater than Min. Sale Price"
      );
    } else {
      let productsData = orderProducts;
      productsData.push(product);
      setOrderProducts(productsData);
      setProduct(productObject);
      let totalOrderPrice = 0;
      orderProducts.map((v, i) => {
        if (v.order_status !== "Returned") {
          totalOrderPrice += Number(v.total_order_price);
        }
      });
      setSubTotal(totalOrderPrice);
    }
  };

  // Delete Product

  const handleDeleteProduct = () => {
    setOrderProducts((current) =>
      current.filter((value, index) => {
        return index !== selectedProductIndex;
      })
    );
    setSubTotal(subTotal - orderProducts[selectedProductIndex].order_price);
    handleCloseDeleteDialog();
  };

  const handleDeleteProductByIndex = (i) => {
    setOrderProducts((current) =>
      current.filter((value, index) => {
        return index !== i;
      })
    );
    setSubTotal(subTotal - orderProducts[i].order_price);
  };

  // Edit Product

  const handleEditProduct = (id) => {
    setIsProductSelected(true);
    setSubTotal(subTotal - orderProducts[id].order_price);
    setProduct(orderProducts[id]);
    setOrderProducts((current) =>
      current.filter((value, index) => {
        return index !== id;
      })
    );
  };

  // Add Selected Product to Product From Product Multi Level Menu Component

  const addSelectedProduct = (p, v, i) => {
    setProduct({
      ...product,
      ["product"]: p,
      ["variant"]: v,
      ["product_imei"]: i,
      ["order_price"]: v.sale_price,
    });
  };

  const handleBackToOrders = () => {
    if (location.state.path === "/staff/orders") {
      navigate("/staff/orders", {
        state: {
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders");
    }
  };

  const getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  };

  const handleUpdateUi = () => {
    let count = updateUi;
    setUpdateUi((count += 1));
  };

  const handleMarkReturnProduct = (product_id) => {
    handleUpdateUi();
    setOrderProducts((pro) => {
      pro[product_id].order_returned = !pro[product_id].order_returned;
      return pro;
    });
  };

  const handleUpdateReturnOrderProductQuantity = (e, product_id) => {
    handleUpdateUi();
    setOrderProducts((pro) => {
      pro[product_id].order_return_quantity = e.target.value;
      return pro;
    });
  };

  const handleUpdateReturnOrderDescription = (e, product_id) => {
    handleUpdateUi();
    setOrderProducts((pro) => {
      pro[product_id].order_return_description = e.target.value;
      return pro;
    });
  };

  const handleCreateReturnOrder = () => {
    let error = false;
    orderProducts.every((v, i) => {
      if (v.order_returned || v.order_returned === 1) {
        if (Number(v.order_return_quantity) > Number(v.order_quantity)) {
          flashMessage(
            "error",
            "Return Quantity Greater than Actual Product Quantity!"
          );
          error = true;
          return false;
        } else if (Number(v.order_return_quantity) === 0) {
          flashMessage("error", "Product Return Quantity Cannot Be Zero!");
          error = true;
          return false;
        } else {
          returnedOrderProducts.push(v);
        }
      }
      return true;
    });
    if (!error) {
      if (returnedOrderProducts.length > 0) {
        let returnOrderBalance = 0;
        let tax_rate = 0;
        orderProducts.forEach((value, index) => {
          if (value.order_returned || value.order_returned === 1) {
            let total =
              Number(value.order_price) * Number(value.order_return_quantity);
            returnOrderBalance = returnOrderBalance + total;
            if (index === 0) {
              tax_rate = value.order.tax;
            }
          }
        });
        returnOrderBalance =
          returnOrderBalance - updateOrderData.order_discount;
        let returnOrderTax = returnOrderBalance * tax_rate;
        navigate("/orders/add-new-order", {
          state: {
            returnOrderProducts: returnedOrderProducts,
            order: order,
            returnOrderBalance: returnOrderBalance,
            returnOrderTax: returnOrderTax,
            order_id: updateOrderData.id,
          },
        });
      } else {
        flashMessage("error", "Please Select Return Product!");
      }
    }
    setReturnedOrderProducts([]);
  };

  return (
    <Grid container height="100vh" paddingRight="5px">
      {/* START ******************************* Container Column ******************************************** */}

      {/* Start Dialogs */}

      <Dialog
        open={isChangeOrderStatusDialogOpen}
        onClose={handleReturnOrderDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Order Return</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ marginBottom: "8px", marginTop: "16px" }}>Status</div>
            <FormControl className="textField__AddInventory">
              {selectedOrderStatus === "" && (
                <InputLabel sx={{ opacity: 0.6 }}>
                  Select Order Status
                </InputLabel>
              )}

              <CustomTextField
                select
                value={selectedOrderStatus}
                onChange={(e) => {
                  setSelectedOrderStatus(e.target.value);
                }}
                name="order_status"
                id="order_status"
                key="order_status"
              >
                {["Paid", "Returned"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomTextField>
            </FormControl>
            <div style={{ marginBottom: "8px", marginTop: "16px" }}>
              Description
            </div>
            <CustomTextField
              multiline
              rows={4}
              style={{ width: "" }}
              onChange={changeReturnOrderDescription}
              value={returnOrderDescription}
              name="description"
              id="description"
              key="description"
              placeholder="Write about product here"
              className="textField__AddInventory"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={handleReturnOrderDialogClose}
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            onClick={handleChangeOrderStatus}
          >
            Return Order
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={deleteProductDialog}
        onClose={handleCloseDeleteDialog}
        id="deleteProductDialog"
      >
        <DialogContent id="deleteProductDialogContent__inv">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              style={{ textAlign: "center" }}
            >
              <DialogBoxDeleteIcon />
              {/* <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "60px" }}
              >
                <circle cx="40" cy="40" r="40" fill="#FF0000" />
                <g clip-path="url(#clip0_605_8451)">
                  <path
                    d="M40 20C36.0444 20 32.1776 21.173 28.8886 23.3706C25.5996 25.5682 23.0362 28.6918 21.5224 32.3463C20.0087 36.0009 19.6126 40.0222 20.3843 43.9018C21.156 47.7814 23.0608 51.3451 25.8579 54.1421C28.6549 56.9392 32.2186 58.844 36.0982 59.6157C39.9778 60.3874 43.9992 59.9913 47.6537 58.4776C51.3082 56.9638 54.4318 54.4004 56.6294 51.1114C58.827 47.8224 60 43.9556 60 40C59.9943 34.6974 57.8853 29.6137 54.1358 25.8642C50.3863 22.1147 45.3026 20.0057 40 20V20ZM40 56.6667C36.7037 56.6667 33.4813 55.6892 30.7405 53.8578C27.9997 52.0265 25.8635 49.4235 24.602 46.3781C23.3406 43.3326 23.0105 39.9815 23.6536 36.7485C24.2967 33.5155 25.884 30.5458 28.2149 28.2149C30.5458 25.884 33.5155 24.2967 36.7485 23.6536C39.9815 23.0105 43.3326 23.3405 46.3781 24.602C49.4235 25.8635 52.0265 27.9997 53.8578 30.7405C55.6892 33.4813 56.6667 36.7036 56.6667 40C56.6618 44.4188 54.9043 48.6552 51.7798 51.7798C48.6552 54.9043 44.4188 56.6618 40 56.6667V56.6667Z"
                    fill="white"
                  />
                  <path
                    d="M40.0007 28.3333C39.5586 28.3333 39.1347 28.5089 38.8221 28.8215C38.5096 29.1341 38.334 29.558 38.334 30V43.3333C38.334 43.7754 38.5096 44.1993 38.8221 44.5119C39.1347 44.8244 39.5586 45 40.0007 45C40.4427 45 40.8666 44.8244 41.1792 44.5119C41.4917 44.1993 41.6673 43.7754 41.6673 43.3333V30C41.6673 29.558 41.4917 29.1341 41.1792 28.8215C40.8666 28.5089 40.4427 28.3333 40.0007 28.3333Z"
                    fill="white"
                  />
                  <path
                    d="M41.6673 50C41.6673 49.0795 40.9211 48.3333 40.0007 48.3333C39.0802 48.3333 38.334 49.0795 38.334 50C38.334 50.9205 39.0802 51.6667 40.0007 51.6667C40.9211 51.6667 41.6673 50.9205 41.6673 50Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_605_8451">
                    <rect
                      width="40"
                      height="40"
                      fill="white"
                      transform="translate(20 20)"
                    />
                  </clipPath>
                </defs>
              </svg> */}
              <Box className="deleteProductDialogActionText1_inv">
                Remove this Product?
              </Box>
              <Box className="deleteProductDialogActionText2_inv">
                You are about to remove a product. Click on the “Remove” button
                to remove it.
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="deleteDialogActions">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              className="deleteDialogBtn__inv deleteDialogBtnNo__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={handleCloseDeleteDialog}
            >
              Don’t Remove
            </Grid>
            <Grid
              className="deleteDialogBtn__inv deleteDialogBtnYes__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={handleDeleteProduct}
            >
              Remove
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* End Dialogs */}

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {/* START ******************************* 
                          Header Box that includes header and navigation bar

        ******************************************** */}

        <Box height={smDown ? "30vh" : "20vh"}>
          {/* START ******************************* Navigation Bar ******************************************** */}

          <NavigationBar
            path={`Dashboard>Orders Management>Orders>Update Order>${
              updateOrderData.invoice_code
            }`}
            paddingBottom={1}
          />

          {/* END ******************************* Navigation Bar ******************************************** */}

          {/* START ******************************* Header Row ******************************************** */}

          <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
            <Grid
              className="header__ai"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
            >
              <div className="header_back_btn__ai" onClick={handleBackToOrders}>
                <IoMdArrowDropleft
                  style={{ marginBottom: "-5px", fontSize: "20px" }}
                />
                Back
              </div>
              <div className="header_text__ai">
                Orders Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                <span style={smDown ? { fontSize: "14px" } : {}}>
                  Update Order
                </span>
              </div>
            </Grid>
          </Grid>

          {/* END ******************************* Header Row ******************************************** */}
        </Box>

        {/* END ******************************* 
        
                          Header Box that includes header and navigation bar

        ******************************************** */}

        {/* START ******************************* Content Box ******************************************** */}

        <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
          {/* START ******************************* 
                
                      Content Gradiant Box
                
                ******************************************** */}
          <Grid container className="card__ai">
            {/* START ******************************* 
                
                      Content container Inside the Gradiant Box
                
                ******************************************** */}
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingTop={query(2, 2, 2)}
              paddingBottom={query(3, 3, 3)}
              paddingLeft={query(5, 2, 1)}
              paddingRight={query(5, 2, 1)}
              id="addOrderStyle"
            >
              {/* START ******************************* 
                
                          Auto Scroll Container for vertical scrolling
                
                ******************************************** */}

              <AutoScrollContainer marginTop={0.01}>
                {/* START ******************************* 
                
                          Order Type, Invoice Type and Invoice Code Fields
                
                ******************************************** */}

                <Grid container display="flex" justifyContent="center">
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    <div style={{ marginBottom: "8px" }}>Order Type</div>
                    <FormControl className="textField__AddInventory">
                      {order.order_type === "" && (
                        <InputLabel sx={{ opacity: 0.6 }}>
                          Select Order Type
                        </InputLabel>
                      )}

                      <CustomTextField
                        select
                        value={order.order_type}
                        onChange={handleOrderInputChange}
                        name="order_type"
                        id="order_type"
                        key="order_type"
                        disabled
                      >
                        {["Card", "Cash", "Credit"].map((option, oi) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={
                              oi + 1 < 2
                                ? { borderBottom: "1px solid rgba(0,0,0,0.15)" }
                                : {}
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </FormControl>
                  </Grid>
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingRight={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>Invoice Type</div>

                    <FormControl className="textField__AddInventory">
                      {order.invoice_type === "" && (
                        <InputLabel sx={{ opacity: 0.6 }}>
                          Select Invoice Type
                        </InputLabel>
                      )}

                      <CustomTextField
                        select
                        value={order.invoice_type}
                        onChange={handleOrderInputChange}
                        name="invoice_type"
                        id="invoice_type"
                        key="invoice_type"
                        disabled
                      >
                        {["With VAT", "Without VAT"].map((option, oi) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={
                              oi + 1 < 2
                                ? { borderBottom: "1px solid rgba(0,0,0,0.15)" }
                                : {}
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </FormControl>
                  </Grid>

                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>Invoice Code</div>
                    <CustomTextField
                      value={order.invoice_code}
                      onChange={handleOrderInputChange}
                      name="invoice_code"
                      id="invoice_code"
                      key="invoice_code"
                      placeholder="Enter Invoice Code"
                      className="textField__AddInventory"
                      disabled
                    />
                  </Grid>
                </Grid>

                {/* END ******************************* 
                
                          Order Type, Invoice Type and Invoice Code Fields
                
                ******************************************** */}

                {/* START ******************************* 
                
                          TRE NO. Field
                
                ******************************************** */}

                {order.invoice_type === "With VAT" ? (
                  <Grid container mt={2}>
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      paddingRight={query(1, 1, 0)}
                      item
                    >
                      <div style={{ marginBottom: "8px" }}>TRE NO.</div>
                      <CustomTextField
                        value={order.tre_no}
                        onChange={handleOrderInputChange}
                        name="tre_no"
                        id="tre_no"
                        key="tre_no"
                        placeholder="Enter TRE Number"
                        className="textField__AddInventory"
                        disabled
                      />
                    </Grid>
                  </Grid>
                ) : null}

                {/* END ******************************* 
                
                          TRE NO. Field
                
                ******************************************** */}

                {/* START ******************************* 
                
                          Customer Name and Phone Fields
                
                ******************************************** */}

                <Grid
                  container
                  display="flex"
                  paddingTop={query(2, 2, 0)}
                  justifyContent="center"
                  paddingBottom={3}
                >
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Customer Name</div>
                    <CustomTextField
                      value={order.customer_name}
                      onChange={handleOrderInputChange}
                      name="customer_name"
                      id="customer_name"
                      key="customer_name"
                      placeholder="Customer Name"
                      className="textField__AddInventory"
                      disabled
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingLeft={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Customer Phone</div>
                    <CustomTextField
                      value={order.customer_phone}
                      onChange={handleOrderInputChange}
                      name="customer_phone"
                      id="customer_phone"
                      key="customer_phone"
                      placeholder="Customer Phone"
                      className="textField__AddInventory"
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid container display="flex" paddingBottom={3}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>Order Date</div>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="bnkmnu_field__bank"
                        value={order.order_date}
                        onChange={(newValue) => {
                          setOrder({
                            ...order,
                            ["order_date"]: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <CustomTextField error={false} {...params} />
                        )}
                      />
                    </LocalizationProvider> */}

                    <CustomTextField
                      value={order.order_date}
                      disabled
                      className="textField__AddInventory"
                    />
                  </Grid>
                </Grid>

                {/* END ******************************* 
                
                          Customer Name and Phone Fields
                
                ******************************************** */}

                {!smDown ? <hr /> : null}

                {/* START ******************************* 

                            Order Products Heading in Mobile Screen
                
                ******************************************** */}

                {smDown ? (
                  <>
                    <hr style={{ marginTop: "40px" }} />
                    <div style={{ marginBottom: "8px", textAlign: "center" }}>
                      Order Products
                    </div>
                    <hr style={{ marginBottom: "40px" }} />
                  </>
                ) : null}

                {/* END ******************************* 

                        Order Products Heading in Mobile Screen
                
                ******************************************** */}

                {/* START ******************************* 
                
                          Product List Fields Header

                          Like,

                          Product Name, Price, Quantity

                          For Laptop to Medium Screen
                
                
                ******************************************** */}

                {!smDown ? (
                  <Grid container mt={4}>
                    <Grid
                      xl={5}
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      paddingRight={query(1, 1, 0)}
                      item
                    >
                      <div style={{ marginBottom: "8px" }}>Product Name</div>
                    </Grid>
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={query(1, 1, 0)}
                      paddingRight={query(1, 1, 0)}
                      paddingTop={query(0, 0, 2)}
                    >
                      <div style={{ marginBottom: "8px" }}>Product Price</div>
                    </Grid>
                    <Grid
                      xl={2}
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={query(1, 1, 0)}
                      paddingTop={query(0, 0, 2)}
                    >
                      <div style={{ marginBottom: "8px" }}>Quantity</div>
                    </Grid>
                  </Grid>
                ) : null}

                {/* END ******************************* 
                
                          Product List Fields Header

                          Like,

                          Product Name, Price, Quantity

                          For Laptop to Medium Screen
                
                
                ******************************************** */}

                {/* START ******************************* Added Product List ******************************************** */}

                {orderProducts.map((pro, proi) => (
                  <Box position="relative">
                    <AnimatePresence>
                      <motion.div
                        initial={{
                          width: "0px",
                          opacity: 0,
                          zIndex: "9999",
                        }}
                        animate={
                          pro.order_returned === 1 || pro.order_returned
                            ? {
                                width: "100%",
                                zIndex: "9999",
                                opacity: 1,
                                transition: {
                                  duration: 0.5,
                                },
                              }
                            : {
                                width: "0px",
                                zIndex: "9999",
                                opacity: 0,
                                transition: {
                                  duration: 0.5,
                                },
                              }
                        }
                        exit={{
                          width: "0px",
                          opacity: 0,
                          zIndex: "9999",
                        }}
                      >
                        <Grid
                          position="absolute"
                          zIndex="999"
                          container
                          mt={0.2}
                          height={`
                        ${
                          pro.order_product_description.length > 0
                            ? "100px"
                            : "75px"
                        }
                        `}
                          top="-5px"
                        >
                          <Grid
                            xs={11}
                            backgroundColor="rgb(255,0,0,0.2)"
                            borderRadius="10px"
                            display="flex"
                            justifyContent="end"
                          >
                            <CustomTextFieldReturnedQuantity
                              disabled={
                                pro.order.order_returned ||
                                pro.order.order_returned === 1 ||
                                updateOrderData.order_discount > 0
                              }
                              style={{
                                width: "82px",
                                height: "53px",
                                marginTop: "4px",
                                marginRight: "2px",
                                borderRadius: "0px 10px 10px 0px",
                                backgroundColor: "white",
                              }}
                              error={
                                pro.order_return_quantity > pro.order_quantity
                                  ? true
                                  : false
                              }
                              value={pro.order_return_quantity}
                              onChange={(e) => {
                                handleUpdateReturnOrderProductQuantity(e, proi);
                              }}
                              name="order_return_quantity"
                              id="order_return_quantity"
                              key="order_return_quantity"
                              type="number"
                              InputProps={{
                                inputProps: { min: 0 },
                              }}
                              helperText={
                                pro.order_return_quantity >
                                pro.order_quantity ? (
                                  <Box
                                    color="red"
                                    fontWeight="600"
                                    fontSize={14}
                                    width="200px"
                                    marginTop={`
                                  ${
                                    pro.order_product_description.length > 0
                                      ? "5px"
                                      : "-3px"
                                  }
                                  `}
                                    marginLeft="-100px"
                                  >{`Product Quantity is ${
                                    pro.order_quantity
                                  }`}</Box>
                                ) : (
                                  ""
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </motion.div>
                    </AnimatePresence>

                    <Grid
                      key={proi}
                      container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                    >
                      {smDown ? (
                        <Grid
                          xl={1}
                          lg={1}
                          md={1}
                          sm={12}
                          xs={12}
                          item
                          paddingLeft={query(1, 1, 0)}
                          paddingTop={query(0, 0, 2)}
                          display="flex"
                          justifyContent="center"
                        >
                          {/* <ButtonGroup
                            orientation="horizontal"
                            variant="contained"
                            style={{
                              backgroundColor: "#ECAE33",
                              padding: "6px",
                              borderRadius: "10%",
                            }}
                          >
                            <HighlightOffIcon
                              style={{
                                cursor: "pointer",
                                paddingRight: "30px",
                                color: "white",
                              }}
                              onClick={() => {
                                openDeleteProductDialog(proi);
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                handleEditProduct(proi);
                              }}
                              style={{ cursor: "pointer", color: "white" }}
                            />
                          </ButtonGroup> */}
                          <IconButton size="large">
                            <GiReturnArrow
                              size={32}
                              style={{ cursor: "pointer", color: "#1E2738" }}
                            />
                          </IconButton>
                        </Grid>
                      ) : null}
                      <Grid
                        xl={5}
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                        paddingRight={query(1, 1, 0)}
                        item
                      >
                        {smDown ? (
                          <div style={{ marginBottom: "8px" }}>
                            Product Name
                          </div>
                        ) : null}
                        <Button
                          variant="outlined"
                          component="span"
                          style={{
                            width: "100%",
                            height: "54.5px",
                            backgroundColor: `${
                              pro.order_status === "Returned"
                                ? "#FFE8DE"
                                : "white"
                            }`,
                            borderColor: "rgb(0,0,0,0.25)",
                            color: "rgb(0,0,0,0.35)",
                            textTransform: "none",
                            fontSize: "16px",
                            justifyContent: "flex-start",
                            borderRadius: "10px",
                          }}
                          // onClick={() => {
                          //   handleReturnOrderDialogOpen(proi);
                          // }}
                        >
                          <img
                            alt=""
                            src={baseUrl + pro.product.image}
                            width="30px"
                            height="30px"
                            style={{
                              borderRadius: "5px",
                              border: "1px solid rgba(0,0,0,0.2)",
                              marginRight: "10px",
                            }}
                          />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                            alignItems="center"
                          >
                            <Box className="product_inv__addord">
                              {pro.product.name}
                              &nbsp;(
                              {pro.variant.color}
                              {pro.variant.storage !== "" &&
                              pro.variant.storage !== null
                                ? ","
                                : ""}
                              {pro.variant.storage !== null
                                ? pro.variant.storage
                                : ""}
                              {pro.variant.ram !== "" &&
                              pro.variant.ram !== null
                                ? ","
                                : ""}
                              {pro.variant.ram !== null ? pro.variant.ram : ""})
                              <br />
                              {pro.imei !== "" ? pro.product_imei : ""}
                            </Box>
                            {pro.order_gifted == 1 || pro.order_gifted ? (
                              <Box mt={1}>
                                <FcPaid size={34} />
                              </Box>
                            ) : null}
                          </Box>
                        </Button>
                      </Grid>
                      <Grid
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        item
                        paddingLeft={query(1, 1, 0)}
                        paddingRight={query(1, 1, 0)}
                        paddingTop={query(0, 0, 2)}
                      >
                        {smDown ? (
                          <div style={{ marginBottom: "8px" }}>
                            Product Price
                          </div>
                        ) : null}
                        <CustomTextField
                          value={pro.order_price}
                          className="textField__AddInventory"
                        />
                      </Grid>
                      <Grid
                        xl={2}
                        lg={2}
                        md={2}
                        sm={12}
                        xs={12}
                        item
                        paddingLeft={query(1, 1, 0)}
                        paddingTop={query(0, 0, 2)}
                      >
                        {smDown ? (
                          <div style={{ marginBottom: "8px" }}>
                            Product Quantity
                          </div>
                        ) : null}
                        <CustomTextField
                          value={pro.order_quantity}
                          className="textField__AddInventory"
                        />
                      </Grid>

                      {!smDown ? (
                        <Grid
                          xl={1}
                          lg={1}
                          md={1}
                          sm={12}
                          xs={12}
                          item
                          paddingLeft={query(1, 1, 0)}
                          paddingTop={query(0, 0, 2)}
                          disabled
                        >
                          {/* <ButtonGroup
                            orientation="vertical"
                            aria-label="vertical contained button group"
                            variant="contained"
                            style={{
                              backgroundColor: "#ECAE33",
                              padding: "2px 5px 2px 5px",
                              borderRadius: "10%",
                            }}
                          >
                            <HighlightOffIcon
                              style={{
                                cursor: "pointer",
                                paddingBottom: "5px",
                                color: "white",
                              }}
                              onClick={() => {
                                openDeleteProductDialog(proi);
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                handleEditProduct(proi);
                              }}
                              style={{ cursor: "pointer", color: "white" }}
                            />
    
                          </ButtonGroup> */}
                          {!(
                            pro.order.order_gifted === 1 ||
                            pro.order.order_gifted
                          ) && Number(updateOrderData.order_discount) === 0 ? (
                            <IconButton
                              onClick={() => {
                                handleMarkReturnProduct(proi);
                              }}
                              size="large"
                              style={{ zIndex: "9999999" }}
                            >
                              <GiReturnArrow
                                size={32}
                                style={{ cursor: "pointer", color: "#1E2738" }}
                              />
                            </IconButton>
                          ) : null}
                        </Grid>
                      ) : null}
                    </Grid>

                    <Grid container mt={0.2}>
                      <Grid xs={11}>
                        <Box className="prod_added_des__addord">
                          {pro.order_product_description}
                        </Box>
                      </Grid>
                    </Grid>

                    {pro.order_returned === 1 || pro.order_returned ? (
                      <Grid container mt={1}>
                        <Grid xs={11}>
                          <CustomTextField
                            disabled={
                              pro.order.order_returned ||
                              pro.order.order_returned === 1
                            }
                            value={pro.order_return_description}
                            onChange={(e) => {
                              handleUpdateReturnOrderDescription(e, proi);
                            }}
                            name="order_return_description"
                            id="order_return_description"
                            key="order_return_description"
                            placeholder="Return Product Description ..."
                            className="textField__AddInventory"
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                  </Box>
                ))}

                {/* END ******************************* Added Product List ******************************************** */}

                {/* {smDown ? (
                  <>
                    <hr style={{ marginTop: "40px" }} />
                    <div style={{ marginBottom: "8px", textAlign: "center" }}>
                      Add New Product Detail
                    </div>
                    <hr style={{ marginBottom: "40px" }} />
                  </>
                ) : null} */}

                {/* START ******************************* Add New Product Fields ******************************************** */}

                {/* <Grid container mt={orderProducts.length > 0 ? 2 : 0}>
                  <Grid
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    {smDown ? (
                      <div style={{ marginBottom: "8px" }}>Product Name</div>
                    ) : null}

                    <ProductMultiLevelMenu
                      setIsProductSelected={setIsProductSelected}
                      setProduct={addSelectedProduct}
                      product={product}
                      productsData={orderProducts}
                    />
                  </Grid>
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingRight={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    {smDown ? (
                      <div style={{ marginBottom: "8px" }}>Product Price</div>
                    ) : null}
                    <CustomTextField
                      error={orderPriceSmallerThanError ? true : false}
                      value={product.order_price}
                      onChange={handleProductInputChange}
                      name="order_price"
                      id="order_price"
                      key="order_price"
                      placeholder="Product Price"
                      className="textField__AddInventory"
                      helperText={
                        orderPriceSmallerThanError
                          ? `Min. Sale Price is ${
                              product.variant.min_sale_price
                            }`
                          : ""
                      }
                      disabled={!isProductSelected}
                    />
                  </Grid>
                  <Grid
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    {smDown ? (
                      <div style={{ marginBottom: "8px" }}>
                        Product Quantity
                      </div>
                    ) : null}
                    <CustomTextField
                      disabled={
                        product.variant.category === "Mobile" ||
                        !isProductSelected
                      }
                      error={quantityGreaterThanError ? true : false}
                      value={product.order_quantity}
                      onChange={handleProductInputChange}
                      name="order_quantity"
                      id="order_quantity"
                      key="order_quantity"
                      type="number"
                      className="textField__AddInventory"
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      helperText={
                        quantityGreaterThanError
                          ? `Available Quantity is ${product.variant.quantity}`
                          : ""
                      }
                    />
                  </Grid>

                  <Grid
                    xl={1}
                    lg={1}
                    md={1}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                    disabled
                  >
                    <Checkbox
                      checked={
                        product.order_gifted || product.order_gifted == 1
                      }
                      {...label}
                      icon={<GiftRedIcon />}
                      checkedIcon={<FcPaid size={34} />}
                      onClick={() => {
                        setProduct({
                          ...product,
                          ["order_gifted"]: !product.order_gifted,
                        });
                      }}
                    />
                  </Grid>
                </Grid> */}

                {/* <Grid container mt={1}>
                  <Grid xs={11}>
                    <CustomTextField
                      value={product.order_product_description}
                      onChange={handleProductInputChange}
                      name="order_product_description"
                      id="order_product_description"
                      key="order_product_description"
                      placeholder="Description ..."
                      className="textField__AddInventory"
                    />
                  </Grid>
                </Grid> */}

                {/* END **************************** Add New Product Fields ******************************************** */}

                {/* START **************************** Add Product Button ******************************************** */}

                {/* <Grid container display="flex" justifyContent="center" mt={2}>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    <Button
                      onClick={handleAddProduct}
                      className="add_product_btn__adord"
                      variant="contained"
                      endIcon={
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2917 8.70833H13.4583C13.6683 8.70833 13.8697 8.79174 14.0181 8.94021C14.1666 9.08867 14.25 9.29004 14.25 9.5C14.25 9.70996 14.1666 9.91133 14.0181 10.0598C13.8697 10.2083 13.6683 10.2917 13.4583 10.2917H10.2917V13.4583C10.2917 13.6683 10.2083 13.8697 10.0598 14.0181C9.91133 14.1666 9.70996 14.25 9.5 14.25C9.29004 14.25 9.08867 14.1666 8.94021 14.0181C8.79174 13.8697 8.70833 13.6683 8.70833 13.4583V10.2917H5.54167C5.3317 10.2917 5.13034 10.2083 4.98187 10.0598C4.83341 9.91133 4.75 9.70996 4.75 9.5C4.75 9.29004 4.83341 9.08867 4.98187 8.94021C5.13034 8.79174 5.3317 8.70833 5.54167 8.70833H8.70833V5.54167C8.70833 5.3317 8.79174 5.13034 8.94021 4.98187C9.08867 4.83341 9.29004 4.75 9.5 4.75C9.70996 4.75 9.91133 4.83341 10.0598 4.98187C10.2083 5.13034 10.2917 5.3317 10.2917 5.54167V8.70833Z"
                            fill="rgb(30, 39, 56)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 5.7C0 2.55198 2.55198 0 5.7 0H13.3C16.448 0 19 2.55198 19 5.7V13.3C19 16.448 16.448 19 13.3 19H5.7C2.55198 19 0 16.448 0 13.3V5.7ZM5.7 1.425H13.3C15.661 1.425 17.575 3.33898 17.575 5.7V13.3C17.575 15.661 15.661 17.575 13.3 17.575H5.7C3.33898 17.575 1.425 15.661 1.425 13.3V5.7C1.425 3.33898 3.33898 1.425 5.7 1.425Z"
                            fill="rgb(30, 39, 56)"
                          />
                        </svg>
                      }
                    >
                      {orderProducts.length > 0 ? "Add Product" : "Add Product"}
                    </Button>
                  </Grid>
                </Grid> */}

                {/* END **************************** Add Product Button ******************************************** */}

                {/* START **************************** Total Calculation ******************************************** */}

                <Grid
                  container
                  paddingTop={4}
                  paddingBottom={1}
                  display="flex"
                  justifyContent="end"
                >
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                  >
                    <Grid container>
                      <Grid xs item>
                        SUBTOTAL
                      </Grid>
                      <Grid xs item>
                        DISCOUNT
                      </Grid>
                      <Grid xs item display="flex" justifyContent="end">
                        TAX RATE
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container display="flex" justifyContent="end">
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    <Grid container>
                      <Grid className="price__addord" xs item>
                        {updateOrderData.sub_total} AED
                      </Grid>
                      <Grid className="price__addord" xs item>
                        {updateOrderData.order_discount} AED
                      </Grid>
                      <Grid
                        xs
                        item
                        display="flex"
                        justifyContent="end"
                        className="price__addord"
                      >
                        {updateOrderData.tax_amount} AED{" "}
                        {updateOrderData.tax.length > 3
                          ? updateOrderData.tax * 100
                          : updateOrderData.tax}
                        %
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  paddingTop={1}
                  paddingBottom={1}
                  display="flex"
                  justifyContent="end"
                >
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                  >
                    TOTAL
                  </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="end">
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                    style={{ borderBottom: "1px solid black" }}
                    className="price__addord"
                  >
                    {updateOrderData.total} AED
                  </Grid>
                </Grid>

                {/* END **************************** Total Calculation ******************************************** */}

                {/* START **************************** Discard and Place Order ******************************************** */}

                <Grid container paddingTop={5} paddingBottom={5}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingLeft={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="start"
                  >
                    <Button
                      onClick={() => {
                        navigate("/orders");
                      }}
                      variant="contained"
                      className="footer_dis__ai"
                    >
                      Discard
                    </Button>
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingRight={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="end"
                  >
                    {!(orderProducts[0] !== undefined
                      ? orderProducts[0].order.order_returned ||
                        orderProducts[0].order.order_returned === 1
                      : false) ? (
                      <Button
                        onClick={handleCreateReturnOrder}
                        variant="contained"
                        className="footer_add__ai"
                      >
                        Create Return Order
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>

                {/* END ******************************* Discard and Place Order ******************************************** */}
              </AutoScrollContainer>

              {/* END ******************************* 
                
                          Auto Scroll Container for vertical scrolling
                
                ******************************************** */}
            </Grid>
            {/* END ******************************* 
                
                      Content container Inside the Gradiant Box
                
                ******************************************** */}
          </Grid>
          {/* END ******************************* 
                
                      Content Gradiant Box
                
                ******************************************** */}
        </Box>

        {/* END ******************************* Content Box ******************************************** */}
      </Grid>
      {/* END ******************************* Container Column ******************************************** */}
    </Grid>
  );
}

export default EditOrder;
