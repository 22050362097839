import "./Login.css";
import { Grid } from "@mui/material";
import React, { useContext, useState, useEffect, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { GlobalData } from "../../App";
import { login } from "../../api/AuthAPI";

function Login() {
  const {
    smDown,
    flashMessage,
    navigate,
    location,
    showLoader,
    hideLoader,
    setIsUserAuthenticated,
  } = useContext(GlobalData);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const emailRef = useRef(null);
  const passRef = useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleLogin(e) {
    e.preventDefault();
    let formData = new FormData();
    formData.append("email", emailRef.current.value);
    formData.append("password", passRef.current.value);
    showLoader();
    login(formData, (resp) => {
      if (resp.statusCode === 200) {
        setIsUserAuthenticated(true);
        localStorage.setItem("user", JSON.stringify(resp.successData.user));
        navigate(location.pathname);
        setTimeout(() => {
          hideLoader();
          flashMessage("success", resp.message);
        }, 4000);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  }

  return (
    <Grid container style={{ height: "100vh" }}>
      {smDown && (
        <Box className="mobile_bg_container__login">
          <Box className="mobile_bg__login" />
        </Box>
      )}
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          className={`form__login ${
            smDown ? "login_form_mobile__login" : "login_form_laptop__login"
          }`}
        >
          <Box
            style={{
              // width: "423px",
              marginTop: "20px",
              marginBottom: "60px",
            }}
          >
            <Grid container>
              <Grid
                className="wel__login"
                display="flex"
                alignItems="center"
                item
                xl={8}
                lg={8}
                md={8}
                sm={8}
                xs={8}
              >
                <Box>
                  <Box className="sign__login">Sign in to</Box>
                  <Box className="parkour__login">Waqas Dubai Wala Shop</Box>
                </Box>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                display="flex"
                justifyContent="end"
              >
                <div
                  className={`${
                    smDown ? "logo_mobile__login" : "logo_laptop__login"
                  }`}
                />
              </Grid>
            </Grid>

            {/* <Grid container>
              <Grid
                className="sign__login"
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
              >
                Sign in to
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                className="parkour__login"
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
              >
                Waqas Dubai Wala Shop
              </Grid>
            </Grid> */}

            <Box component="form" noValidate autoComplete="off">
              <FormControl
                sx={{
                  width: `${smDown ? "100%" : "423px"}`,
                  marginTop: "40px",
                  letterSpacing: "1px",
                }}
              >
                <Box mb={1}>Email</Box>
                <OutlinedInput
                  placeholder="Enter your Email"
                  id="email"
                  name="email"
                  inputRef={emailRef}
                  sx={{ borderRadius: "10px" }}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </FormControl>
            </Box>

            <Box component="form" noValidate autoComplete="off">
              <FormControl
                sx={{
                  width: `${smDown ? "100%" : "423px"}`,
                  marginTop: "40px",
                  letterSpacing: "1px",
                }}
              >
                <Box mb={1}>Password</Box>
                <OutlinedInput
                  sx={{ borderRadius: "10px" }}
                  inputRef={passRef}
                  id="pass"
                  name="pass"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Enter your Password"
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
              </FormControl>
            </Box>

            <Grid container>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="rem_item__login"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox style={smDown ? { fontSize: "14px" } : {}} />
                    }
                    label={
                      <Box style={smDown ? { fontSize: "14px" } : {}}>
                        Remember me
                      </Box>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                display="flex"
                alignItems="center"
                className="for_item__login"
                style={smDown ? { fontSize: "14px" } : {}}
              >
                <div>Forgot Password ?</div>
              </Grid>
            </Grid>

            <Button
              onClick={handleLogin}
              className="button__login"
              variant="contained"
              size="large"
              sx={{
                marginTop: "60px",
                borderRadius: "10px",
                width: `${smDown ? "100%" : "423px"}`,
              }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Grid>

      {!smDown && (
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box paddingRight={!smDown && 20} className="side_image__login" />
        </Grid>
      )}
    </Grid>
  );
}
export default Login;
