import React, {  useContext}  from 'react'
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import { GlobalData } from "../App";
function MainTitle(props) {
    const {
        onlyXs,
      } = useContext(GlobalData);
      const title=props.title;
  return (
  <Grid>
    {title =='Expense Management'?( <Grid container marginBottom={2}>
    <Grid
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      item
      paddingRight={onlyXs ? "5px" : "10px"}
    >
      <Box className="i-title">{props.title}</Box>
    </Grid>
  </Grid>):( <Grid container marginBottom={2}>
    <Grid
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      item
      paddingRight={onlyXs ? "5px" : "10px"}
    >
      <Box className="i-title">{props.title}</Box>
    </Grid>
  </Grid>)}
  </Grid>
  )
}

export default MainTitle