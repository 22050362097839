import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { styled } from "@mui/material/styles";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import {
  registerStaffMember,
  updateStaffMember,
  getMemberDetailsById,

} from "../../api/StaffAPI";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./Staff.css";
import AutoScrollContainer from "auto-scroll-container";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getAllUserRoles } from "../../api/UserRolesAPI";
import { ReactComponent as UploadImageIcon } from "../../assets/SVG/UploadImageIcon.svg";
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
  
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});
const memberObject = {
  member_name: "",
  member_phone: "",
  member_address: "",
  member_salary: "",
  member_nationality: "",
  member_cnic: "",
  email: "",
  password: "",
  role: "",
  role_id: "",
  passport_number: "",
  visa_number: "",
  visa_expiry_date: "",
  emirates_id_number: "",
  picture: "",
};
function AddStaff(props) {
  const {
    smDown,
    query,
    showLoader,
    hideLoader,
    location,
    flashMessage,
    navigate,
    baseUrl,
  } = useContext(GlobalData);

  const [imageUri, setImageUri] = useState("");
  const [imageFile, setImageFile] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [userRoles, setUserRoles] = useState([]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  const [edit, setEdit] = useState(false);
  const [member, setMember] = useState(memberObject);

  const handleOrderInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "role") {
      setMember({
        ...member,
        [name]: value,
        ["role_id"]: userRoles.find((ur) => ur.role === value).id,
      });
    } else {
      setMember({
        ...member,
        [name]: value,
      });
    }
   
  };

  const handleGetAllUserRoles = () => {
    showLoader();
    getAllUserRoles((resp) => {
      hideLoader();
      setUserRoles(resp.successData.user_roles);
    });
  };

  const handleGetMemberDetailsById = () => {
    const data = {
      member_id: location.state.memberData,
    };
    showLoader();
    getMemberDetailsById(data, (resp) => {
      if (resp.statusCode === 200) {
        setMember({
          ...member,
          member_name: resp.successData.staff.member_name,
          member_phone: resp.successData.staff.member_phone,
          member_address: resp.successData.staff.member_address,
          member_salary: resp.successData.staff.member_salary,
          member_cnic: resp.successData.staff.member_cnic,
          member_nationality: resp.successData.staff.member_nationality,
          email: resp.successData.staff.user.email,
          role: resp.successData.staff.user.role,
          role_id: resp.successData.staff.user.role_id,
          passport_number: resp.successData.staff.passport_number,
          visa_number: resp.successData.staff.visa_number,
          visa_expiry_date: resp.successData.staff.visa_expiry_date,
          emirates_id_number: resp.successData.staff.emirates_id_number,
        });
        setImageUri(baseUrl + resp.successData.staff.image);
        setEdit(true);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  useEffect(() => {
    handleGetAllUserRoles();
    if (location.state) {
      handleGetMemberDetailsById();
    } else {
      console.log("Nothing");
    }
  }, []);

  const handleAddStaff = () => {
    showLoader();
    let formData = new FormData();
    formData.append("member_name", member.member_name);
    formData.append("member_phone", member.member_phone);
    formData.append("member_address", member.member_address);
    formData.append("member_salary", member.member_salary);
    formData.append("member_nationality", member.member_nationality);
    formData.append("member_cnic", member.member_cnic);
    formData.append("email", member.email);
    formData.append("password", member.password);
    formData.append("role", member.role);
    formData.append("role_id", member.role_id);
    formData.append("passport_number", member.passport_number);
    formData.append("visa_number", member.visa_number);
    formData.append("visa_expiry_date", member.visa_expiry_date);
    formData.append("emirates_id_number", member.emirates_id_number);
    if (imageFile) {
      formData.append("image", imageFile, imageFile.name);
    }
    registerStaffMember(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        navigate("/staff");
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };
  const handleEditStaff = () => {
    showLoader();
    let formData = new FormData();
    formData.append("member_id", location.state.memberData);
    if (member.member_name !== "") {
      formData.append("member_name", member.member_name);
    }
    if (member.member_phone !== "") {
      formData.append("member_phone", member.member_phone);
    }
    if (member.member_address !== "") {
      formData.append("member_address", member.member_address);
    }
    if (member.member_salary !== "") {
      formData.append("member_salary", member.member_salary);
    }

    if (member.member_nationality !== "") {
      formData.append("member_nationality", member.member_nationality);
    }

    if (member.member_cnic !== "") {
      formData.append("member_cnic", member.member_cnic);
    }

    if (member.email !== "") {
      formData.append("email", member.email);
    }

    if (member.password !== "") {
      formData.append("password", member.password);
    }

    if (member.role !== "") {
      formData.append("role", member.role);
    }

    if (member.role_id !== "") {
      formData.append("role_id", member.role_id);
    }

    if (member.passport_number !== "") {
      formData.append("passport_number", member.passport_number);
    }

    if (member.visa_number !== "") {
      formData.append("visa_number", member.visa_number);
    }

    if (member.visa_expiry_date !== "") {
      formData.append("visa_expiry_date", member.visa_expiry_date);
    }

    if (member.emirates_id_number !== "") {
      formData.append("emirates_id_number", member.emirates_id_number);
    }

    if (imageFile) {
      formData.append("image", imageFile, imageFile.name);
    }
    updateStaffMember(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        navigate("/staff");
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  function saveImageFile(e) {
    let image = document.getElementById("productImage").files;
    setImageFile(image[0]);
    setImageUri(window.URL.createObjectURL(new Blob(image, { type: "*" })));
  }

  return (
    
    <Grid container height="100vh" paddingRight="5px">
   
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box height={smDown ? "30vh" : "20vh"}>
          <NavigationBar
            path={
              edit
                ? "Dashboard>Staff Management>Staff>Edit Staff Member"
                : "Dashboard>Staff Management>Staff>Add New Staff Member"
            }
            paddingBottom={1}
          />

          <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
            <Grid
              className="header__ai"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
            >
              <div
                className="header_back_btn__ai"
                onClick={() => {
                  navigate("/staff");
                }}
              >
                <IoMdArrowDropleft
                  style={{ marginBottom: "-5px", fontSize: "20px" }}
                />
                Back
              </div>
              <div className="header_text__ai">
                Staff Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                {edit ? (
                  <span style={smDown ? { fontSize: "14px" } : {}}>
                    Edit Member
                  </span>
                ) : (
                  <span style={smDown ? { fontSize: "14px" } : {}}>
                    Add New Member
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
          <Grid container className="card__ai">
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingTop={query(2, 2, 2)}
              paddingBottom={query(3, 3, 3)}
              paddingLeft={query(5, 2, 1)}
              paddingRight={query(5, 2, 1)}
              id="addOrderStyle"
            >
              <Grid container display="flex" justifyContent="center">
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Name</div>
                  <CustomTextField
                    value={member.member_name}
                    onChange={handleOrderInputChange}
                    name="member_name"
                    id="member_name"
                    key="member_name"
                    placeholder="Member Name"
                    className={"textField__AddInventory"}
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Phone Number</div>
                  <CustomTextField
                    value={member.member_phone}
                    onChange={handleOrderInputChange}
                    name="member_phone"
                    id="member_phone"
                    key="member_phone"
                    placeholder="Phone Number"
                    className="textField__AddInventory"
                    disabled={edit ? true : false}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                display="flex"
                paddingTop={query(1, 1, 0)}
                justifyContent="center"
              >
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Address</div>
                  <CustomTextField
                    value={member.member_address}
                    name="member_address"
                    id="member_address"
                    onChange={handleOrderInputChange}
                    key="member_address"
                    placeholder="Member Address"
                    className="textField__AddInventory"
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Nationality</div>
                  <CustomTextField
                    value={member.member_nationality}
                    onChange={handleOrderInputChange}
                    name="member_nationality"
                    id="member_nationality"
                    key="member_nationality"
                    placeholder="Nationality"
                    className="textField__AddInventory"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                display="flex"
                paddingTop={query(1, 1, 0)}
                justifyContent="center"
              >
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Salary</div>
                  <CustomTextField
                    value={member.member_salary}
                    name="member_salary"
                    id="member_salary"
                    onChange={handleOrderInputChange}
                    key="member_salary"
                    placeholder="Member Salary"
                    className="textField__AddInventory"
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>CNIC Number</div>
                  <CustomTextField
                    value={member.member_cnic}
                    onChange={handleOrderInputChange}
                    name="member_cnic"
                    id="member_cnic"
                    key="member_cnic"
                    placeholder="CNIC NO."
                    className="textField__AddInventory"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                display="flex"
                paddingTop={query(1, 1, 0)}
                justifyContent="center"
              >
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Email</div>
                  <CustomTextField
                    value={member.email}
                    name="email"
                    id="email"
                    onChange={handleOrderInputChange}
                    key="email"
                    placeholder="Enter Email"
                    className="textField__AddInventory"
                    disabled={edit ? true : false}
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Password</div>

                  <CustomTextField
                    value={member.password}
                    onChange={handleOrderInputChange}
                    className="textField__AddInventory"
                    id="password"
                    name="password"
                    key="password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Enter your Password"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                display="flex"
                paddingTop={query(1, 1, 0)}
                justifyContent="center"
              >
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Passport Number</div>
                  <CustomTextField
                    value={member.passport_number}
                    name="passport_number"
                    id="passport_number"
                    onChange={handleOrderInputChange}
                    key="passport_number"
                    placeholder="Enter Passport Number"
                    className="textField__AddInventory"
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Visa Number</div>

                  <CustomTextField
                    value={member.visa_number}
                    name="visa_number"
                    id="visa_number"
                    onChange={handleOrderInputChange}
                    key="visa_number"
                    placeholder="Visa Number"
                    className="textField__AddInventory"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                display="flex"
                paddingTop={query(1, 1, 0)}
                justifyContent="center"
              >
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Visa Expiry Date</div>
                  <CustomTextField
                    value={member.visa_expiry_date}
                    name="visa_expiry_date"
                    id="visa_expiry_date"
                    onChange={handleOrderInputChange}
                    key="visa_expiry_date"
                    placeholder="Enter Visa Expiry Date"
                    className="textField__AddInventory"
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Emirates ID Number</div>

                  <CustomTextField
                    value={member.emirates_id_number}
                    name="emirates_id_number"
                    id="emirates_id_number"
                    onChange={handleOrderInputChange}
                    key="emirates_id_number"
                    placeholder="Emirates ID Number"
                    className="textField__AddInventory"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                display="flex"
                paddingTop={query(1, 1, 0)}
                justifyContent={smDown ? "center" : "start"}
              >
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Roles</div>
                  <FormControl className="textField__AddInventory">
                    {member.role === "" && (
                      <InputLabel sx={{ opacity: 0.6 }}>
                        Select User Role
                      </InputLabel>
                    )}

                    <CustomTextField
                      select
                      value={member.role}
                      onChange={handleOrderInputChange}
                      name="role"
                      id="role"
                      key="role"
                    >
                      {userRoles.map((option) => (
                        <MenuItem key={option.role} value={option.role}>
                          {option.role}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </FormControl>
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingLeft={query(1, 1, 0)}
                  paddingTop={query(0, 0, 2)}
                >
                  <div style={{ marginBottom: "8px" }}>
                    Staff Member Picture (Optional)
                  </div>

                  <input
                    style={{ display: "none" }}
                    id="productImage"
                    type="file"
                    onChange={saveImageFile}
                  />
                  <label htmlFor="productImage">
                    <Button
                      variant="outlined"
                      component="span"
                      style={{
                        width: "100%",
                        height: "54.5px",
                        backgroundColor: "white",
                        borderColor: "rgb(0,0,0,0.25)",
                        color: "rgb(0,0,0,0.35)",
                        textTransform: "none",
                        fontSize: "16px",
                        justifyContent: "flex-start",
                        borderRadius: "10px",
                      }}
                      endIcon={
                        <Box
                          sx={
                            imageUri === ""
                              ? {
                                  position: "absolute",
                                  right: "20px",
                                  bottom: "8px",
                                }
                              : {
                                  position: "absolute",
                                  right: "0px",
                                  bottom: "-10px",
                                }
                          }
                        >
                          {imageUri === "" && (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_829_437)">
                                <path
                                  d="M12.6667 2.00003H8.31467C8.212 2.00003 8.108 1.97603 8.01667 1.93003L5.91467 0.879359C5.638 0.740692 5.32867 0.667359 5.01867 0.667359H3.33333C1.49533 0.666692 0 2.16203 0 4.00003V12C0 13.838 1.49533 15.3334 3.33333 15.3334H5.33333C5.70133 15.3334 6 15.0354 6 14.6667C6 14.298 5.70133 14 5.33333 14H3.33333C2.23067 14 1.33333 13.1027 1.33333 12V6.00003H14.6667V12C14.6667 13.1027 13.7693 14 12.6667 14H10.6667C10.2987 14 10 14.298 10 14.6667C10 15.0354 10.2987 15.3334 10.6667 15.3334H12.6667C14.5047 15.3334 16 13.838 16 12V5.33336C16 3.49536 14.5047 2.00003 12.6667 2.00003ZM1.33333 4.00003C1.33333 2.89736 2.23067 2.00003 3.33333 2.00003H5.01867C5.12133 2.00003 5.224 2.02403 5.31667 2.07069L7.42067 3.12203C7.69733 3.26003 8.006 3.33269 8.31467 3.33269H12.6667C13.5347 3.33269 14.268 3.89203 14.544 4.66603H1.33333V3.99936V4.00003ZM9.52867 10.5974L8.66667 9.73536V14.6667C8.66667 15.0354 8.368 15.3334 8 15.3334C7.632 15.3334 7.33333 15.0354 7.33333 14.6667V9.73536L6.47133 10.5974C6.34133 10.7274 6.17067 10.7927 6 10.7927C5.82933 10.7927 5.65867 10.7274 5.52867 10.5974C5.268 10.3367 5.268 9.91536 5.52867 9.65469L6.604 8.57936C7.34933 7.83336 8.65 7.83336 9.396 8.57936L10.4713 9.65469C10.732 9.91536 10.732 10.3367 10.4713 10.5974C10.2107 10.858 9.78933 10.858 9.52867 10.5974Z"
                                  fill="#1E2738"
                                  fill-opacity="0.4"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_829_437">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                          {imageUri !== "" && (
                            <img
                              alt=""
                              style={{ borderRadius: "10px" }}
                              src={imageUri}
                              width="50px"
                              height="50px"
                            />
                          )}
                        </Box>
                      }
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>
              </Grid>

              <Grid container paddingTop={2} paddingBottom={5}>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  item
                  paddingLeft={smDown ? 1 : 0}
                  display="flex"
                  justifyContent="start"
                >
                  <Button
                    variant="contained"
                    className="footer_dis__ai"
                    onClick={() => {
                      navigate("/staff");
                    }}
                  >
                    Discard
                  </Button>
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  item
                  paddingRight={smDown ? 1 : 0}
                  display="flex"
                  justifyContent="end"
                >
                  {edit ? (
                    <Button
                      variant="contained"
                      className="footer_add__ai"
                      onClick={handleEditStaff}
                    >
                      Save Member Changes
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="footer_add__ai"
                      onClick={handleAddStaff}
                    >
                      Add Staff Member
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      
  
    </Grid>
  );
}
export default AddStaff;
