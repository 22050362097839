import {
  Grid,
  IconButton,
  DialogActions,
  DialogContent,
  Dialog,
} from "@mui/material";
import Button from "@mui/material/Button";
import CustomTable from "../../widgets/CustomTable";
import SearchField from "../../widgets/SearchField";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { debounce } from "lodash";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import PointOfSaleRoundedIcon from "@mui/icons-material/PointOfSaleRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import { getAllPartners, deletePartner } from "../../api/PartnerAPI";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/SVG/DownloadIcon.svg";
import DeleteDialog from "../../widgets/DeleteDialog";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "partner_name",
    label: "Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "partner_phone",
    label: "Phone Number",
    minWidth: 30,
    align: "left",
  },
  {
    id: "partner_address",
    label: "Address",
    minWidth: 30,
    align: "left",
  },
  {
    id: "partner_shop_name",
    label: "Shop Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "partner_total_credit",
    label: "Total Credit",
    minWidth: 100,
    align: "left",
  },
  {
    id: "partner_total_debit",
    label: "Total Debit",
    minWidth: 100,
    align: "left",
  },
  {
    id: "partner_total_balance",
    label: "Total Balance",
    minWidth: 100,
    align: "left",
  },
  {
    id: "partner_percentage",
    label: "%",
    minWidth: 100,
    align: "left",
  },
];

const options = [
  {
    icon: (
      <div>
        <EyeIcon />
      </div>
    ),
    label: "View Khata",
    method: "view",
    type: "icon",
  },
  {
    icon: (
      <div>
        <EditIcon />
      </div>
    ),
    label: "Edit Partner",
    method: "edit",
    type: "icon",
  },
  {
    icon: (
      <div>
        <DeleteIcon />
      </div>
    ),
    label: "Delete Partner",
    method: "delete",
    type: "icon",
  },
];

function Partners(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
  } = useContext(GlobalData);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [partners, setPartners] = useState([]);
  const [partnersCount, setPartnersCount] = useState([]);
  const [currentPartnerId, setCurrentPartnerId] = useState("");
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleViewPartner = (data) => {
    navigate("/partners/view-partner-khata", {
      state: { partnerData: data.id },
    });
  };

  const getAllPartnersMethod = (pn) => {
    let data = {
      search_text: searchText,
      per_page: process.env.REACT_APP_PER_PAGE,
      page: pn,
    };
    showLoader();
    getAllPartners(data, (resp) => {
      if (resp.statusCode === 200) {
        setPartnersCount(resp.successData.all_partners_count);
        if (pn === 1) {
          setPartners(resp.successData.all_partners);
          hideLoader();
        } else {
          setPartners((dt) => {
            dt.push(...resp.successData.all_partners);
            hideLoader();
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const openDeletePartnerDialog = (partner) => {
    console.log("this is Partner", partner);
    setCurrentPartnerId(partner.id);
    setDeleteProductDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeletePartner = () => {
    showLoader();
    const tempdata = { partner_id: currentPartnerId };

    deletePartner(tempdata, (resp) => {
      if (resp.statusCode === 200) {
        flashMessage("success", resp.message);
        updateDataUsingPageNumber();
        setDeleteProductDialog(false);
      } else {
        flashMessage("error", resp.message);
        setDeleteProductDialog(false);
        hideLoader();
      }
    });
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const handleEditPartner = (partner) => {
    navigate("/partners/add-new-partner", {
      state: { partnerData: partner },
    });
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    getAllPartnersMethod(page);
  }, [page]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllPartnersMethod(page);
    }
  };

  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText]);

  return (
    <Grid id="header__orderm" container>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <NavigationBar
          path={"Dashboard>Partners Management>Partners"}
          paddingBottom={3}
        />

        <Grid container marginBottom={2}>
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            item
            paddingRight={onlyXs ? "5px" : "10px"}
          >
            <Box className="i-title">Partners Management</Box>
          </Grid>
        </Grid>

        <Grid container marginBottom={2}>
          <Grid
            xl={6}
            lg={6}
            md={smDown ? 7 : 5}
            sm={smDown ? 7 : 5}
            xs={12}
            item
            paddingRight="5px"
          >
            <SearchField
              onChange={updateProductList}
              tableDataCount={partners.length}
              totalAvailableDataCount={partnersCount}
            />
          </Grid>
          <Grid
            xl={6}
            lg={6}
            md={smDown ? 5 : 7}
            sm={smDown ? 5 : 7}
            xs={12}
            item
            display="flex"
            justifyContent={onlyXs ? "center" : "end"}
            paddingTop={onlyXs ? 1 : 0}
            paddingRight={smDown ? "0px" : "10px"}
          >
            {smDown ? (
              <>
                <IconButton
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  className="action_btn__orderm action_btn_filled__orderm"
                >
                  <AddShoppingCartRoundedIcon />
                </IconButton>
                <IconButton
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  className="action_btn__orderm"
                >
                  <CloudDownloadRoundedIcon />
                </IconButton>
                <IconButton
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  className="action_btn__orderm"
                >
                  <PointOfSaleRoundedIcon />
                </IconButton>
                <IconButton
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  className="action_btn__orderm"
                >
                  <InventoryRoundedIcon />
                </IconButton>
                <IconButton
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  className="action_btn__orderm"
                >
                  <DateRangeRoundedIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  className="add_order_btn__orders"
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  onClick={() => {
                    navigate("/partners/add-new-partner");
                  }}
                >
                  Add New Partner
                </Button>
                <Button
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  className="download_report_btn__orders"
                >
                  Download Report
                </Button>
              </>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <CustomTable
              view={handleViewPartner}
              edit={handleEditPartner}
              delete={openDeletePartnerDialog}
              columns={columns}
              data={partners}
              options={options}
            />
          </Grid>
        </Grid>
      </Grid>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteProductDialog}
        handleRemove={handleDeletePartner}
        title={"  Remove this Partner?"}
        sub_title={
          "You are about to remove a Partner. Click on the “Remove” button to remove it."
        }
      />
    </Grid>
  );
}

export default Partners;
