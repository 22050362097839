import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Chip,
  MobileStepper,
} from "@mui/material";
import "./UserRoles.css";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { EditSVG, ViewSVG, DeleteSVG } from "../../assets/SVG/GeneralSVG";
import CustomTable from "../../widgets/CustomTable";
import { styled } from "@mui/material/styles";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { encrypt, decrypt } from "../../middleware/Encryption";
import {
  addUserRole,
  updateUserRole,
  getAllUserRoles,
} from "../../api/UserRolesAPI";

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    align: "left",
  },
  {
    id: "role",
    label: "Role",
    align: "left",
  },
  {
    id: "permissions",
    label: "Permissions",
    align: "left",
  },
  {
    id: "created_at",
    label: "Date",
    align: "left",
    type: "date",
  },
];

const options = [
  {
    icon: <ViewSVG />,
    label: "View",
    method: "view",
    type: "icon",
  },
  {
    icon: <EditSVG />,
    label: "Edit",
    method: "edit",
    type: "icon",
  },
  {
    icon: <DeleteSVG />,
    label: "Delete",
    method: "delete",
    type: "icon",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const PermissionObject = [
  {
    module: "/",
    label: "Dashboard",
    permissions: [
      {
        id: "Dashboard",
        value: false,
        label: "Dashboard Module",
      },
    ],
  },
  {
    module: "products",
    label: "Inventory",
    permissions: [
      {
        id: "Inventory",
        value: false,
        label: "Inventory Module",
      },
      {
        id: "download-all-btn",
        value: false,
        label: "Download All Inventory PDF Button",
      },
      {
        id: "action_view",
        value: false,
        label: "Product View Option",
      },
      {
        id: "action_status",
        value: false,
        label: "Product Change Status Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Product Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Product Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Product Delete Option",
      },
      {
        id: "product_cost_price",
        value: false,
        label: "Show Product Cost Price",
      },
      {
        id: "product_source",
        value: false,
        label: "Show Product Source",
      },
      {
        id: "test_permission",
        value: false,
        label: "Test Permission",
      },
    ],
  },
  {
    module: "orders",
    label: "Orders",
    permissions: [
      {
        id: "Orders",
        value: false,
        label: "Orders Module",
      },
      {
        id: "download-all-btn",
        value: false,
        label: "Download All Orders PDF Button",
      },
      {
        id: "action_view",
        value: false,
        label: "Order View Option",
      },
      {
        id: "action_status",
        value: false,
        label: "Order Change Status Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Order Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Order Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Order Delete Option",
      },
      {
        id: "product_cost_price",
        value: false,
        label: "Show Product Cost Price",
      },
      {
        id: "product_source",
        value: false,
        label: "Show Product Source",
      },
    ],
  },
  {
    module: "accounts",
    label: "Accounts",
    permissions: [
      {
        id: "Accounts",
        value: false,
        label: "Accounts Module",
      },
      {
        id: "total_orders_sale",
        value: false,
        label: "Total Orders Sale Card",
      },
      {
        id: "total_orders_profit",
        value: false,
        label: "Total Orders Profit Card",
      },
      {
        id: "total_orders_return",
        value: false,
        label: "Total Orders Return Card",
      },
      {
        id: "total_cash_out_amount",
        value: false,
        label: "Total Cash Out Amount Card",
      },
      {
        id: "total_cash_in_amount",
        value: false,
        label: "Total Cash In Amount Card",
      },
      {
        id: "total_balance",
        value: false,
        label: "Total Balance Card",
      },
      {
        id: "total_bank_balance ",
        value: false,
        label: "Total Account Cash Card",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
    ],
  },
  {
    module: "expense",
    label: "Expense",
    permissions: [
      {
        id: "Expense",
        value: false,
        label: "Expense Module",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
    ],
  },
  {
    module: "staff",
    label: "Staff",
    permissions: [
      {
        id: "Staff",
        value: false,
        label: "Staff Module",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
      {
        id: "action_viewOrders",
        value: false,
        label: "View Orders Option",
      },
    ],
  },
  {
    module: "partners",
    label: "Partners",
    permissions: [
      {
        id: "Partners",
        value: false,
        label: "Partners Module",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
    ],
  },
  {
    module: "vendors",
    label: "Vendors",
    permissions: [
      {
        id: "Vendors",
        value: false,
        label: "Vendors Module",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
    ],
  },
  {
    module: "bank",
    label: "Bank",
    permissions: [
      {
        id: "Bank",
        value: false,
        label: "Bank Module",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
    ],
  },
  {
    module: "notes",
    label: "Notes",
    permissions: [
      {
        id: "Notes",
        value: false,
        label: "Notes Module",
      },
    ],
  },
];

function UserRoles(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    mdDown,
    onlyMd,
    baseUrl,
  } = useContext(GlobalData);

  const [userAddNewRoleDialog, setUserAddNewRoleDialog] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [permissions, setPermissions] = useState(PermissionObject);

  const [selectedUserRoleId, setSelectedUserRoleId] = useState("");

  const [userRole, setUserRole] = useState("");

  const [userRoles, setUserRoles] = useState([]);

  const [userRoleDialogMethod, setUserRoleDialogMethod] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangePermission = (index, permission) => {
    let newPermissions = [...permissions];
    newPermissions[activeStep].permissions[index].value = permission;
    setPermissions(newPermissions);
  };

  const handleSavePermissions = () => {
    showLoader();
    setUserAddNewRoleDialog(false);
    let formData = new FormData();
    formData.append("role", userRole);
    formData.append("permissions", encrypt(JSON.stringify(permissions)));
    addUserRole(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleGetAllUserRoles();
        flashMessage("success", resp.message);
      } else {
        setUserAddNewRoleDialog(true);
        flashMessage("error", resp.message);
      }
    });
  };

  const handleGetAllUserRoles = () => {
    showLoader();
    getAllUserRoles((resp) => {
      hideLoader();
      setUserRoles(resp.successData.user_roles);
      console.log(resp.successData.user_roles);
    });
  };

  useEffect(() => {
    handleGetAllUserRoles();
  }, []);

  const handleUserRoleDetail = (data) => {
    console.log(JSON.parse(decrypt(data.permissions)));
  };

  const handleClickAddNewRoleButton = () => {
    setUserRoleDialogMethod("Add");
    setActiveStep(0);
    setUserRole("");
    setPermissions([
      {
        module: "/",
        label: "Dashboard",
        permissions: [
          {
            id: "Dashboard",
            value: false,
            label: "Dashboard Module",
          },
        ],
      },
      {
        module: "products",
        label: "Inventory",
        permissions: [
          {
            id: "Inventory",
            value: false,
            label: "Inventory Module",
          },
          {
            id: "download-all-btn",
            value: false,
            label: "Download All Inventory PDF Button",
          },
          {
            id: "action_view",
            value: false,
            label: "Product View Option",
          },
          {
            id: "action_status",
            value: false,
            label: "Product Change Status Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Product Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Product Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Product Delete Option",
          },
          {
            id: "product_cost_price",
            value: false,
            label: "Show Product Cost Price",
          },
          {
            id: "product_source",
            value: false,
            label: "Show Product Source",
          },
          {
            id: "test_permission",
            value: false,
            label: "Test Permission",
          },
        ],
      },
      {
        module: "orders",
        label: "Orders",
        permissions: [
          {
            id: "Orders",
            value: false,
            label: "Orders Module",
          },
          {
            id: "download-all-btn",
            value: false,
            label: "Download All Orders PDF Button",
          },
          {
            id: "action_view",
            value: false,
            label: "Order View Option",
          },
          {
            id: "action_status",
            value: false,
            label: "Order Change Status Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Order Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Order Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Order Delete Option",
          },
          {
            id: "product_cost_price",
            value: false,
            label: "Show Product Cost Price",
          },
          {
            id: "product_source",
            value: false,
            label: "Show Product Source",
          },
        ],
      },
      {
        module: "accounts",
        label: "Accounts",
        permissions: [
          {
            id: "Accounts",
            value: false,
            label: "Accounts Module",
          },
          {
            id: "total_orders_sale",
            value: false,
            label: "Total Orders Sale Card",
          },
          {
            id: "total_orders_profit",
            value: false,
            label: "Total Orders Profit Card",
          },
          {
            id: "total_orders_return",
            value: false,
            label: "Total Orders Return Card",
          },
          {
            id: "total_cash_out_amount",
            value: false,
            label: "Total Cash Out Amount Card",
          },
          {
            id: "total_cash_in_amount",
            value: false,
            label: "Total Cash In Amount Card",
          },
          {
            id: "total_balance",
            value: false,
            label: "Total Balance Card",
          },
          {
            id: "total_bank_balance",
            value: false,
            label: "Total Account Cash Card",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
        ],
      },
      {
        module: "expense",
        label: "Expense",
        permissions: [
          {
            id: "Expense",
            value: false,
            label: "Expense Module",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
        ],
      },
      {
        module: "staff",
        label: "Staff",
        permissions: [
          {
            id: "Staff",
            value: false,
            label: "Staff Module",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
          {
            id: "action_viewOrders",
            value: false,
            label: "View Orders Option",
          },
        ],
      },
      {
        module: "partners",
        label: "Partners",
        permissions: [
          {
            id: "Partners",
            value: false,
            label: "Partners Module",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
        ],
      },
      {
        module: "vendors",
        label: "Vendors",
        permissions: [
          {
            id: "Vendors",
            value: false,
            label: "Vendors Module",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
        ],
      },
      {
        module: "bank",
        label: "Bank",
        permissions: [
          {
            id: "Bank",
            value: false,
            label: "Bank Module",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
        ],
      },
      {
        module: "notes",
        label: "Notes",
        permissions: [
          {
            id: "Notes",
            value: false,
            label: "Notes Module",
          },
        ],
      },
    ]);
    setUserAddNewRoleDialog(true);
  };

  const handleEditUserRole = (data) => {
    setActiveStep(0);
    setUserRole(data.role);
    setPermissions(JSON.parse(decrypt(data.permissions)));
    setUserRoleDialogMethod("Update");
    setSelectedUserRoleId(data.id);
    setUserAddNewRoleDialog(true);
  };

  const handleUpdatePermissions = () => {
    showLoader();
    setUserAddNewRoleDialog(false);
    let formData = new FormData();
    formData.append("role", userRole);
    formData.append("permissions", encrypt(JSON.stringify(permissions)));
    formData.append("user_role_id", selectedUserRoleId);
    updateUserRole(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleGetAllUserRoles();
        flashMessage("success", resp.message);
      } else {
        setUserAddNewRoleDialog(true);
        flashMessage("error", resp.message);
      }
    });
  };

  return (
    <Box mr="5px">
      <Dialog
        fullWidth
        maxWidth="xs"
        open={userAddNewRoleDialog}
        onClose={() => {
          setUserAddNewRoleDialog(false);
        }}
        id="deleteProductDialog"
      >
        <DialogContent className="uinfo_con__user">
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div style={{ marginBottom: "8px" }}>Staff Role</div>
                  <CustomTextField
                    key="user_role"
                    name="user_role"
                    id="user_role"
                    value={userRole}
                    onChange={(e) => {
                      setUserRole(e.target.value);
                    }}
                    placeholder="New User Role"
                    className="textField__AddInventory"
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MobileStepper
                    variant="dots"
                    steps={permissions.length}
                    position="static"
                    activeStep={activeStep}
                    style={{ backgroundColor: "transparent" }}
                    classes={{
                      dotActive: "stp_dtstl__user",
                    }}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === permissions.length - 1}
                        className={`bnbt_stl__user ${activeStep <
                          permissions.length - 1 && "bnbt_stl_c__user"}`}
                      >
                        Next
                        <IoMdArrowDropright
                          style={{ fontSize: "20px", marginTop: "-2px" }}
                        />
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        className={`bnbt_stl__user ${activeStep > 0 &&
                          "bnbt_stl_c__user"}`}
                      >
                        <IoMdArrowDropleft
                          style={{ fontSize: "20px", marginTop: "-2px" }}
                        />
                        Back
                      </Button>
                    }
                  />
                </Grid>
              </Grid>

              <Grid container mt="-5px" mb="10px">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box textAlign="center">
                    <span className="stp_txt__user">
                      {permissions[activeStep].label}
                    </span>
                  </Box>
                </Grid>
              </Grid>

              <Grid container pt="10px">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} px="10px">
                  <Box>
                    {permissions[activeStep].permissions.map((value, index) => (
                      <>
                        {value.value ? (
                          <Chip
                            style={{ margin: "3px" }}
                            label={value.label}
                            color="success"
                            clickable
                            onClick={() => {
                              handleChangePermission(index, false);
                            }}
                          />
                        ) : (
                          <Chip
                            style={{ margin: "3px" }}
                            label={value.label}
                            clickable
                            onClick={() => {
                              handleChangePermission(index, true);
                            }}
                          />
                        )}
                      </>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="deleteDialogActions">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              className="deleteDialogBtn__inv deleteDialogBtnNo__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={() => {
                setUserAddNewRoleDialog(false);
              }}
            >
              Cancel
            </Grid>
            <Grid
              className="deleteDialogBtn__inv adurl_btn__user"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={() => {
                userRoleDialogMethod === "Add"
                  ? handleSavePermissions()
                  : handleUpdatePermissions();
              }}
            >
              {userRoleDialogMethod === "Add" ? "Save" : "Update"}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <NavigationBar
        path={"Dashboard>Staff Management>Staff>Staff Roles"}
        paddingBottom={1}
      />
      <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
        <Grid
          className="header__ai"
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          item
        >
          <div
            className="header_back_btn__ai"
            onClick={() => {
              navigate("/staff");
            }}
          >
            <IoMdArrowDropleft
              style={{ marginBottom: "-5px", fontSize: "20px" }}
            />
            Back
          </div>
          <div className="header_text__ai">Staff Roles Management</div>
        </Grid>
      </Grid>

      <Grid container marginBottom={2}>
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          item
          display="flex"
          justifyContent={smDown ? "center" : "end"}
          paddingTop={smDown ? 1 : 0}
          paddingRight={smDown ? "0px" : "10px"}
        >
          <Button
            variant="contained"
            className="add_order_btn__orders"
            style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
            onClick={handleClickAddNewRoleButton}
          >
            Add New Staff Role
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CustomTable
            view={handleUserRoleDetail}
            edit={handleEditUserRole}
            columns={columns}
            data={userRoles}
            options={options}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserRoles;
