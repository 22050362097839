import { Box } from "@mui/material";
import { ReactComponent as UtilityBank } from "../assets/SVG/UtilityBank.svg";
import { ReactComponent as Salaries } from "../assets/SVG/Salaries.svg";
import { ReactComponent as Purchasing } from "../assets/SVG/Purchasing.svg";
import { ReactComponent as Tax } from "../assets/SVG/Tax.svg";
import { ReactComponent as DebitIcon } from "../assets/SVG/DebitSmallIcon.svg";

const ExpenseBox = (props) => {
  return (
    <Box
      backgroundColor={props.backgroundColor}
      className={`bnkcrd_bx__bank ${props.className}`}
      marginBottom="6px"
    >
      <Box className="bnkcrd_bx_icn_bx__bank">{props.expenseIcon}</Box>
      <Box>
        <Box className="bnkcrd_txt1__bank">{props.expenseType}</Box>
        <Box className="bnkcrd_txt2__bank">{props.expenseAmount} AED</Box>
      </Box>
    </Box>
  );
};

export const ShopCashInCard = (props) => {
  return (
    <ExpenseBox
      backgroundColor="#E4F3F1"
      expenseIcon={<DebitIcon />}
      expenseType={`Total Shop Cash In`}
      expenseAmount={props.amount}
      className={`${props.width === "full" ? "box_fw__expense" : ""}`}
    />
  );
};

export const UtilityBillExpenseCard = (props) => {
  return (
    <ExpenseBox
      backgroundColor="#EDEBFE"
      expenseIcon={<UtilityBank />}
      expenseType={"Utility Bills"}
      expenseAmount={props.amount}
      className={`${props.width === "full" ? "box_fw__expense" : ""}`}
    />
  );
};

export const SalaryExpenseCard = (props) => {
  return (
    <ExpenseBox
      backgroundColor="#FFF6D4"
      expenseIcon={<Salaries />}
      expenseType={"Salaries"}
      expenseAmount={props.amount}
      className={`${props.width === "full" ? "box_fw__expense" : ""}`}
    />
  );
};

export const PurchasingExpenseCard = (props) => {
  return (
    <ExpenseBox
      backgroundColor="#FFE8DE"
      expenseIcon={<Purchasing />}
      expenseType={"Purchasing"}
      expenseAmount={props.amount}
      className={`${props.width === "full" ? "box_fw__expense" : ""}`}
    />
  );
};

export const TaxExpenseCard = (props) => {
  return (
    <ExpenseBox
      backgroundColor="#FFDEF0"
      expenseIcon={<Tax />}
      expenseType={"Tax"}
      expenseAmount={props.amount}
      className={`${props.width === "full" ? "box_fw__expense" : ""}`}
    />
  );
};

export const OthersExpenseCard = (props) => {
  return (
    <ExpenseBox
      backgroundColor="#cdddfc"
      expenseIcon={<Tax />}
      expenseType={"Others"}
      expenseAmount={props.amount}
      className={`${props.width === "full" ? "box_fw__expense" : ""}`}
    />
  );
};
