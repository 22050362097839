import "./Inventory.css";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Menu,
  MenuItem,
  Fade,
} from "@mui/material";
import { IoBagAddOutline } from "react-icons/io5";
import SearchField from "../../widgets/SearchField";
import CustomTable from "../../widgets/CustomTable";
import TriStateButton from "../../widgets/TriStateButton.jsx";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { getAllProducts } from "../../api/InventoryAPI";
import { GlobalData } from "../../App";
import { deleteProduct } from "../../api/InventoryAPI";
import { getAllCategories } from "../../api/CategoryAPI";
import { debounce } from "lodash";
import NavigationBar from "../NavigationBar";
import PrintIcon from "@mui/icons-material/Print";
import ProtectComponent from "../../middleware/ProtectComponent";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { inventoryReport } from "../../api/ReportAPI";
import DeleteDialog from "../../widgets/DeleteDialog";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/SVG/DownloadIcon.svg";
import { ReactComponent as InventoryMenuFilterIcon } from "../../assets/SVG/InventoryMenuFilterIcon.svg";

import MainTitle from "../../widgets/MainTitle";
const options = [
  {
    icon: <EyeIcon />,
    label: "View",
    method: "view",
    type: "icon",
  },
  {
    icon: <EditIcon />,
    label: "Edit",
    method: "edit",
    type: "icon",
  },
  {
    icon: <DeleteIcon />,
    label: "Delete",
    method: "delete",
    type: "icon",
  },
  {
    icon: <PrintIcon style={{ opacity: "0.7", marginLeft: "-2.5px" }} />,
    label: <span style={{ marginLeft: "-4.5px" }}>Print</span>,
    method: "print",
    type: "icon",
  },
];

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",

    align: "left",
  },
  {
    id: "image",
    label: "Image",
    align: "left",
  },
  {
    id: "name",
    label: "Name",
    align: "left",
  },
  {
    id: "variant_sale_prices",
    label: "Sale Prices",
    align: "left",
  },
  {
    id: "category",
    label: "Category",
    align: "left",
  },
  {
    id: "quantity",
    label: "Quantity",
    align: "left",
  },
];

function Inventory(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    onlyXs,
    smDown,
    mdDown,
    baseUrl,
  } = useContext(GlobalData);
  const [
    inventoryTriStateFilterValue,
    setInventoryTriStateFilterValue,
  ] = useState("All");
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalInventoryWorth, setTotalInventoryWorth] = useState("");
  const [totalInventoryCount, setTotalInventoryCount] = useState(0);
  const [productCategories, setProductCategories] = useState([]);
  const [
    selectedValueInventoryWorthFilter,
    setSelectedValueInventoryWorthFilter,
  ] = useState("");

  const [
    totalInventoryWorthFilterMenu,
    setTotalInventoryWorthFilterMenu,
  ] = useState(null);

  const isTotalInventoryWorthFilterMenuOpen = Boolean(
    totalInventoryWorthFilterMenu
  );
  const handleTotalInventoryWorthFilterMenuOpen = (event) => {
    console.log("ok");
    setTotalInventoryWorthFilterMenu(event.currentTarget);
  };
  const handleTotalInventoryWorthFilterMenuClose = () => {
    setTotalInventoryWorthFilterMenu(null);
  };

  const handleViewProduct = (product) => {
    navigate("/products/product-detail", {
      state: { product_id: product.id, product: product },
    });
  };

  const handleEditProduct = (product) => {
    navigate("/products/edit-product", {
      state: { product_id: product.id, product: product },
    });
  };

  const openDeleteProductDialog = (product) => {
    setCurrentProductId(product.id);
    setDeleteProductDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeleteProduct = () => {
    handleCloseDeleteDialog();
    showLoader();
    let formData = new FormData();
    formData.append("product_id", currentProductId);
    deleteProduct(formData, (resp) => {
      console.log(resp);
      if (resp.statusCode === 200) {
        setProducts(resp.successData.products);
        hideLoader();
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  const getAllProductsMethod = (pn) => {
    showLoader();
    let formData = new FormData();
    formData.append("search_text", searchText);
    formData.append(
      "filter_inventory_category_id",
      selectedValueInventoryWorthFilter
    );
    formData.append("inventory_tri_state_filter_value", inventoryTriStateFilterValue);
    formData.append("per_page", process.env.REACT_APP_PER_PAGE);
    formData.append("page", pn);
    getAllProducts(formData, (resp) => {
      if (resp.statusCode === 200) {
        setTotalInventoryWorth(resp.successData.total_inventory_worth_cost);
        setTotalInventoryCount(resp.successData.total_products_count);
        if (resp.statusCode === 200) {
          if (pn === 1) {
            setProducts(resp.successData.products);
            hideLoader();
          } else {
            setProducts((dt) => {
              dt.push(...resp.successData.products);
              hideLoader();
              return dt;
            });
          }
          setLastPage(resp.successData.last_page);
        }
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const handleGetAllCategories = () => {
    showLoader();
    getAllCategories((resp) => {
      let c = resp.successData.categories;
      let b = c;
      b.push({
        id: b[b.length - 1].id + 1,
        category: "All",
        image: "images/image_placeholder.png",
        created_at: "2023-01-02T17:44:49.000000Z",
        updated_at: "2023-01-02T17:44:49.000000Z",
      });
      b = b.reverse();
      setProductCategories(b);
    });
  };

  const handlePrintInventoryReport = () => {
    showLoader();
    let formData = new FormData();
    formData.append("products", JSON.stringify(products));
    formData.append("inventory_cost", totalInventoryWorth);
    inventoryReport(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handleFetchInventory = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(handleFetchInventory);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllProductsMethod(page);
    }
  };

  useEffect(() => {
    getAllProductsMethod(page);
  }, [page]);

  useEffect(() => {
    updateDataUsingPageNumber();
    if (productCategories.length === 0) {
      handleGetAllCategories();
    }
  }, [
    searchText,
    selectedValueInventoryWorthFilter,
    inventoryTriStateFilterValue,
  ]);

  const handleTriStateFilter = (value) => {
    setInventoryTriStateFilterValue(value);
  };
  return (
    <>
      <DeleteDialog
        handleRemove={handleDeleteProduct}
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteProductDialog}
        title={"Remove this Inventory Product?"}
        sub_title={
          "You are about to remove a Product. Click on the “Remove” button to remove it."
        }
      />

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={"Dashboard>Inventory Management>Products"}
            paddingBottom={3}
          />

          <MainTitle title={"Inventory Management"} />

          <Grid container marginBottom={2}>
            <Grid
              xl={6}
              lg={6}
              md={5}
              sm={5}
              xs={8}
              item
              paddingRight={onlyXs ? "5px" : "10px"}
            >
              <Box className="i-title wrt__invt">Total Inventory Worth</Box>

              <Box display="flex">
                <Box className="ttwrt__inv">
                  <Box display="flex" alignItems="end">
                    <span style={{ fontSize: "24px", opacity: "0.8" }}>
                      {totalInventoryWorth}
                    </span>
                    &nbsp;&nbsp;AED
                  </Box>
                </Box>
                <InventoryWorthFilter
                  setSelectedValueInventoryWorthFilter={
                    setSelectedValueInventoryWorthFilter
                  }
                  productCategories={productCategories}
                />
              </Box>
            </Grid>

            <Grid
              xl={6}
              lg={6}
              md={7}
              sm={7}
              xs={4}
              item
              display="flex"
              justifyContent="end"
              alignItems="end"
              paddingRight={onlyXs ? "0px" : "10px"}
            >
              {onlyXs ? (
                <IconButton
                  aria-label="add"
                  size="large"
                  className="i-add-btn-icon"
                >
                  <IoBagAddOutline fontSize="inherit" />
                </IconButton>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      navigate("/products/add-new-product");
                    }}
                    variant="contained"
                    className="add_order_btn__orders"
                  >
                    Add New Product
                  </Button>
                  <span style={{ marginLeft: "5px" }} />
                  <ProtectComponent
                    id="download-all-btn"
                    componentLocation={true}
                    componentNavigate={false}
                    component={
                      <Button
                        variant="contained"
                        endIcon={<DownloadIcon />}
                        className="download_report_btn__orders"
                        onClick={handlePrintInventoryReport}
                      >
                        {mdDown ? (
                          <div>
                            <div>Download</div>
                            <div style={{ marginTop: "-8px" }}>Report</div>
                          </div>
                        ) : (
                          "Download Report"
                        )}
                      </Button>
                    }
                  />
                </>
              )}
            </Grid>
          </Grid>

          <Grid container marginBottom={2}>
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              item
              display="flex"
              justifyContent={onlyXs ? "center" : "start"}
              paddingRight={onlyXs ? "5px" : "10px"}
              paddingTop={onlyXs ? 1 : 0}
              paddingBottom={onlyXs ? 1 : 0}
            >
              <TriStateButton
                values={["All", "Cost Price", "Quantity"]}
                selected={"All"}
                handleTriStateFilter={handleTriStateFilter}
              />

              <InventoryFilter />
            </Grid>

            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item paddingRight="5px">
              <SearchField
                onChange={updateProductList}
                tableDataCount={products.length}
                totalAvailableDataCount={totalInventoryCount}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CustomTable
            view={handleViewProduct}
            edit={handleEditProduct}
            delete={openDeleteProductDialog}
            rowRed={{
              id: "quantity",
              value: 5,
              condition: "<=",
            }}
            columns={columns}
            data={products}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
}

const InventoryWorthFilter = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className="ttwrt_flt__inv "
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <InventoryMenuFilterIcon style={{ cursor: "pointer" }} />
      </IconButton>
      <Menu
        PaperProps={{
          style: {
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.15)",
          },
        }}
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {props.productCategories.map((option, oi) => (
          <MenuItem
            style={
              oi + 1 < props.productCategories.length
                ? {
                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }
                : { paddingTop: 0, paddingBottom: 0 }
            }
            onClick={() => {
              handleClose();
              props.setSelectedValueInventoryWorthFilter(option.id);
            }}
          >
            {option.category}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const InventoryFilter = (props) => {
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open2 = Boolean(anchorE2);
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };

  return (
    <>
      <Button
        style={{
          marginLeft: "5px",
          marginTop: "2px",
          borderRadius: "10px",
        }}
        className="icon__fil__invet"
        id="demo-positioned-button"
        aria-controls={open2 ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? "true" : undefined}
        onClick={handleClick2}
      >
        <FilterAltIcon
          style={{ height: "40px", width: "30px", color: "#1e2738" }}
        />

        {/* <Box fontSize="22px" color="#1e2738">
          -&nbsp;({props.products.length}/{props.totalInventoryCount})
        </Box> */}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorE2}
        open={open2}
        onClose={handleClose2}
        PaperProps={{
          style: {
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.15)",
          },
        }}
      >
        {["Loss", "Maintenance", "Fine Inventory"].map((option, oi) => (
          <MenuItem
            style={
              oi + 1 < 3
                ? {
                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }
                : { paddingTop: 0, paddingBottom: 0 }
            }
            onClick={handleClose2}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Inventory;
