import { DateRangePicker } from "mui-daterange-picker";
import { MenuItem, Button, Menu, Box } from "@mui/material";
import React, { useState, useEffect } from "react";

const DateFilter = (props) => {
  console.log(props);
  const [date, setDate] = useState("This Month");

  const [dateMenuElement, setDateMenuElement] = useState(null);
  const isDateMenuOpen = Boolean(dateMenuElement);
  const ITEM_HEIGHT = 48;
  const handleOpenDateMenu = (event) => {
    setDateMenuElement(event.currentTarget);
  };
  const handleCloseDateMenu = () => {
    setDateMenuElement(null);
  };
  //--
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const toggleDateRangerPicker = () =>
    setOpenDateRangePicker(!openDateRangePicker);
  const handleOrdersDateFilter = (option) => {
    handleCloseDateMenu();
    if (option === "Custom") {
      toggleDateRangerPicker();
    } else {
      setDate(option);
    }
  };

  const handleDateRange = (range) => {
    setDateRange(range);
    setDate(range);
    setTimeout(() => {
      toggleDateRangerPicker();
    }, 500);
  };

  useEffect(() => {
    props.setDateObject(date);
  }, [date]);

  return (
    <>
      <Button
        variant="contained"
        endIcon={
          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.34156 0.5H10.6582C10.823 0.500691 10.9839 0.550237 11.1206 0.642372C11.2572 0.734508 11.3635 0.865096 11.426 1.01762C11.4884 1.17015 11.5042 1.33777 11.4714 1.49928C11.4386 1.66079 11.3586 1.80894 11.2416 1.925L6.59156 6.575C6.51409 6.65311 6.42192 6.7151 6.32037 6.75741C6.21882 6.79972 6.1099 6.8215 5.99989 6.8215C5.88988 6.8215 5.78096 6.79972 5.67941 6.75741C5.57786 6.7151 5.48569 6.65311 5.40823 6.575L0.758225 1.925C0.641207 1.80894 0.561231 1.66079 0.52841 1.49928C0.495589 1.33777 0.511397 1.17015 0.573835 1.01762C0.636273 0.865096 0.742537 0.734508 0.87919 0.642372C1.01584 0.550237 1.17675 0.500691 1.34156 0.5Z"
              fill="black"
            />
          </svg>
        }
        className={props.className}
        onClick={handleOpenDateMenu}
      >
        {typeof date === "string" ? date : "Custom"}
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={dateMenuElement}
        open={isDateMenuOpen}
        onClose={handleCloseDateMenu}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: "20ch",
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.15)",
          },
        }}
      >
        {["Today", "This Week", "This Month", "Custom"].map((option, oi) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => {
              handleOrdersDateFilter(option);
            }}
            style={
              oi + 1 < 4
                ? {
                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }
                : { paddingTop: 0, paddingBottom: 0 }
            }
          >
            <Box marginTop={1} marginRight={2}>
              {option}
            </Box>
          </MenuItem>
        ))}
      </Menu>
      {openDateRangePicker ? (
        <Box position="fixed" top="20%" left="20%" zIndex="9999">
          <DateRangePicker
            open={openDateRangePicker}
            toggle={toggleDateRangerPicker}
            onChange={(range) => handleDateRange(range)}
          />
        </Box>
      ) : null}
    </>
  );
};

export default DateFilter;
