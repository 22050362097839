import "./App.css";
import { Box } from "@mui/material";
import SideMenu from "./components/SideMenu";
import MobileMenu from "./components/MobileMenu";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Welcome from "./components/Welcome";
import React, { useEffect, createContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { isAuthenticated } from "./api/AuthAPI";
import ProtectComponent from "./middleware/ProtectComponent";
import Login from "./components/Auth/Login";
import CircularProgress from "@mui/material/CircularProgress";
import RoutesData from "./Routes";
const GlobalData = createContext();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

let flashMessageObject = {
  type: "",
  message: "",
  active: false,
};

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("lg"));
  const onlyLg = useMediaQuery(theme.breakpoints.only("lg"));
  const onlyMd = useMediaQuery(theme.breakpoints.only("md"));
  const onlySm = useMediaQuery(theme.breakpoints.only("sm"));
  const onlyXs = useMediaQuery(theme.breakpoints.only("xs"));
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [flashMessage, setFlashMessage] = useState(flashMessageObject);
  const [loader, setLoader] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  const [allowedModules, setAllowedModules] = useState([]);

  const query = (lg, md, sm) => {
    if (mdDown) {
      if (smDown) {
        return sm;
      } else {
        return md;
      }
    } else {
      return lg;
    }
  };

  const queryx = (lg, md, sm, xs) => {
    if (onlyXs) {
      return xs;
    }
    if (onlySm) {
      return sm;
    }
    if (onlyMd) {
      return md;
    }
    if (onlyLg) {
      return lg;
    }
  };

  function showLoader() {
    setLoader(true);
  }

  function hideLoader() {
    setLoader(false);
  }

  const flashMessageClick = (type, message) => {
    setFlashMessage({
      ...flashMessage,
      ["active"]: true,
      ["type"]: type,
      ["message"]: message,
    });
  };

  const flashMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFlashMessage({
      ...flashMessage,
      ["active"]: false,
    });
  };

  const toggleContentPadding = (value) => {
    setSideMenuIsOpen(value);
  };

  function showWebsite() {
    document.getElementById("website").style.display = "block";
  }

  useEffect(() => {
    setIsUserAuthenticated(isAuthenticated);
  }, []);

  return (
    <Box sx={{ height: "100vh", width: "100vw" }}>
      {/* Splash Screen */}
      <Welcome showWebsite={showWebsite} />

      {/* Context API */}
      <GlobalData.Provider
        value={{
          flashMessage: flashMessageClick,
          smDown: smDown,
          mdDown: mdDown,
          baseUrl: baseUrl,
          navigate: navigate,
          location: location,
          showLoader: showLoader,
          hideLoader: hideLoader,
          setIsUserAuthenticated: setIsUserAuthenticated,
          useLocation: useLocation(),
          query: query,
          queryx: queryx,
          onlyXs: onlyXs,
          onlySm: onlySm,
          onlyMd: onlyMd,
          onlyLg: onlyLg,
          allowedModules: allowedModules,
          setAllowedModules: setAllowedModules,
        }}
      >
        {/* Loader */}

        {loader && (
          <Box
            style={{
              position: "fixed",
              height: "100%",
              width: "100%",
              backgroundColor: "rgb(0,0,0,0.8)",
              zIndex: "5",
            }}
          >
            <CircularProgress
              style={{ position: "absolute", top: "40%", left: "49%" }}
            />
          </Box>
        )}

        {/* Flash Message */}

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={flashMessage.active}
          autoHideDuration={2000}
          onClose={flashMessageClose}
        >
          <Alert
            onClose={flashMessageClose}
            severity={flashMessage.type}
            sx={{ width: "100%" }}
          >
            {flashMessage.message}
          </Alert>
        </Snackbar>

        {/* Main Content */}

        <Box id="website" style={{ display: "none" }}>
          {/* Authentication Check if login then show Menu and Routes (Website Content)  */}
          {isUserAuthenticated ? (
            <>
              {/* Menu */}
              {smDown ? (
                <MobileMenu toggleContentPadding={toggleContentPadding} />
              ) : (
                <SideMenu toggleContentPadding={toggleContentPadding} />
              )}

              {/* Routes */}
              <Routes>
                {RoutesData.map((route) => (
                  <Route
                    path={route.path}
                    element={
                      <Box
                        paddingLeft={
                          smDown ? "5px" : sideMenuIsOpen ? "285px" : "70px"
                        }
                      >
                        {
                          <ProtectComponent
                            component={route.component}
                            componentLocation={true}
                            componentNavigate={false}
                            id={route.text}
                          />
                        }
                      </Box>
                    }
                  />
                ))}
              </Routes>
            </>
          ) : (
            <Login />
          )}
        </Box>
      </GlobalData.Provider>
    </Box>
  );
}

export { GlobalData };
export default App;
