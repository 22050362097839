import axios from "axios";
const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const activityLog = (responseData) => {
  let user = JSON.parse(localStorage.getItem("user"));
  let accessToken = "";
  if (user) {
    accessToken = user.access_token;
  }
  let formData = new FormData();
  formData.append("user", JSON.stringify(user));
  formData.append("log", JSON.stringify(responseData));
  axios({
    method: "post",
    url: baseUrl + "api/activity-logs/createActivityLog",
    data: formData,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(function(response) {
      if (response.data.statusCode === 200) {
        // localStorage.removeItem("user");
        // window.location.reload();
      }
    })
    .catch(function(response) {
      console.log(response);
    });
};

export function get(api, callback) {
  let user = JSON.parse(localStorage.getItem("user"));
  let accessToken = "";
  if (user) {
    accessToken = user.access_token;
  }
  axios({
    method: "get",
    url: baseUrl + "api/" + api.endPoint,

    headers: {
      Accept: "application/json",

      Authorization: "Bearer " + accessToken,
    },
  })
    .then(function(response) {
      callback(response.data);
    })
    .catch(function(response) {
      activityLog(response);
    });
}

export function post(api, data, callback) {
  let user = JSON.parse(localStorage.getItem("user"));
  let accessToken = "";
  if (user) {
    accessToken = user.access_token;
  }
  axios({
    method: "post",
    url: baseUrl + "api/" + api.endPoint,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(function(response) {
      callback(response.data);
    })
    .catch(function(response) {
      activityLog(response);
    });
}
export function getWithParams(api, data, callback) {
  let user = JSON.parse(localStorage.getItem("user"));
  let accessToken = "";
  if (user) {
    accessToken = user.access_token;
  }
  console.log("DATA", data);

  axios({
    method: "get",
    url: baseUrl + "api/" + api.endPoint,
    params: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(function(response) {
      callback(response.data);
    })
    .catch(function(response) {
      activityLog(response);
    });
}

export function delte(api, data, callback) {
  let user = JSON.parse(localStorage.getItem("user"));
  let accessToken = "";
  if (user) {
    accessToken = user.access_token;
  }
  console.log("data fro deleteing", data);
  axios({
    method: "delete",
    url: baseUrl + "api/" + api.endPoint,
    params: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(function(response) {
      console.log("this is message", response);
      callback(response.data);
    })
    .catch(function(response) {
      activityLog(response);
    });
}
