import "./SideMenu.css";
import {
  Grid,
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  MenuList,
  Button,
  Divider,
} from "@mui/material";
import "@fontsource/poppins";
import Routes from "../Routes";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { BsBoxArrowInLeft, BsBoxArrowInRight } from "react-icons/bs";
import { AiOutlinePoweroff } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import { logout } from "../api/AuthAPI";
import { GlobalData } from "../App";
import ProtectComponent from "../middleware/ProtectComponent";
import { BsArrowDownShort } from "react-icons/bs";
import ContentPaste from "@mui/icons-material/ContentPaste";

function SideMenu(props) {
  const {
    smDown,
    flashMessage,
    navigate,
    showLoader,
    hideLoader,
    setIsUserAuthenticated,
    location,
  } = useContext(GlobalData);

  const [isOpen, setIsOpen] = useState(false);

  const [routesData, setRoutesData] = useState([]);

  const toggle = () => {
    setIsOpen(!isOpen);
    props.toggleContentPadding(!isOpen);
  };

  // function handleLogout() {
  //   showLoader();
  //   logout([], (resp) => {
  //     if (resp.statusCode === 200) {
  //       localStorage.removeItem("user");
  //       setIsUserAuthenticated(false);
  //       navigate("/");
  //       setTimeout(() => {
  //         hideLoader();
  //         flashMessage("success", resp.message);
  //       }, 3000);
  //     } else {
  //       hideLoader();
  //       flashMessage("error", resp.message);
  //     }
  //   });
  // }

  function handleLogout() {
    showLoader();
    localStorage.removeItem("user");
    setIsUserAuthenticated(false);
    navigate("/");
    setTimeout(() => {
      hideLoader();
      flashMessage("success", "User Logout Successfully");
    }, 3000);
    logout([], (resp) => {});
  }

  useEffect(() => {
    setRoutesData(Routes);
    setTimeout(() => {
      props.toggleContentPadding(true);
      setIsOpen(true);
    }, 4000);
  }, []);

  const currentPath = (path) => {
    if (
      path === location.pathname ||
      (location.pathname.includes("products") && path === "/products") ||
      (location.pathname.includes("orders") &&
        location.pathname !== "/staff/orders" &&
        path === "/orders") ||
      (location.pathname.includes("accounts") && path === "/accounts") ||
      (location.pathname.includes("vendors") && path === "/vendors") ||
      (location.pathname.includes("partners") && path === "/partners") ||
      (location.pathname.includes("users") && path === "/users") ||
      (location.pathname.includes("staff") && path === "/staff") ||
      (location.pathname.includes("bank") && path === "/bank")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const logoAnimation = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  const menuAnimation = {
    hidden: {
      opacity: 0,
      width: 0,
      transition: { duration: 0.3 },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.3,
      },
    },
  };

  const handleSubMenu = (index) => {
    let data = [...Routes];
    data[index].subMenuActive = !data[index].subMenuActive;
    if (data[index].subMenu.length > 0) {
      navigate(data[index].path);
      setRoutesData(data);
    } else {
      let resetData = [...data];
      navigate(data[index].path);
      data.forEach((v, i) => {
        if (i !== index) {
          resetData[i].subMenuActive = false;
        }
      });
      setRoutesData(resetData);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <motion.div
      animate={{
        width: isOpen ? "280px" : "46px",
        transition: {
          duration: 1,
          type: "spring",
          damping: 15,
        },
      }}
      className="side_menu"
      style={isOpen ? {} : { padding: "10px" }}
    >
      <div class="container__sidmn">
        <header className="header_sidmn">
          <Grid container display="flex" justifyContent="end" mt={1}>
            <Grid item>
              {isOpen ? (
                <BsBoxArrowInLeft
                  onClick={() => {
                    toggle();
                  }}
                  className="sideMenuBtn"
                />
              ) : (
                <BsBoxArrowInRight
                  onClick={() => {
                    toggle();
                  }}
                  className="sideMenuBtn"
                />
              )}
            </Grid>
          </Grid>
          {isOpen ? (
            <>
              <AnimatePresence>
                <div
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={logoAnimation}
                >
                  <Grid container display="flex" justifyContent="center">
                    <Grid item className="logo__side_menu" />
                  </Grid>
                </div>
              </AnimatePresence>
              <br />
            </>
          ) : (
            <hr style={{ opacity: 0.5 }} />
          )}
        </header>
        <div class="body__sidmn" style={{ marginTop: `${isOpen ? 0 : "5px"}` }}>
          <AnimatePresence>
            {routesData.map((value, index) => (
              <>
                {value.text !== "" ? (
                  <ProtectComponent
                    id={value.text}
                    componentLocation={false}
                    componentNavigate={true}
                    component={
                      <Grid container display="flex" justifyContent="center">
                        <motion.div
                          animate={
                            value.subMenuActive
                              ? {
                                  height: `${
                                    isOpen
                                      ? 40 + 50 * value.subMenu.length
                                      : 32 + 36 * value.subMenu.length
                                  }px`,
                                  transition: {
                                    duration: 1,
                                    type: "spring",
                                    damping: 15,
                                  },
                                }
                              : {
                                  height: `${isOpen ? 40 : 32}px`,
                                  transition: {
                                    duration: 1,
                                    type: "spring",
                                    damping: 15,
                                  },
                                }
                          }
                          className={`${
                            currentPath(value.path)
                              ? "subMenu__sidemnu subMenuStl__sidmnu"
                              : "subMenuStl__sidmnu"
                          }`}
                        >
                          <Button
                            onClick={() => {
                              handleSubMenu(index);
                            }}
                            className="menuButton"
                            style={
                              currentPath(value.path)
                                ? {
                                    backgroundColor: "#ECAE33",
                                    color: "#1E2738",
                                    justifyContent: `${
                                      isOpen ? "start" : "center"
                                    }`,
                                    "&:hover": {
                                      background: "rgb(236, 174, 51, 0.3)",
                                    },
                                  }
                                : {
                                    justifyContent: `${
                                      isOpen ? "start" : "center"
                                    }`,
                                    "&:hover": {
                                      background: "rgb(236, 174, 51, 0.3)",
                                    },
                                    color: "white",
                                  }
                            }
                            endIcon={
                              isOpen && value.subMenu.length > 0 ? (
                                <BsArrowDownShort
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "8px",
                                  }}
                                  size={24}
                                  className={`${
                                    currentPath(value.path) &&
                                    value.subMenuActive
                                      ? "sidarw_rt"
                                      : ""
                                  }`}
                                />
                              ) : (
                                ""
                              )
                            }
                          >
                            <div
                              className="icon"
                              style={
                                isOpen
                                  ? { marginRight: "12px" }
                                  : { marginRight: 0 }
                              }
                            >
                              {currentPath(value.path)
                                ? value.active
                                : value.icon}
                            </div>

                            {isOpen && (
                              <motion.div
                                variants={menuAnimation}
                                initial="hidden"
                                animate="show"
                                exit="hidden"
                              >
                                {value.text}
                              </motion.div>
                            )}
                          </Button>

                          {isOpen ? (
                            <MenuList>
                              {currentPath(value.path) && value.subMenuActive
                                ? value.subMenu.map((v, i) => (
                                    <Box>
                                      <MenuItem
                                        onClick={() => {
                                          handleNavigate(v.path);
                                        }}
                                      >
                                        <ListItemIcon>{v.icon}</ListItemIcon>
                                        <ListItemText>{v.text}</ListItemText>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                        />
                                      </MenuItem>
                                      {i + 1 !== value.subMenu.length && (
                                        <Divider color="#ecae33" />
                                      )}
                                    </Box>
                                  ))
                                : ""}
                            </MenuList>
                          ) : (
                            <Box mt={0.6}>
                              {currentPath(value.path) && value.subMenuActive
                                ? value.subMenu.map((v, i) => (
                                    <Box display="flex" justifyContent="center">
                                      <Button
                                        onClick={() => {
                                          handleNavigate(v.path);
                                        }}
                                      >
                                        {v.icon}
                                      </Button>
                                    </Box>
                                  ))
                                : ""}
                            </Box>
                          )}
                        </motion.div>
                      </Grid>
                    }
                  />
                ) : null}
              </>
            ))}
          </AnimatePresence>
        </div>
        <footer className="footer__sidmn">
          <AnimatePresence>
            <Grid container style={isOpen ? { justifyContent: "center" } : {}}>
              <Grid
                className="logout-btn"
                item
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={isOpen ? { width: "260px" } : {}}
                onClick={handleLogout}
              >
                {isOpen ? (
                  <motion.div
                    variants={menuAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                  >
                    Logout
                  </motion.div>
                ) : null}
                <div
                  className="icon"
                  style={isOpen ? { marginLeft: "10px" } : {}}
                >
                  <AiOutlinePoweroff style={{ fontSize: "20px" }} />
                </div>
              </Grid>
            </Grid>
          </AnimatePresence>
        </footer>
      </div>
    </motion.div>
  );
}

export default SideMenu;
