import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TableCell,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Menu,
} from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import SearchField from "../../widgets/SearchField";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { styled } from "@mui/material/styles";
import {
  getPartnerDetailsById,
  addPartnerTransaction,
  deletePartnerTransaction,
} from "../../api/PartnerAPI";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as BalanceIcon } from "../../assets/SVG/BalanceSmallIcon.svg";
import { ReactComponent as DebitIcon } from "../../assets/SVG/DebitSmallIcon.svg";
import { ReactComponent as CreditIcon } from "../../assets/SVG/CreditSmallIcon.svg";
import { ReactComponent as InvestmentIcon } from "../../assets/SVG/InvestmentSmallIcon.svg";
import { ReactComponent as CashWithdrawIcon } from "../../assets/SVG/CashWithdrawSmallIcon.svg";
import { ReactComponent as PendingProfitIcon } from "../../assets/SVG/PendingProfitSmallIcon.svg";
import DeleteDialog from "../../widgets/DeleteDialog";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import "./PartnerKhata.css";
import { debounce } from "lodash";
import CustomTable from "../../widgets/CustomTable";
import DateFilter from "../../widgets/DateFilter";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SimpleMenu from "../../widgets/SimpleMenu";
import TopMidHeader from "../../widgets/TopMidHeader";

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "partner_name",
    label: "Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_type",
    label: "Type",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_amount",
    label: "Amount",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_date",
    label: "Date",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_description",
    label: "Description",
    minWidth: 30,
    align: "left",
  },
];

const options = [
  {
    icon: (
      <div>
        <DeleteIcon />
      </div>
    ),
    label: "Delete",
    method: "delete",
    type: "icon",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const transactionObject = {
  partner_name: "",
  partner_shop_name: "",
  transaction_amount: "",
  transaction_description: "",
  transaction_type: "",
  transaction_credit_order_id: "",
  partner_id: "",
};
function PartnerKhata() {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    location,
    onlyXs,
  } = useContext(GlobalData);

  const [selectedCashType, setSelectedCashType] = useState("Shop Cash");

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [partner, setPartner] = useState("");
  const [accounts, setAccounts] = useState("");
  const [transaction_id, setTransaction_id] = useState("");
  const [partnersCount, setPartnersCount] = useState([]);
  const [transactions, setTransactions] = useState([{}]);
  const [showForm, setShowForm] = useState(false);
  const [transactionData, setTransactionData] = useState(transactionObject);
  const [total_credit, setTotal_credit] = useState(0);
  const [total_debit, setTotal_debit] = useState(0);
  const [temp, setTemp] = useState("");
  const [partnerDescription, setPartnerDescription] = useState("");
  const [total_balance, setTotal_balance] = useState(0);
  const partner_id = location.state.partnerData;
  const [searchText, setSearchText] = useState("");
  const [partnerKhataDate, setPartnerKhataDate] = useState("This Month");
  const [transactionDate, setTransactionDate] = useState(null);
  const handlegetAllTransactions = (pn) => {
    const data = {
      partner_id: partner_id,
      per_page: 10,
      page: pn,
      search_text: searchText,
    };
    console.log("DAta", data);
    showLoader();
    getPartnerDetailsById(data, (resp) => {
      if (resp.statusCode === 200) {
        setPartner(resp.successData.partner);
        setAccounts(resp.successData.accounts);
        setTotal_credit(resp.successData.partner.partner_total_credit);
        setTotal_debit(resp.successData.partner.partner_total_debit);
        setTotal_balance(resp.successData.partner.partner_total_balance);
        setTransactionData({
          ...transactionData,
          partner_name: resp.successData.partner.partner_name,
          partner_id: resp.successData.partner.id,
          partner_shop_name: resp.successData.partner.partner_shop_name,
          transaction_amount: "",
          transaction_description: "",
        });
        if (pn === 1) {
          setTransactions(resp.successData.partner.transactions);
          hideLoader();
        } else {
          setTransactions((dt) => {
            dt.push(...resp.successData.partner.transactions);
            hideLoader();
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };
  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);
  const openDeleteProductDialog = (product) => {
    console.log("this is Product", product);
    setTransaction_id(product.id);

    setDeleteProductDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeleteProduct = () => {
    showLoader();
    const tempdata = { partner_id: partner_id, transaction_id: transaction_id };

    deletePartnerTransaction(tempdata, (resp) => {
      if (resp.statusCode === 200) {
        flashMessage("success", resp.message);
        updateDataUsingPageNumber();
        setDeleteProductDialog(false);
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        setDeleteProductDialog(false);
        hideLoader();
      }
    });
  };

  const openAddTransactionDialog = (value) => {
    setTransactionData({
      ...transactionData,
      transaction_type: value,
    });
    setTransactionDate(getTodayDate());
    setShowForm(true);
  };

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  const closeTransactionDialog = () => {
    setShowForm(false);
    setTransactionDate(null);
  };
  const handleAddTransaction = () => {
    showLoader();
    let formData = new FormData();
    formData.append("partner_name", transactionData.partner_name);
    formData.append("partner_id", transactionData.partner_id);
    formData.append("partner_shop_name", transactionData.partner_shop_name);
    formData.append("transaction_amount", transactionData.transaction_amount);
    formData.append(
      "transaction_description",
      transactionData.transaction_description
    );
    formData.append("transaction_type", transactionData.transaction_type);
    formData.append("cash_type", selectedCashType);

    var date = new Date(transactionDate);
    formData.append(
      "transaction_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    addPartnerTransaction(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        updateDataUsingPageNumber();
        setTransactionData({
          ...transactionData,
          transaction_amount: "",
          transaction_description: "",
        });
        setTransactionDate(null);
        setShowForm(false);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
        setTransactionDate(null);
      }
    });
  };
  const handleTransactionInput = (e) => {
    const { name, value } = e.target;
    if (name === "transaction_amount") {
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        setTransactionData({
          ...transactionData,
          [name]: value,
        });
      }
    } else {
      setTransactionData({
        ...transactionData,
        [name]: value,
      });
    }
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    handlegetAllTransactions(page);
  }, [page, searchText]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      handlegetAllTransactions(page);
    }
  };

  return (
    <>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteProductDialog}
        handleRemove={handleDeleteProduct}
        title={"Remove this Transaction?"}
        sub_title={
          "You are about to remove a transaction. Click on the “Remove” button to remove it."
        }
      />
      {/*========================================================Add Transaction Dialog */}
      <Dialog
        open={showForm}
        onClose={closeTransactionDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Create {transactionData.transaction_type} Transaction
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs pr={0.5}>
              <CustomTextField
                value={transactionData.transaction_amount}
                onChange={handleTransactionInput}
                name={"transaction_amount"}
                id={"transaction_amount"}
                key={"transaction_amount"}
                placeholder={"Transaction Amount"}
                className={"textField__AddInventory"}
              />
            </Grid>
            <Grid item xs pl={0.5}>
              <SimpleMenu
                handleSetData={setSelectedCashType}
                data={["Bank Cash", "Shop Cash"]}
                selectedValue={selectedCashType}
                className={"bnkmnu_field__bank"}
                width={"268px"}
              />
            </Grid>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="bnkmnu_field__bank"
                value={transactionDate}
                onChange={(newValue) => {
                  setTransactionDate(newValue);
                }}
                renderInput={(params) => (
                  <CustomTextField
                    error={false}
                    {...params}
                    placeholder={"mm/dd/yyyy"}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Box>
            <CustomTextField
              value={transactionData.transaction_description}
              onChange={handleTransactionInput}
              multiline
              rows={3}
              name="transaction_description"
              id="transaction_description"
              key="transaction_description"
              placeholder="Description"
              className="textField__AddInventory"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={closeTransactionDialog}
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            onClick={handleAddTransaction}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/*========================================================MAin View  */}
      <Grid container height="100vh" paddingRight="5px">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box height={smDown ? "30vh" : "20vh"}>
            <NavigationBar
              path={"Dashboard>Partners Management>Partners>Partner Khata"}
              paddingBottom={1}
            />
            <TopMidHeader
              path={"/partners"}
              back={"Back"}
              title1={"Partner Management"}
              name={transactionData.partner_name}
              title2={"Partner Khata"}
            />
          </Box>

          <Grid container display="flex" justifyContent="start" mt={1}>
            <Box
              backgroundColor="#EDEBFE"
              className="bnkcrd_bx__bank"
              marginBottom="6px"
            >
              <Box className="bnkcrd_bx_icn_bx__bank">
                <BalanceIcon />
              </Box>
              <Box>
                <Box className="bnkcrd_txt1__bank">Total Balance</Box>
                <Box className="bnkcrd_txt2__bank">
                  {partner.partner_total_balance} AED
                </Box>
              </Box>
            </Box>

            <Box
              backgroundColor="#E4F3F1"
              className="bnkcrd_bx__bank"
              marginBottom="6px"
            >
              <Box className="bnkcrd_bx_icn_bx__bank">
                <DebitIcon />
              </Box>
              <Box>
                <Box className="bnkcrd_txt1__bank">Total Debit</Box>
                <Box className="bnkcrd_txt2__bank">
                  {partner.partner_total_debit} AED
                </Box>
              </Box>
              <Box>
                <IconButton
                  aria-label="delete"
                  size="small"
                  className="cio_btn__bank"
                  onClick={() => {
                    openAddTransactionDialog("Debit");
                  }}
                >
                  <AiOutlinePlus color={"#1E2738"} size={18} />
                </IconButton>
              </Box>
            </Box>

            <Box
              backgroundColor="#FFE8DE"
              className="bnkcrd_bx__bank"
              marginBottom="6px"
            >
              <Box className="bnkcrd_bx_icn_bx__bank">
                <CreditIcon />
              </Box>
              <Box>
                <Box className="bnkcrd_txt1__bank">Total Credit</Box>
                <Box className="bnkcrd_txt2__bank">
                  {partner.partner_total_credit} AED
                </Box>
              </Box>
              <Box>
                <IconButton
                  aria-label="delete"
                  size="small"
                  className="cio_btn__bank"
                  onClick={() => {
                    openAddTransactionDialog("Credit");
                  }}
                >
                  <AiOutlinePlus color={"#1E2738"} size={18} />
                </IconButton>
              </Box>
            </Box>

            <Box
              backgroundColor="#E2FFE9"
              className="bnkcrd_bx__bank"
              marginBottom="6px"
            >
              <Box className="bnkcrd_bx_icn_bx__bank">
                <InvestmentIcon />
              </Box>
              <Box>
                <Box className="bnkcrd_txt1__bank">Total Investment</Box>
                <Box className="bnkcrd_txt2__bank">
                  {partner.partner_total_investment} AED
                </Box>
              </Box>
              <Box>
                <IconButton
                  aria-label="delete"
                  size="small"
                  className="cio_btn__bank"
                  onClick={() => {
                    openAddTransactionDialog("Investment");
                  }}
                >
                  <AiOutlinePlus color={"#1E2738"} size={18} />
                </IconButton>
              </Box>
            </Box>

            <Box
              backgroundColor="#FCF9BE"
              className="bnkcrd_bx__bank"
              marginBottom="6px"
            >
              <Box className="bnkcrd_bx_icn_bx__bank">
                <PendingProfitIcon />
              </Box>
              <Box>
                <Box className="bnkcrd_txt1__bank">Total Pending Profit</Box>
                <Box className="bnkcrd_txt2__bank">
                  {accounts.totalProfit !== undefined
                    ? (
                        accounts.totalProfit *
                          (partner.partner_percentage / 100) -
                        partner.partner_total_profit_withdraw
                      ).toFixed(2)
                    : ""}
                  &nbsp;AED
                </Box>
              </Box>
            </Box>

            <Box
              backgroundColor="#EFD6F5"
              className="bnkcrd_bx__bank"
              marginBottom="6px"
            >
              <Box className="bnkcrd_bx_icn_bx__bank">
                <CashWithdrawIcon />
              </Box>
              <Box>
                <Box className="bnkcrd_txt1__bank">Total Profit Withdraw</Box>
                <Box className="bnkcrd_txt2__bank">
                  {partner.partner_total_profit_withdraw !== undefined
                    ? partner.partner_total_profit_withdraw.toFixed(2)
                    : ""}{" "}
                  AED
                </Box>
              </Box>
              <Box>
                <IconButton
                  aria-label="delete"
                  size="small"
                  className="cio_btn__bank"
                  onClick={() => {
                    openAddTransactionDialog("Profit Withdraw");
                  }}
                >
                  <AiOutlinePlus color={"#1E2738"} size={18} />
                </IconButton>
              </Box>
            </Box>
          </Grid>

          <Grid container marginBottom={2}>
            <Grid
              xl={10}
              lg={10}
              md={10}
              sm={10}
              xs={12}
              item
              paddingRight="5px"
            >
              <SearchField
                onChange={updateProductList}
                tableDataCount={transactionData.length}
                totalAvailableDataCount={partnersCount}
              />
            </Grid>
            <Grid
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={12}
              item
              display="flex"
              justifyContent={smDown ? "center" : "end"}
              paddingTop={smDown ? 1 : 0}
              paddingRight={smDown ? "0px" : "10px"}
            >
              {/* <DateFilter
                setDateObject={setPartnerKhataDate}
                dateObject={partnerKhataDate}
                className={"download_report_btn__orders"}
              /> */}
            </Grid>
          </Grid>

          <Grid container>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <CustomTable
                // view={handleViewProduct}
                // edit={handleEditProduct}
                delete={openDeleteProductDialog}
                options={options}
                columns={columns}
                data={transactions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default PartnerKhata;
