import "./Orders.css";
import {
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import { AiFillCaretDown } from "react-icons/ai";
import CustomTable from "../../widgets/CustomTable";
import SearchField from "../../widgets/SearchField";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useEffect, useContext, useMemo } from "react";
import Divider from "@mui/material/Divider";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import {
  getAllOrders,
  changeOrderStatus,
  deleteOrder,
} from "../../api/OrderAPI";
import { orderInvoice } from "../../api/ReportAPI";
import { debounce } from "lodash";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PrintIcon from "@mui/icons-material/Print";
import ProtectComponent from "../../middleware/ProtectComponent";
import { DateRangePicker } from "mui-daterange-picker";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

const options = [
  {
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1250_558)">
          <path
            d="M17.2786 6.66288C15.4573 3.8528 12.3478 2.14372 8.99922 2.1123C5.65068 2.14372 2.54118 3.8528 0.719861 6.66288C-0.239954 8.07146 -0.239954 9.92395 0.719861 11.3326C2.54015 14.1444 5.64984 15.8552 8.99926 15.8877C12.3478 15.8563 15.4573 14.1472 17.2787 11.3371C18.2405 9.92745 18.2405 8.07249 17.2786 6.66288ZM15.415 10.0554C14.0244 12.261 11.6065 13.6066 8.99922 13.626C6.392 13.6067 3.97405 12.261 2.58348 10.0554C2.14997 9.41856 2.14997 8.58137 2.58348 7.94453C3.97401 5.73896 6.39196 4.39335 8.99922 4.37402C11.6065 4.39331 14.0244 5.73896 15.415 7.94453C15.8485 8.58137 15.8485 9.41856 15.415 10.0554Z"
            fill="rgba(30,39,56,0.8)"
          />
          <path
            d="M8.99998 12.0156C10.6655 12.0156 12.0156 10.6655 12.0156 8.99998C12.0156 7.33451 10.6655 5.98438 8.99998 5.98438C7.33451 5.98438 5.98438 7.33451 5.98438 8.99998C5.98438 10.6655 7.33451 12.0156 8.99998 12.0156Z"
            fill="rgba(30,39,56,0.8)"
          />
        </g>
        <defs>
          <clipPath id="clip0_1250_558">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    label: "View",
    method: "view",
    type: "icon",
  },
  {
    icon: (
      <PublishedWithChangesIcon
        style={{ opacity: "0.8", marginLeft: "-2.5px" }}
      />
    ),
    label: <span style={{ marginLeft: "-4.5px" }}>Status</span>,
    method: "status",
    type: "icon",
  },
  {
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.75 13.5H6.93C7.0287 13.5006 7.12655 13.4817 7.21793 13.4443C7.30931 13.407 7.39242 13.352 7.4625 13.2825L12.6525 8.08502L14.7825 6.00002C14.8528 5.93029 14.9086 5.84734 14.9467 5.75595C14.9847 5.66455 15.0043 5.56652 15.0043 5.46752C15.0043 5.36851 14.9847 5.27048 14.9467 5.17908C14.9086 5.08769 14.8528 5.00474 14.7825 4.93502L11.6025 1.71752C11.5328 1.64722 11.4498 1.59142 11.3584 1.55335C11.267 1.51527 11.169 1.49567 11.07 1.49567C10.971 1.49567 10.873 1.51527 10.7816 1.55335C10.6902 1.59142 10.6072 1.64722 10.5375 1.71752L8.4225 3.84002L3.2175 9.03752C3.14799 9.1076 3.09299 9.19071 3.05567 9.28209C3.01835 9.37346 2.99943 9.47131 3 9.57002V12.75C3 12.9489 3.07902 13.1397 3.21967 13.2803C3.36032 13.421 3.55109 13.5 3.75 13.5ZM11.07 3.30752L13.1925 5.43002L12.1275 6.49502L10.005 4.37252L11.07 3.30752ZM4.5 9.87752L8.9475 5.43002L11.07 7.55252L6.6225 12H4.5V9.87752ZM15.75 15H2.25C2.05109 15 1.86032 15.079 1.71967 15.2197C1.57902 15.3603 1.5 15.5511 1.5 15.75C1.5 15.9489 1.57902 16.1397 1.71967 16.2803C1.86032 16.421 2.05109 16.5 2.25 16.5H15.75C15.9489 16.5 16.1397 16.421 16.2803 16.2803C16.421 16.1397 16.5 15.9489 16.5 15.75C16.5 15.5511 16.421 15.3603 16.2803 15.2197C16.1397 15.079 15.9489 15 15.75 15Z"
          fill="rgba(30,39,56,1)"
        />
      </svg>
    ),
    label: "Edit",
    method: "edit",
    type: "icon",
  },
  // {
  //   icon: <TransformIcon style={{ opacity: "0.7", marginLeft: "-2.5px" }} />,
  //   label: <span style={{ marginLeft: "-4.5px" }}>Return</span>,
  //   method: "return",
  //   type: "icon",
  // },
  {
    icon: <PrintIcon style={{ opacity: "0.7", marginLeft: "-2.5px" }} />,
    label: <span style={{ marginLeft: "-4.5px" }}>Print</span>,
    method: "print",
    type: "icon",
  },
  // {
  //   icon: (
  //     <svg
  //       width="18"
  //       height="18"
  //       viewBox="0 0 18 18"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M9 15.1875C12.4173 15.1875 15.1875 12.4173 15.1875 9C15.1875 5.58274 12.4173 2.8125 9 2.8125C5.58274 2.8125 2.8125 5.58274 2.8125 9C2.8125 12.4173 5.58274 15.1875 9 15.1875Z"
  //         stroke="rgba(30,39,56,0.8)"
  //         stroke-width="2"
  //         stroke-linecap="round"
  //         stroke-linejoin="round"
  //       />
  //       <path
  //         d="M5.24805 13.8769L13.8543 5.27625"
  //         stroke="rgba(30,39,56,0.8)"
  //         stroke-width="3"
  //         stroke-linejoin="round"
  //       />
  //       <path
  //         d="M3.83008 12.7744L12.4813 4.12311"
  //         stroke="rgba(30,39,56,0.8)"
  //         stroke-miterlimit="10"
  //       />
  //     </svg>
  //   ),
  //   label: "Delete",
  //   method: "delete",
  //   type: "icon",
  // },
];

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    align: "left",
  },
  {
    id: "invoice_code",
    label: "Order ID",

    align: "left",
  },
  {
    id: "total",
    label: "Total Cash",

    align: "left",
  },
  {
    id: "customer_name",
    label: "Customer Name",
    align: "left",
  },
  {
    id: "customer_phone",
    label: "Phone Number",
    align: "left",
  },
  {
    id: "quantity",
    label: "Total Products",

    align: "left",
  },
  {
    id: "order_date",
    label: "Order Date",
    align: "left",
  },
  {
    id: "order_type",
    label: "Type",
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    align: "left",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

function Orders(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    mdDown,
    onlyMd,
    baseUrl,
    location,
  } = useContext(GlobalData);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  //
  const [ordersDate, setOrdersDate] = useState("This Month");
  //--
  const [dateMenuElement, setDateMenuElement] = useState(null);
  const isDateMenuOpen = Boolean(dateMenuElement);
  const ITEM_HEIGHT = 48;
  const handleOpenDateMenu = (event) => {
    setDateMenuElement(event.currentTarget);
  };
  const handleCloseDateMenu = () => {
    setDateMenuElement(null);
  };
  //--
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const toggleDateRangerPicker = () =>
    setOpenDateRangePicker(!openDateRangePicker);
  const handleOrdersDateFilter = (option) => {
    handleCloseDateMenu();
    if (option === "Custom") {
      toggleDateRangerPicker();
    } else {
      setOrdersDate(option);
    }
  };

  const handleDateRange = (range) => {
    setDateRange(range);
    setOrdersDate(range);
    setTimeout(() => {
      toggleDateRangerPicker();
    }, 500);
  };
  //

  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("All");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [orderType, setOrderType] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [orderStatusCount, setOrderStatusCount] = useState("");
  const [orderTypeCount, setOrderTypeCount] = useState("");
  const [
    isChangeOrderStatusDialogOpen,
    setIsChangeOrderStatusDialogOpen,
  ] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const [orderDescription, setOrderDescription] = useState("");

  const [allOrderStatus, setAllOrderStatus] = useState([
    "Paid",
    "Unpaid",
    "Cancelled",
  ]);

  const [deleteProductDialog, setDeleteProductDialog] = useState(false);

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeleteOrder = () => {
    handleCloseDeleteDialog();
    showLoader();
    let formData = new FormData();
    formData.append("order_id", selectedOrder.id);
    deleteOrder(formData, (resp) => {
      if (resp.statusCode === 200) {
        updateDataUsingPageNumber();
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const openDeleteOrderDialog = (order) => {
    setSelectedOrder(order);
    setDeleteProductDialog(true);
  };

  const getAllOrdersMethod = (pn) => {
    showLoader();
    let formData = new FormData();
    formData.append("search_text", searchText);
    formData.append("order_type", orderType);
    formData.append("status", orderStatus);
    formData.append("date", JSON.stringify(ordersDate));
    formData.append("per_page", process.env.REACT_APP_PER_PAGE);
    formData.append("page", pn);
    if (location.pathname === "/staff/orders") {
      formData.append("staff_user_id", location.state.memberData.user_id);
    }
    getAllOrders(formData, (resp) => {
      if (resp.statusCode === 200) {
        setOrderStatusCount(resp.successData.order_status_count);
        setOrderTypeCount(resp.successData.order_type_count);
        if (resp.statusCode === 200) {
          if (pn === 1) {
            setOrders(resp.successData.orders);
            hideLoader();
          } else {
            setOrders((dt) => {
              dt.push(...resp.successData.orders);
              hideLoader();
              return dt;
            });
          }
          setLastPage(resp.successData.last_page);
        }
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const changeOrderDescription = (e) => {
    setOrderDescription(e.target.value);
  };

  const handleOrderStatus = (order) => {
    setSelectedOrder(order);
    setSelectedOrderStatus("");
    setOrderDescription(order.description);
    handleChangeOrderStatusDialogOpen();
  };

  const handleViewOrder = (data) => {
    if (location.pathname === "/staff/orders") {
      navigate("/orders/order-detail", {
        state: {
          orderData: data,
          orderStatus: orderStatus,
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders/order-detail", {
        state: {
          orderData: data,
          orderStatus: orderStatus,
          path: location.pathname,
        },
      });
    }
  };

  // Order Status Dialog Methods

  const handleChangeOrderStatusDialogOpen = () => {
    setIsChangeOrderStatusDialogOpen(true);
  };

  const handleChangeOrderStatusDialogClose = () => {
    setIsChangeOrderStatusDialogOpen(false);
  };

  const handleChangeOrderStatus = () => {
    if (selectedOrderStatus === "") {
      flashMessage("error", "Please Select Order Status");
    } else {
      handleChangeOrderStatusDialogClose();
      showLoader();
      let formData = new FormData();
      formData.append("order_id", selectedOrder.id);
      formData.append("order_status", selectedOrderStatus);
      formData.append("description", orderDescription);
      changeOrderStatus(formData, (resp) => {
        if (resp.statusCode === 200) {
          updateDataUsingPageNumber();
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    }
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const handleEditOrder = (data) => {
    if (location.pathname === "/staff/orders") {
      navigate("/orders/update-order", {
        state: {
          orderData: data,
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders/update-order", {
        state: {
          orderData: data,
          path: location.pathname,
        },
      });
    }
  };

  const handlePrintSingleOrder = (order) => {
    showLoader();
    let formData = new FormData();
    formData.append("orders", JSON.stringify([order]));
    orderInvoice(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handlePrintAllOrders = () => {
    showLoader();
    let formData = new FormData();
    formData.append("orders", JSON.stringify(orders));
    orderInvoice(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    getAllOrdersMethod(page);
  }, [page]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllOrdersMethod(page);
    }
  };

  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText, orderType, orderStatus, ordersDate]);

  const handleNavigateToAddOrder = () => {
    if (location.pathname === "/staff/orders") {
      navigate("/orders/add-new-order", {
        state: {
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders/add-new-order", {
        state: {
          path: location.pathname,
        },
      });
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={deleteProductDialog}
        onClose={handleCloseDeleteDialog}
        id="deleteProductDialog"
      >
        <DialogContent id="deleteProductDialogContent__inv">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              style={{ textAlign: "center" }}
            >
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginTop: "60px" }}
              >
                <circle cx="40" cy="40" r="40" fill="#FF0000" />
                <g clip-path="url(#clip0_605_8451)">
                  <path
                    d="M40 20C36.0444 20 32.1776 21.173 28.8886 23.3706C25.5996 25.5682 23.0362 28.6918 21.5224 32.3463C20.0087 36.0009 19.6126 40.0222 20.3843 43.9018C21.156 47.7814 23.0608 51.3451 25.8579 54.1421C28.6549 56.9392 32.2186 58.844 36.0982 59.6157C39.9778 60.3874 43.9992 59.9913 47.6537 58.4776C51.3082 56.9638 54.4318 54.4004 56.6294 51.1114C58.827 47.8224 60 43.9556 60 40C59.9943 34.6974 57.8853 29.6137 54.1358 25.8642C50.3863 22.1147 45.3026 20.0057 40 20V20ZM40 56.6667C36.7037 56.6667 33.4813 55.6892 30.7405 53.8578C27.9997 52.0265 25.8635 49.4235 24.602 46.3781C23.3406 43.3326 23.0105 39.9815 23.6536 36.7485C24.2967 33.5155 25.884 30.5458 28.2149 28.2149C30.5458 25.884 33.5155 24.2967 36.7485 23.6536C39.9815 23.0105 43.3326 23.3405 46.3781 24.602C49.4235 25.8635 52.0265 27.9997 53.8578 30.7405C55.6892 33.4813 56.6667 36.7036 56.6667 40C56.6618 44.4188 54.9043 48.6552 51.7798 51.7798C48.6552 54.9043 44.4188 56.6618 40 56.6667V56.6667Z"
                    fill="white"
                  />
                  <path
                    d="M40.0007 28.3333C39.5586 28.3333 39.1347 28.5089 38.8221 28.8215C38.5096 29.1341 38.334 29.558 38.334 30V43.3333C38.334 43.7754 38.5096 44.1993 38.8221 44.5119C39.1347 44.8244 39.5586 45 40.0007 45C40.4427 45 40.8666 44.8244 41.1792 44.5119C41.4917 44.1993 41.6673 43.7754 41.6673 43.3333V30C41.6673 29.558 41.4917 29.1341 41.1792 28.8215C40.8666 28.5089 40.4427 28.3333 40.0007 28.3333Z"
                    fill="white"
                  />
                  <path
                    d="M41.6673 50C41.6673 49.0795 40.9211 48.3333 40.0007 48.3333C39.0802 48.3333 38.334 49.0795 38.334 50C38.334 50.9205 39.0802 51.6667 40.0007 51.6667C40.9211 51.6667 41.6673 50.9205 41.6673 50Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_605_8451">
                    <rect
                      width="40"
                      height="40"
                      fill="white"
                      transform="translate(20 20)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <Box className="deleteProductDialogActionText1_inv">
                Remove this Order?
              </Box>
              <Box className="deleteProductDialogActionText2_inv">
                You are about to remove a Order. Click on the “Remove” button to
                remove it.
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="deleteDialogActions">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              className="deleteDialogBtn__inv deleteDialogBtnNo__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={handleCloseDeleteDialog}
            >
              Don’t Remove
            </Grid>
            <Grid
              className="deleteDialogBtn__inv deleteDialogBtnYes__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={handleDeleteOrder}
            >
              Remove
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isChangeOrderStatusDialogOpen}
        onClose={handleChangeOrderStatusDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Order Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ marginBottom: "8px", marginTop: "16px" }}>Status</div>
            <FormControl className="textField__AddInventory">
              {selectedOrderStatus === "" && (
                <InputLabel sx={{ opacity: 0.6 }}>
                  Select Order Status
                </InputLabel>
              )}

              {selectedOrder.order_type === "Card" || selectedOrder.order_type === "Cash" ? (
                <ChangeOrderStatus
                  setSelectedOrderStatus={setSelectedOrderStatus}
                  selectedOrderStatus={selectedOrderStatus}
                  orderStatus={["Cancelled"]}
                />
              ) : true ? (
                <ChangeOrderStatus
                  setSelectedOrderStatus={setSelectedOrderStatus}
                  selectedOrderStatus={selectedOrderStatus}
                  orderStatus={["Cancelled"]}
                />
              ) : null}
            </FormControl>

            <div style={{ marginBottom: "8px", marginTop: "16px" }}>
              Description
            </div>

            <CustomTextField
              multiline
              rows={4}
              style={{ width: "" }}
              value={orderDescription}
              onChange={changeOrderDescription}
              name="description"
              id="description"
              key="description"
              placeholder="Write about product here"
              className="textField__AddInventory"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={handleChangeOrderStatusDialogClose}
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            onClick={handleChangeOrderStatus}
          >
            Change Status
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={"Dashboard>Orders Management>Orders"}
            paddingBottom={3}
          />

          <Grid container marginBottom={2}>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingRight={onlyXs ? "5px" : "10px"}
            >
              <Box className="i-title">Orders Management</Box>
            </Grid>
          </Grid>

          {true && (
            <Grid container marginBottom={2}>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
                display="flex"
                justifyContent={smDown ? "center" : "start"}
                paddingRight={onlyXs ? "5px" : "10px"}
                paddingTop={onlyXs ? 1 : 0}
              >
                <div className="order_sc__s">
                  <div className="order_sc__c ">
                    <Button
                      variant="contained"
                      className={`${
                        orderStatus === "All"
                          ? "add_order_btn__orders"
                          : "download_report_btn__orders"
                      }`}
                      style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                      onClick={() => {
                        setOrderStatus("All");
                      }}
                    >
                      <div>
                        <div>All Orders</div>
                        <div style={{ marginTop: "-10px" }}>
                          ({orderStatusCount.All})
                        </div>
                      </div>
                    </Button>
                  </div>

                  <div className="order_sc__c ">
                    <Button
                      variant="contained"
                      className={`${
                        orderStatus === "Paid"
                          ? "add_order_btn__orders"
                          : "download_report_btn__orders"
                      }`}
                      style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                      onClick={() => {
                        setOrderStatus("Paid");
                      }}
                    >
                      <div>
                        <div>Paid Orders</div>
                        <div style={{ marginTop: "-10px" }}>
                          ({orderStatusCount.Paid})
                        </div>
                      </div>
                    </Button>
                  </div>

                  <div className="order_sc__c ">
                    <Button
                      variant="contained"
                      className={`${
                        orderStatus === "Unpaid"
                          ? "add_order_btn__orders"
                          : "download_report_btn__orders"
                      }`}
                      style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                      onClick={() => {
                        setOrderStatus("Unpaid");
                      }}
                    >
                      <div>
                        <div>Unpaid Orders</div>
                        <div style={{ marginTop: "-10px" }}>
                          ({orderStatusCount.Unpaid})
                        </div>
                      </div>
                    </Button>
                  </div>

                  <div className="order_sc__c ">
                    <Button
                      variant="contained"
                      className={`${
                        orderStatus === "Returned"
                          ? "add_order_btn__orders"
                          : "download_report_btn__orders"
                      }`}
                      style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                      onClick={() => {
                        setOrderStatus("Returned");
                      }}
                    >
                      <div>
                        <div>Returned Orders</div>
                        <div style={{ marginTop: "-10px" }}>
                          ({orderStatusCount.Returned})
                        </div>
                      </div>
                    </Button>
                  </div>

                  <div className="order_sc__c ">
                    <Button
                      variant="contained"
                      className={`${
                        orderStatus === "Cancelled"
                          ? "add_order_btn__orders"
                          : "download_report_btn__orders"
                      }`}
                      onClick={() => {
                        setOrderStatus("Cancelled");
                      }}
                    >
                      <div>
                        <div>Cancelled Orders</div>
                        <div style={{ marginTop: "-10px" }}>
                          ({orderStatusCount.Cancelled})
                        </div>
                      </div>
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}

          {true && (
            <Grid container marginBottom={2}>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                item
                display="flex"
                justifyContent={smDown ? "start" : "start"}
                paddingRight={onlyXs ? "5px" : "10px"}
                paddingTop={onlyXs ? 1 : 0}
              >
                <Button
                  variant="contained"
                  className={`${
                    orderType === "All"
                      ? "add_order_btn__orders"
                      : "download_report_btn__orders"
                  }`}
                  onClick={() => {
                    setOrderType("All");
                  }}
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                >
                  All ({orderTypeCount.All})
                </Button>
                <Button
                  variant="contained"
                  className={`${
                    orderType === "Cash"
                      ? "add_order_btn__orders"
                      : "download_report_btn__orders"
                  }`}
                  onClick={() => {
                    setOrderType("Cash");
                  }}
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                >
                  Cash ({orderTypeCount.Cash})
                </Button>
                <Button
                  variant="contained"
                  className={`${
                    orderType === "Card"
                      ? "add_order_btn__orders"
                      : "download_report_btn__orders"
                  }`}
                  onClick={() => {
                    setOrderType("Card");
                  }}
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                >
                  Card ({orderTypeCount.Card})
                </Button>
                <Button
                  variant="contained"
                  className={`${
                    orderType === "Credit"
                      ? "add_order_btn__orders"
                      : "download_report_btn__orders"
                  }`}
                  onClick={() => {
                    setOrderType("Credit");
                  }}
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                >
                  Credit ({orderTypeCount.Credit})
                </Button>
                {onlyXs && (
                  <Button
                    variant="contained"
                    endIcon={
                      <svg
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.34156 0.5H10.6582C10.823 0.500691 10.9839 0.550237 11.1206 0.642372C11.2572 0.734508 11.3635 0.865096 11.426 1.01762C11.4884 1.17015 11.5042 1.33777 11.4714 1.49928C11.4386 1.66079 11.3586 1.80894 11.2416 1.925L6.59156 6.575C6.51409 6.65311 6.42192 6.7151 6.32037 6.75741C6.21882 6.79972 6.1099 6.8215 5.99989 6.8215C5.88988 6.8215 5.78096 6.79972 5.67941 6.75741C5.57786 6.7151 5.48569 6.65311 5.40823 6.575L0.758225 1.925C0.641207 1.80894 0.561231 1.66079 0.52841 1.49928C0.495589 1.33777 0.511397 1.17015 0.573835 1.01762C0.636273 0.865096 0.742537 0.734508 0.87919 0.642372C1.01584 0.550237 1.17675 0.500691 1.34156 0.5Z"
                          fill="black"
                        />
                      </svg>
                    }
                    className="download_report_btn__orders"
                    onClick={handleOpenDateMenu}
                  >
                    {typeof ordersDate === "string" ? ordersDate : "Custom"}
                  </Button>
                )}

                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={dateMenuElement}
                  open={isDateMenuOpen}
                  onClose={handleCloseDateMenu}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 6,
                      width: "20ch",
                      borderRadius: "10px",
                      boxShadow: "none",
                      border: "1px solid rgba(0,0,0,0.15)",
                    },
                  }}
                >
                  {["Today", "This Week", "This Month", "Custom"].map(
                    (option, oi) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={() => {
                          handleOrdersDateFilter(option);
                        }}
                        style={
                          oi + 1 < 4
                            ? {
                                borderBottom: "1px solid rgba(0,0,0,0.15)",
                                paddingTop: 0,
                                paddingBottom: 0,
                              }
                            : { paddingTop: 0, paddingBottom: 0 }
                        }
                      >
                        <Box marginTop={1} marginRight={2}>
                          {option}
                        </Box>
                      </MenuItem>
                    )
                  )}
                </Menu>
                {openDateRangePicker ? (
                  <Box position="absolute" top="20%" zIndex="9999">
                    <DateRangePicker
                      open={openDateRangePicker}
                      toggle={toggleDateRangerPicker}
                      onChange={(range) => handleDateRange(range)}
                    />
                  </Box>
                ) : null}
              </Grid>
              {!onlyXs && (
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  item
                  display="flex"
                  justifyContent={smDown ? "end" : "end"}
                  paddingRight={onlyXs ? "5px" : "10px"}
                  paddingTop={onlyXs ? 1 : 0}
                >
                  <Button
                    variant="contained"
                    endIcon={
                      <svg
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.34156 0.5H10.6582C10.823 0.500691 10.9839 0.550237 11.1206 0.642372C11.2572 0.734508 11.3635 0.865096 11.426 1.01762C11.4884 1.17015 11.5042 1.33777 11.4714 1.49928C11.4386 1.66079 11.3586 1.80894 11.2416 1.925L6.59156 6.575C6.51409 6.65311 6.42192 6.7151 6.32037 6.75741C6.21882 6.79972 6.1099 6.8215 5.99989 6.8215C5.88988 6.8215 5.78096 6.79972 5.67941 6.75741C5.57786 6.7151 5.48569 6.65311 5.40823 6.575L0.758225 1.925C0.641207 1.80894 0.561231 1.66079 0.52841 1.49928C0.495589 1.33777 0.511397 1.17015 0.573835 1.01762C0.636273 0.865096 0.742537 0.734508 0.87919 0.642372C1.01584 0.550237 1.17675 0.500691 1.34156 0.5Z"
                          fill="black"
                        />
                      </svg>
                    }
                    className="download_report_btn__orders"
                    onClick={handleOpenDateMenu}
                  >
                    {typeof ordersDate === "string" ? ordersDate : "Custom"}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}

          <Grid container marginBottom={2}>
            <Grid xl={6} lg={6} md={5} sm={12} xs={12} item paddingRight="5px">
              <SearchField
                onChange={updateProductList}
                tableDataCount={orders.length}
                totalAvailableDataCount={orderTypeCount.All}
              />
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={7}
              sm={12}
              xs={12}
              item
              display="flex"
              justifyContent={smDown ? "center" : "end"}
              paddingTop={smDown ? 1 : 0}
              paddingRight={smDown ? "0px" : "10px"}
            >
              <Button
                variant="contained"
                className="add_order_btn__orders"
                style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                onClick={handleNavigateToAddOrder}
              >
                Add New Order
              </Button>

              <ProtectComponent
                id="download-all-btn"
                componentLocation={true}
                componentNavigate={false}
                component={
                  <Button
                    onClick={handlePrintAllOrders}
                    variant="contained"
                    endIcon={
                      <svg
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.34156 0.5H10.6582C10.823 0.500691 10.9839 0.550237 11.1206 0.642372C11.2572 0.734508 11.3635 0.865096 11.426 1.01762C11.4884 1.17015 11.5042 1.33777 11.4714 1.49928C11.4386 1.66079 11.3586 1.80894 11.2416 1.925L6.59156 6.575C6.51409 6.65311 6.42192 6.7151 6.32037 6.75741C6.21882 6.79972 6.1099 6.8215 5.99989 6.8215C5.88988 6.8215 5.78096 6.79972 5.67941 6.75741C5.57786 6.7151 5.48569 6.65311 5.40823 6.575L0.758225 1.925C0.641207 1.80894 0.561231 1.66079 0.52841 1.49928C0.495589 1.33777 0.511397 1.17015 0.573835 1.01762C0.636273 0.865096 0.742537 0.734508 0.87919 0.642372C1.01584 0.550237 1.17675 0.500691 1.34156 0.5Z"
                          fill="black"
                        />
                      </svg>
                    }
                    className="download_report_btn__orders"
                  >
                    {onlyXs || onlyMd ? (
                      <div>
                        <div>Download</div>
                        <div style={{ marginTop: "-8px" }}>Report</div>
                      </div>
                    ) : (
                      "Download Report"
                    )}
                  </Button>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CustomTable
            view={handleViewOrder}
            edit={handleEditOrder}
            delete={openDeleteOrderDialog}
            print={handlePrintSingleOrder}
            status={handleOrderStatus}
            columns={columns}
            data={orders}
            options={options}
            firstRowMargin={true}
          />
        </Grid>
      </Grid>
    </>
  );
}

const ChangeOrderStatus = (props) => {
  return (
    <CustomTextField
      select
      value={props.selectedOrderStatus}
      onChange={(e) => {
        props.setSelectedOrderStatus(e.target.value);
      }}
      name="order_status"
      id="order_status"
      key="order_status"
    >
      {props.orderStatus.map((value, index) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </CustomTextField>
  );
};

export default Orders;
