import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TableCell,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Menu,
} from "@mui/material";
import { Textfit } from "react-textfit";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { styled } from "@mui/material/styles";
import "./Expense.css";
import { deleteTransaction } from "../../api/ExpenseAPI";
import CustomTable from "../../widgets/CustomTable";
import DeleteDialog from "../../widgets/DeleteDialog";
import { createTransaction, getAllTransaction } from "../../api/ExpenseAPI";
import { DateRangePicker } from "mui-daterange-picker";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import SearchField from "../../widgets/SearchField";
import { debounce } from "lodash";
import { ReactComponent as UtilityBank } from "../../assets/SVG/UtilityBank.svg";
import { ReactComponent as Salaries } from "../../assets/SVG/Salaries.svg";
import { ReactComponent as Purchasing } from "../../assets/SVG/Purchasing.svg";
import { ReactComponent as Tax } from "../../assets/SVG/Tax.svg";
import { ReactComponent as DownloadIcon } from "../../assets/SVG/DownloadIcon.svg";
import { ReactComponent as BalanceIcon } from "../../assets/SVG/BalanceSmallIcon.svg";
import { ReactComponent as DebitIcon } from "../../assets/SVG/DebitSmallIcon.svg";
import { ReactComponent as CreditIcon } from "../../assets/SVG/CreditSmallIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as CashIn } from "../../assets/SVG/CashInIcon.svg";
import { ReactComponent as CashOut } from "../../assets/SVG/CashOutIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/SVG/PlusIcon.svg";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
  ShopCashInCard,
  UtilityBillExpenseCard,
  SalaryExpenseCard,
  PurchasingExpenseCard,
  TaxExpenseCard,
  OthersExpenseCard,
} from "../../widgets/Cards";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SimpleMenu from "../../widgets/SimpleMenu";
const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "expense_amount",
    label: "Expense Amount",
    minWidth: 30,
    align: "left",
  },
  {
    id: "expense_description",
    label: "Expense Description",
    minWidth: 30,
    align: "left",
  },
  {
    id: "expense_type",
    label: "Expense Type",
    minWidth: 30,
    align: "left",
  },

  {
    id: "transaction_date",
    label: "Date",
    minWidth: 30,
    align: "left",
  },
];

const options = [
  {
    icon: (
      <div>
        <DeleteIcon />
      </div>
    ),
    label: "Delete",
    method: "delete",
    type: "icon",
  },
];

const transactionObject = {
  vendor_name: "",
  vendor_shop_name: "",
  transaction_amount: "",
  transaction_description: "",
  transaction_type: "",
  transaction_credit_order_id: "",
  vendor_id: "",
};
function AccountCashKhata() {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    location,
    onlyXs,
    onlyMd,
  } = useContext(GlobalData);

  const [addExpenseDialog, setAddExpenseDialog] = useState(false);
  const [transactionDate, setTransactionDate] = useState(null);
  const [menuAccountsDate, setMenuAccountsDate] = useState(null);
  const [accountsDate, setAccountsDate] = useState("This Month");

  const [searchText, setSearchText] = useState("");
  const [
    openDateRangePickerForAccounts,
    setOpenDateRangePickerForAccounts,
  ] = useState(false);
  const [dateRangeForAccounts, setDateRangeForAccounts] = useState({});

  const openMenuAccountsDate = Boolean(menuAccountsDate);
  const handleClickAccountsDate = (event) => {
    setMenuAccountsDate(event.currentTarget);
  };
  const handleCloseAccountsDate = () => {
    setMenuAccountsDate(null);
  };

  const toggleDateRangerPickerForAccounts = () =>
    setOpenDateRangePickerForAccounts(!openDateRangePickerForAccounts);

  const ITEM_HEIGHT = 48;

  const handleDateRange = (range) => {
    setDateRangeForAccounts(range);
    setAccountsDate(range);
    setTimeout(() => {
      toggleDateRangerPickerForAccounts();
    }, 500);
  };

  const handleDashboardDateFilter = (option) => {
    handleCloseAccountsDate();
    if (option === "Custom") {
      toggleDateRangerPickerForAccounts();
    } else {
      setAccountsDate(option);
    }
  };

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [vendor, setVendor] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [transactions, setTransactions] = useState([{}]);
  const [showForm, setShowForm] = useState(false);
  const [transactionData, setTransactionData] = useState(transactionObject);
  const [total_credit, setTotal_credit] = useState(0);
  const [total_debit, setTotal_debit] = useState(0);
  const [transaction_type, setTransaction_type] = useState("");
  const [vendorDescription, setVendorDescription] = useState("");
  const [totalCashInAmount, setTotalCashInAmount] = useState(0);
  const [totalUtilty, setTotalUtilty] = useState("");
  const [totalTax, setTax] = useState("");
  const [totalOther, setOther] = useState("");
  const [totalPurchasing, setPuchasing] = useState("");
  const [totalSalary, setSalary] = useState("");
  const [expenseData, setExpenseData] = useState("");

  const [selectedExpense, setSelectedExpense] = useState("Cash In");

  const [expenseAmount, setExpenseAmount] = useState(0);
  const [expenseDescription, setExpenseDescription] = useState("");
  const [openCashDialog, setOpenCashDialog] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(
    "total_cash_out_amount"
  );
  const [selectedTransaction_type, setSelectedTransaction_type] = useState(
    "Cash In"
  );
  const [menuTransaction_type, setMenuTransaction_type] = useState([
    "Cash In",
    "Purchase",
    "Tax",
    "Utility Bill",
    "Salary",
    "Others",
  ]);

  const transactionType = "Shop Cash";

  const openDeleteProductDialog = (transaction) => {
    setTransactionId(transaction.id);
    setDeleteProductDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    return today;
  };

  const openAddTransactionDialog = (value) => {
    console.log("value", value);
    setTransactionData({
      ...transactionData,
      transaction_type: value,
    });
    setShowForm(true);
  };

  const closeTransactionDialog = () => {
    setShowForm(false);
  };

  const handleTransactionInput = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setTransactionData({
      ...transactionData,
      [name]: value,
    });
    setTimeout(() => {
      let el = document.getElementById(name);
      if (el) {
        el.focus();
      }
    }, 10);
  };
  const changeOrderDescription = (e) => {
    setVendorDescription(e.target.value);

    setTimeout(() => {
      let el = document.getElementById(e.target.name);
      if (el) {
        el.focus();
        let text = el.value;
        el.value = "";
        el.value = text;
      }
    }, 10);
  };
  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };
  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const [allTransactions, setAllTransactions] = useState([]);

  const handleGetAccountsData = (pn) => {
    showLoader();
    let formData = new FormData();
    formData.append("per_page", process.env.REACT_APP_PER_PAGE);
    formData.append("page", pn);
    formData.append("date", JSON.stringify(accountsDate));
    formData.append("search_text", searchText);
    formData.append("transaction_type", transactionType);

    getAllTransaction(formData, (resp) => {
      if (resp.statusCode === 200) {
        setExpenseData(resp.successData);
        setTotalCashInAmount(resp.successData.total_cash_in_amount);
        setTotalUtilty(resp.successData.total_utility_bills_amount);
        setTax(resp.successData.total_tax_amount);
        setOther(resp.successData.total_others_amount);
        setPuchasing(resp.successData.total_purchasing_amount);
        setSalary(resp.successData.total_salaries_amount);
        //  setTotalTransactionsCount(resp.successData.expense_transactions_count);
        if (pn === 1) {
          setAllTransactions(resp.successData.all_transactions);
          hideLoader();
        } else {
          setAllTransactions((dt) => {
            dt.push(...resp.successData.all_transactions);
            hideLoader();
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const [totalTransactionsCount, setTotalTransactionsCount] = useState(0);

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    handleGetAccountsData(page);
  }, [page, searchText]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      handleGetAccountsData(page);
    }
  };

  useEffect(() => {
    updateDataUsingPageNumber();
  }, [accountsDate, searchText]);

  const handleDeleteTransaction = () => {
    showLoader();
    handleCloseDeleteDialog();
    const data = { transaction_id: transactionId };
    deleteTransaction(data, (resp) => {
      if (resp.statusCode === 200) {
        flashMessage("success", resp.message);
        updateDataUsingPageNumber();
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handleCashDialogOpen = (id) => {
    setTransactionDate(getTodayDate());
    setSelectedCardIndex(id);
    setOpenCashDialog(true);
  };

  const handleCashDialogClose = () => {
    setOpenCashDialog(false);
    setTransactionDate(null);
  };

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  const handleSaveExpense = () => {
    handleCashDialogClose();
    showLoader();
    let formData = new FormData();
    formData.append("expense_amount", expenseAmount);
    formData.append("expense_description", expenseDescription);
    formData.append("expense_type", selectedExpense);
    formData.append("transaction_type", "Shop Cash");

    var date = new Date(transactionDate);
    formData.append(
      "transaction_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    createTransaction(formData, (resp) => {
      if (resp.statusCode === 200) {
        setExpenseAmount(0);
        setExpenseDescription("");
        updateDataUsingPageNumber();
        setTransactionDate(null);
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        setTransactionDate(null);
        hideLoader();
      }
    });
  };

  const openMenuTransaction_type = Boolean(menuTransaction_type);
  const handleClickTransaction_type = (event) => {
    setMenuTransaction_type(event.currentTarget);
  };
  const handleCloseTransaction_type = () => {
    setMenuTransaction_type(null);
  };

  const handleTransaction_typeSelection = (option) => {
    handleCloseTransaction_type();
    setSelectedTransaction_type(option);
  };

  return (
    <>
      <DeleteDialog
        handleRemove={handleDeleteTransaction}
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteProductDialog}
        title={"Remove this Transaction?"}
        sub_title={
          "You are about to remove a transaction. Click on the “Remove” button to remove it."
        }
      />

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openCashDialog}
        onClose={handleCashDialogClose}
      >
        <DialogTitle>Add Expense</DialogTitle>
        <DialogContent>
          <Grid>
            <div justifyContent="flex-end" style={{ marginBottom: "5px" }}>
              Type
            </div>

            {/* <Transaction_typeMenu
              selectedTransaction_type={selectedTransaction_type}
              setSelectedTransaction_type={setSelectedTransaction_type}
              allTransactions={menuTransaction_type}
              className={"bnkmnu_btn__bank_ex"}
              width={"155px"}
              allTransactionOption={selectedTransaction_type}
            /> */}

            <SimpleMenu
              handleSetData={setSelectedExpense}
              data={[
                "Cash In",
                "Utility Bill",
                "Salary",
                "Purchase",
                "Tax",
                "Others",
              ]}
              selectedValue={selectedExpense}
              className={"bnkmnu_field__bank"}
              width={"268px"}
            />
          </Grid>

          <Box marginBottom="15px">
            <AmountTextField
              title={"Amount"}
              value={expenseAmount}
              onChange={setExpenseAmount}
            />
          </Box>
          <Box marginBottom="10px">Transaction Date</Box>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12} marginBottom="15px">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="bnkmnu_field__bank"
                value={transactionDate}
                onChange={(newValue) => {
                  setTransactionDate(newValue);
                }}
                renderInput={(params) => (
                  <CustomTextField
                    error={false}
                    {...params}
                    placeholder={"mm/dd/yyyy"}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <DescriptionTextField
            title={"Description"}
            value={expenseDescription}
            onChange={setExpenseDescription}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={handleCashDialogClose}
          >
            Cancel
          </Button>
          <Button className="add_order_btn__orders" onClick={handleSaveExpense}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container height="100vh" paddingRight="5px">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box>
            <NavigationBar path={"Dashboard>Expense Management>Expenses"} />
            <Grid container marginBottom={2} mt={5}>
              <Grid
                xl={6}
                lg={6}
                md={5}
                sm={12}
                xs={12}
                item
                paddingRight={onlyXs ? "5px" : "10px"}
              >
                <Box className="i-title">Expense Management</Box>
              </Grid>

              <Grid
                xl={6}
                lg={6}
                md={7}
                sm={12}
                xs={12}
                item
                display="flex"
                justifyContent={smDown ? "center" : "end"}
                paddingTop={smDown ? 1 : 0}
                paddingRight={smDown ? "0px" : "10px"}
              >
                <Button
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  className="download_report_btn__orders"
                  onClick={handleClickAccountsDate}
                >
                  {typeof accountsDate === "string" ? accountsDate : "Custom"}
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={menuAccountsDate}
                  open={openMenuAccountsDate}
                  onClose={handleCloseAccountsDate}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 6,
                      width: "20ch",
                      borderRadius: "10px",
                      boxShadow: "none",
                      border: "1px solid rgba(0,0,0,0.15)",
                    },
                  }}
                >
                  {["Today", "This Week", "This Month", "Custom"].map(
                    (option, oi) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={() => {
                          handleDashboardDateFilter(option);
                        }}
                        style={
                          oi + 1 < 4
                            ? {
                                borderBottom: "1px solid rgba(0,0,0,0.15)",
                                paddingTop: 0,
                                paddingBottom: 0,
                              }
                            : { paddingTop: 0, paddingBottom: 0 }
                        }
                      >
                        <Box marginTop={1} marginRight={2}>
                          {option}
                        </Box>
                      </MenuItem>
                    )
                  )}
                </Menu>
                {openDateRangePickerForAccounts ? (
                  <Box position="absolute" top="20%" zIndex="5">
                    <DateRangePicker
                      open={openDateRangePickerForAccounts}
                      toggle={toggleDateRangerPickerForAccounts}
                      onChange={(range) => handleDateRange(range)}
                    />
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Box>

          <Grid container>
            <Grid item xs>
              <ShopCashInCard width={"full"} amount={totalCashInAmount} />
            </Grid>
            <Grid item xs>
              <UtilityBillExpenseCard width={"full"} amount={totalUtilty} />
            </Grid>
            <Grid item xs>
              <SalaryExpenseCard width={"full"} amount={totalSalary} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <PurchasingExpenseCard width={"full"} amount={totalPurchasing} />
            </Grid>
            <Grid item xs>
              <TaxExpenseCard width={"full"} amount={totalTax} />
            </Grid>
            <Grid item xs>
              <OthersExpenseCard width={"full"} amount={totalOther} />
            </Grid>
          </Grid>

          <Grid container marginBottom={3} mt={3}>
            <Grid xl={6} lg={6} md={5} sm={12} xs={12} item paddingRight="5px">
              <SearchField
                onChange={updateProductList}
                tableDataCount={allTransactions.length}
                totalAvailableDataCount={totalTransactionsCount}
              />
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={7}
              sm={12}
              xs={12}
              item
              display="flex"
              justifyContent={smDown ? "center" : "end"}
              paddingTop={smDown ? 1 : 0}
              paddingRight={smDown ? "0px" : "10px"}
            >
              <Button
                variant="contained"
                className="add_order_btn__orders"
                style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                onClick={handleCashDialogOpen}
              >
                Add Expense
              </Button>

              <Button
                variant="contained"
                endIcon={<DownloadIcon />}
                className="download_report_btn__orders"
              >
                {onlyXs || onlyMd ? (
                  <div>
                    <div>Download</div>
                    <div style={{ marginTop: "-8px" }}>Report</div>
                  </div>
                ) : (
                  "Download Report"
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid container>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <CustomTable
                // view={handleViewProduct}
                // edit={handleEditProduct}
                delete={openDeleteProductDialog}
                rowRed={{
                  id: "expense_type",
                  value: "Cash In",
                  condition: "!=",
                }}
                options={options}
                columns={columns}
                data={allTransactions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.1)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.1)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.1)`,
      borderRadius: "10px",
    },
  },
});

function AmountTextField(props) {
  return (
    <>
      <div style={{ marginBottom: "8px", paddingLeft: "5px" }}>
        {props.title}
      </div>
      <CustomTextField
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        name={props.title}
        id={props.title}
        key={props.title}
        placeholder={props.title}
        className="textField__AddInventory"
      />
    </>
  );
}

function DescriptionTextField(props) {
  return (
    <>
      <div style={{ marginBottom: "8px", paddingLeft: "5px" }}>
        {props.title}
      </div>
      <CustomTextField
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        name="description"
        id="description"
        key="description"
        placeholder="Enter Description"
        className="textField__AddInventory"
        multiline
        rows={3}
      />
    </>
  );
}

export default AccountCashKhata;
