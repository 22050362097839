import React, { useState, useEffect, useContext } from "react";
import { decrypt } from "../middleware/Encryption";
import { GlobalData } from "../App";

const ProtectedComponent = (props) => {
  const { location, navigate, allowedModules, setAllowedModules } = useContext(
    GlobalData
  );
  const { component, id, componentLocation, componentNavigate } = props;
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      window.location.href = process.env.REACT_APP_FRONTEND_BASE_URL;
    } else {
      let encryptedPermissions = user.user_role.permissions;
      let decryptedPermissions = JSON.parse(decrypt(encryptedPermissions));
      if (componentLocation) {
        const getModuleObject = async () => {
          let mo;
          if (location.pathname === "/") {
            mo = decryptedPermissions[0];
          } else {
            mo = await decryptedPermissions.find(
              (o) => location.pathname.includes(o.module) && o.module !== "/"
            );
          }
          let po = await mo.permissions.find((po) => po.id === id);
          if (po == undefined) {
            setAuthenticated(true);
          } else {
            setAuthenticated(po.value);
          }
        };
        getModuleObject();
      } else {
        const getModuleObject = async () => {
          await decryptedPermissions.map((value, index) => {
            let mo = value;
            let po = mo.permissions.find((po) => po.id === id);
            if (po !== undefined ? po.value : false) {
              setAllowedModules((am) => {
                am.push(mo);
                return am;
              });
              setAuthenticated(po.value);
            }
          });
        };
        getModuleObject();
        if (componentNavigate) {
          let am = allowedModules;
          if (am[0] !== undefined) {
            let p = am[0].module;
            if (
              p !== location.pathname &&
              (location.pathname === "/" || location.pathname === "/login")
            ) {
              navigate("/" + p);
            }
          }
        }
      }
    }
  });
  return <>{authenticated ? component : null}</>;
};

export default ProtectedComponent;
