import "./Staff.css";
import {
  Grid,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import Button from "@mui/material/Button";
import { AiFillCaretDown } from "react-icons/ai";
import CustomTable from "../../widgets/CustomTable";
import SearchField from "../../widgets/SearchField";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { GlobalData } from "../../App";
import { debounce } from "lodash";
import NavigationBar from "../NavigationBar";
import DeleteDialog from "../../widgets/DeleteDialog";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import PointOfSaleRoundedIcon from "@mui/icons-material/PointOfSaleRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import {
  getAllStaffMembers,
  deleteStaffMember,
  getMemberDetailsById,
} from "../../api/StaffAPI";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import MainTitle from '../../widgets/MainTitle'
const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_name",
    label: "Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_phone",
    label: "Phone Number",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_address",
    label: "Address",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_salary",
    label: "Salary",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_nationality",
    label: "Nationality",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_cnic",
    label: "CNIC No.",
    minWidth: 30,
    align: "left",
  },
];
const options = [
  {
    icon: (
      <div>
        <EyeIcon />
      </div>
    ),
    label: "View Orders",
    method: "viewOrders",
    type: "icon",
  },

  {
    icon: (
      <div>
        <EyeIcon />
      </div>
    ),
    label: "View Khata",
    method: "view",
    type: "icon",
  },
  {
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.75 13.5H6.93C7.0287 13.5006 7.12655 13.4817 7.21793 13.4443C7.30931 13.407 7.39242 13.352 7.4625 13.2825L12.6525 8.08502L14.7825 6.00002C14.8528 5.93029 14.9086 5.84734 14.9467 5.75595C14.9847 5.66455 15.0043 5.56652 15.0043 5.46752C15.0043 5.36851 14.9847 5.27048 14.9467 5.17908C14.9086 5.08769 14.8528 5.00474 14.7825 4.93502L11.6025 1.71752C11.5328 1.64722 11.4498 1.59142 11.3584 1.55335C11.267 1.51527 11.169 1.49567 11.07 1.49567C10.971 1.49567 10.873 1.51527 10.7816 1.55335C10.6902 1.59142 10.6072 1.64722 10.5375 1.71752L8.4225 3.84002L3.2175 9.03752C3.14799 9.1076 3.09299 9.19071 3.05567 9.28209C3.01835 9.37346 2.99943 9.47131 3 9.57002V12.75C3 12.9489 3.07902 13.1397 3.21967 13.2803C3.36032 13.421 3.55109 13.5 3.75 13.5ZM11.07 3.30752L13.1925 5.43002L12.1275 6.49502L10.005 4.37252L11.07 3.30752ZM4.5 9.87752L8.9475 5.43002L11.07 7.55252L6.6225 12H4.5V9.87752ZM15.75 15H2.25C2.05109 15 1.86032 15.079 1.71967 15.2197C1.57902 15.3603 1.5 15.5511 1.5 15.75C1.5 15.9489 1.57902 16.1397 1.71967 16.2803C1.86032 16.421 2.05109 16.5 2.25 16.5H15.75C15.9489 16.5 16.1397 16.421 16.2803 16.2803C16.421 16.1397 16.5 15.9489 16.5 15.75C16.5 15.5511 16.421 15.3603 16.2803 15.2197C16.1397 15.079 15.9489 15 15.75 15Z"
          fill="rgba(30,39,56,1)"
        />
      </svg>
    ),
    label: "Edit Member",
    method: "edit",
    type: "icon",
  },
  {
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 15.1875C12.4173 15.1875 15.1875 12.4173 15.1875 9C15.1875 5.58274 12.4173 2.8125 9 2.8125C5.58274 2.8125 2.8125 5.58274 2.8125 9C2.8125 12.4173 5.58274 15.1875 9 15.1875Z"
          stroke="rgba(30,39,56,0.8)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.24805 13.8769L13.8543 5.27625"
          stroke="rgba(30,39,56,0.8)"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          d="M3.83008 12.7744L12.4813 4.12311"
          stroke="rgba(30,39,56,0.8)"
          stroke-miterlimit="10"
        />
      </svg>
    ),
    label: "Delete Member",
    method: "delete",
    type: "icon",
  },
];

function Staff(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
  } = useContext(GlobalData);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [staff, setStaff] = useState([]);
  const [staffCount, setStaffCount] = useState(0);
  const [member, setMember] = useState("");
  const [searchText, setSearchText] = useState("");
  const [deleteMemberDialog, setDeleteMemberDialog] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState("");

  const getAllMembers = (pn) => {
    let data = {
      search_text: searchText,
      per_page: process.env.REACT_APP_PER_PAGE,
      page: pn,
    };
    showLoader();
    getAllStaffMembers(data, (resp) => {
      if (resp.statusCode === 200) {
        setStaffCount(resp.successData.all_staff_members_count);
        if (pn === 1) {
          setStaff(resp.successData.all_staff_members);
          hideLoader();
        } else {
          setStaff((dt) => {
            dt.push(...resp.successData.all_staff_members);
            hideLoader();
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };
  const handleEditStaffMember = (member) => {
    navigate("/staff/add-new-staff-member", {
      state: { memberData: member.id },
    });
  };
  const handleViewStaffMember = (member) => {

    navigate("/staff/staff-member-khata", {
      state: { memberData: member.id },
    });
  };
  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateStaffList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const openDeleteMemberDialog = (member) => {
    console.log("this is Member", member);
    setCurrentMemberId(member.id);
    setDeleteMemberDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteMemberDialog(false);
  };

  const handleDeleteMember = () => {
    showLoader();
    const tempdata = { member_id: currentMemberId };
    deleteStaffMember(tempdata, (resp) => {
      if (resp.statusCode === 200) {
        flashMessage("success", resp.message);
        updateDataUsingPageNumber();
        setDeleteMemberDialog(false);
      } else {
        flashMessage("error", resp.message);
        setDeleteMemberDialog(false);
        hideLoader();
      }
    });
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    getAllMembers(page);
  }, [page]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllMembers(page);
    }
  };
  const handleViewMemberOrders = (member) => {
    navigate("/staff/orders", {
      state: { memberData: member },
    });
  };
  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText]);

  return (
    <Grid id="header__orderm" container>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <NavigationBar
          path={"Dashboard>Staff Management>Staff"}
          paddingBottom={3}
        />
<MainTitle title={"Staff Management"}/>
     

        <Grid container marginBottom={2}>
          <Grid
            xl={6}
            lg={6}
            md={smDown ? 7 : 5}
            sm={smDown ? 7 : 5}
            xs={12}
            item
            paddingRight="5px"
          >
            <SearchField
              onChange={updateStaffList}
              tableDataCount={staff.length}
              totalAvailableDataCount={staffCount}
            />
          </Grid>
          <Grid
            xl={6}
            lg={6}
            md={smDown ? 5 : 7}
            sm={smDown ? 5 : 7}
            xs={12}
            item
            display="flex"
            justifyContent={onlyXs ? "center" : "end"}
            paddingTop={onlyXs ? 1 : 0}
            paddingRight={smDown ? "0px" : "10px"}
          >
            <Button
              variant="contained"
              className="add_order_btn__orders"
              style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
              onClick={() => {
                navigate("/staff/add-new-staff-member");
              }}
            >
              Add New Staff Member
            </Button>
            <Button
              variant="contained"
              className="download_report_btn__orders"
              onClick={() => {
                navigate("/staff/staff-roles");
              }}
            >
              Staff Roles
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <CustomTable
              view={handleViewStaffMember}
              edit={handleEditStaffMember}
              delete={openDeleteMemberDialog}
              viewOrders={handleViewMemberOrders}
              columns={columns}
              data={staff}
              options={options}
            />
          </Grid>
        </Grid>
      </Grid>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteMemberDialog}
        handleRemove={handleDeleteMember}
        title={"Remove this Transaction?"}
        sub_title={
          "You are about to remove a transaction. Click on the “Remove” button to remove it."
        }
      />
    </Grid>
  );
}

export default Staff;
