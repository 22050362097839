import {
  Grid,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import Button from "@mui/material/Button";
import { AiFillCaretDown } from "react-icons/ai";
import SearchField from "../../widgets/SearchField";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import "./Notes.css";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const notesData = [
  {
    text: "It is a long established It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of",
    color: "lightblue",
  },
  {
    text: "It is a long established It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of",
    color: "lightpink",
  },
  {
    text: "It is a long established It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of",
    color: "lightyellow",
  },
];

const ObjectNotes = {
  notes:"",
};

function Notes() { 
  


  const [note , setNote] = useState(ObjectNotes);
  const Data = (value) => {
  
    setNote({
        ...note,
        ['notes'] : value,
    });
    
  };
 const MyClick = () => {
  console.log(note);
 };

  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
  } = useContext(GlobalData);

  return (
    <> 
   <Grid id="header__orderm" container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={"Dashboard>Notes Management>Notes"}
            paddingBottom={3}
          />

          <Grid container marginBottom={2}>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingRight={onlyXs ? "5px" : "10px"}
            >
              <Box className="i-title">Notes</Box>
            </Grid>
          </Grid>

          <Grid container marginBottom={2}>
            <Grid
              xl={6}
              lg={6}
              md={smDown ? 7 : 5}
              sm={smDown ? 7 : 5}
              xs={12}
              item
              paddingRight="5px"
            >
              <SearchField onChange={Data} />
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={smDown ? 5 : 7}
              sm={smDown ? 5 : 7}
              xs={12}
              item
              display="flex"
              justifyContent={onlyXs ? "center" : "end"}
              paddingTop={onlyXs ? 1 : 0}
              paddingRight={smDown ? "0px" : "10px"}
            >
              <Button
                variant="contained"
                className="add_order_btn__orders"
                style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                onClick={MyClick} >
                Add New Notes
              </Button>
              {/* <Dialog
        open={showForm}
        onClose={closeTransactionDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Create {transactionData.transaction_type} Transaction
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <CustomTextField
                value={transactionData.transaction_amount}
                onChange={handleTransactionInput}
                name={"transaction_amount"}
                id={"transaction_amount"}
                key={"transaction_amount"}
                placeholder={"Transaction Amount"}
                className={"textField__AddInventory"}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <CustomTextField
              value={transactionData.transaction_description}
              onChange={handleTransactionInput}
              multiline
              rows={3}
              name="transaction_description"
              id="transaction_description"
              key="transaction_description"
              placeholder="Description"
              className="textField__AddInventory"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={closeTransactionDialog}
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            onClick={handleAddTransaction}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog> */}

 
              <Button
                variant="contained"
                className="download_report_btn__orders"
                endIcon={
                  <AiFillCaretDown className="Drop_down__account" />
                }
                
              >
                Today 
              </Button>
            </Grid>
          </Grid>
    <Grid container>
    {notesData.map((object) => (
      <Box
        className="boxes__notes"
        style={{
          backgroundColor: object.color,
        }}
      >
        <Grid container mt={2}>
   <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="shoh__n_notes iconoo">
    Shop Name
   </Grid>
   <Grid item xl={6} lg={6} md={6} sm={6} xs={6} pr={1} justifyContent="end" display="flex">
    <EditIcon />
    <DeleteIcon />
   </Grid>
      </Grid>
        <Box mt={3}>{object.text}</Box>
      </Box>
      
    ))}
  </Grid>

        </Grid>
      </Grid>
  </>
  );
}
export default Notes;
