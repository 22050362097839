import "./ProductMultiLevelMenu.css";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  Paper,
  IconButton,
  InputBase,
  Chip,
} from "@mui/material";
import { GlobalData } from "../App";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { motion } from "framer-motion";
import { debounce } from "lodash";
import { getAllProducts } from "../api/InventoryAPI";

const ProductMultiLevelMenu = (props) => {
  const { baseUrl } = useContext(GlobalData);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const [isListOpen, setIsListOpen] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedImei, setSelectedImei] = useState("");

  const getAllData = (pn) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("search_text", searchText);
    formData.append("quantity_greater_than_zero", 1);
    formData.append("per_page", 4);
    formData.append("page", pn);
    formData.append("products", JSON.stringify(props.productsData));
    getAllProducts(formData, (resp) => {
      if (resp.statusCode === 200) {
        if (pn === 1) {
          setData(resp.successData.products);
        } else {
          setData((dt) => {
            dt.push(...resp.successData.products);
            setLoader(false);
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
      }
    });
  };

  const handleSetSearchText = (text) => {
    setSearchText(text);
  };

  const handleSearchTextDebounce = useMemo(() => {
    return debounce(handleSetSearchText, 300);
  }, []);

  // Use Effect

  useEffect(() => {
    getAllData(page);
  }, [page]);

  useEffect(() => {
    getAllData(1);
    setPage(1);
  }, [searchText]);

  useEffect(() => {
    setLoader(false);
  }, [data]);

  // Handle Page on Scroll

  const handleFetchInventory = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(handleFetchInventory);

  async function handleUpdateVariantSelection() {
    if (props.product.product !== "") {
      var pro = await data.filter((obj) => obj.id === selectedProduct.id)[0];
      setSelectedProduct(pro);
    }
    setSelectedVariant("");
    setSelectedImei("");
    setIsListOpen(true);
  }

  async function handleUpdateImeiSelection() {
    if (props.product.product !== "") {
      var pro = await data.filter((obj) => obj.id === selectedProduct.id)[0];
      var vr = await pro.variants.filter(
        (obj) => obj.id === selectedVariant.id
      )[0];
      setSelectedVariant(vr);
      setSelectedProduct(pro);
    }
    setSelectedImei("");
    setIsListOpen(true);
  }

  const handleUpdateProductSelection = () => {
    setSelectedVariant("");
    setSelectedProduct("");
    setSelectedImei("");
    setIsListOpen(true);
  };

  useEffect(() => {
    if (selectedVariant.imei === "" || selectedVariant.imei === null) {
      setIsListOpen(false);
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (selectedImei !== "" || selectedImei !== null) {
      setIsListOpen(false);
    }
  }, [selectedImei]);

  useEffect(() => {
    if (isListOpen) {
      getAllData(page);
    }
    if (selectedProduct !== "") {
      if (selectedVariant !== "") {
        if (selectedVariant.imei !== "" || selectedVariant.imei !== null) {
          if (selectedImei !== "") {
            props.setIsProductSelected(true);
            props.setProduct(selectedProduct, selectedVariant, selectedImei);
          } else {
            props.setIsProductSelected(true);
            props.setProduct(selectedProduct, selectedVariant, selectedImei);
          }
        } else {
          props.setIsProductSelected(true);
          props.setProduct(selectedProduct, selectedVariant, selectedImei);
        }
      } else {
        props.setIsProductSelected(false);
      }
    } else {
      props.setIsProductSelected(false);
    }
  }, [isListOpen]);

  useEffect(() => {
    if (props.product.product === "") {
      setSelectedProduct("");
      setSelectedVariant("");
      setSelectedImei("");
    } else {
      getAllData(page);
      setSelectedProduct(props.product.product);
      setSelectedVariant(props.product.variant);
      setSelectedImei(props.product.product_imei);
    }
  }, [props.product]);

  useEffect(() => {
    setIsListOpen(false);
    setSelectedProduct("");
    setSelectedVariant("");
    setSelectedImei("");
  }, [props.productsData]);

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
    if (product.variants.length === 1) {
      let variant = product.variants[0];
      setSelectedVariant(variant);
      if (variant.imei.length > 8) {
        if (variant.imei.split(",").length === 1) {
          setSelectedImei(variant.imei);
        }
      }
    }
  };

  const handleSelectVariant = (variant) => {
    setSelectedVariant(variant);
    if (variant.imei.length > 8) {
      if (variant.imei.split(",").length === 1) {
        setSelectedImei(variant.imei);
      }
    }
  };

  return (
    <Box className="con__mltm">
      <List className="list__mltm">
        <ListItem
          style={{
            paddingTop: `${
              isListOpen
                ? "20px"
                : `${selectedProduct.length !== 0 ? "10px" : "0"}`
            }`,
            paddingBottom: `${
              isListOpen
                ? "10px"
                : `${selectedProduct.length !== 0 ? "10px" : "0"}`
            }`,
            paddingLeft: `${
              isListOpen
                ? "20px"
                : `${selectedProduct.length !== 0 ? "10px" : "0"}`
            }`,
            paddingRight: `${
              isListOpen
                ? "20px"
                : `${selectedProduct.length !== 0 ? "10px" : "0"}`
            }`,
          }}
        >
          {selectedProduct.length === 0 ? (
            <SearchInput
              onChange={handleSearchTextDebounce}
              isListOpen={isListOpen}
              setIsListOpen={setIsListOpen}
            />
          ) : (
            <Box>
              <Chip
                style={{ marginRight: "5px" }}
                label={selectedProduct.name}
                variant="outlined"
                onClick={handleUpdateProductSelection}
              />
              {selectedVariant.length !== 0 ? (
                <Chip
                  label={`${selectedVariant.color}${
                    selectedVariant.storage !== "" &&
                    selectedVariant.storage !== null
                      ? ","
                      : ""
                  }${
                    selectedVariant.storage !== null
                      ? selectedVariant.storage
                      : ""
                  }${
                    selectedVariant.ram !== "" && selectedVariant.ram !== null
                      ? ","
                      : ""
                  }${selectedVariant.ram !== null ? selectedVariant.ram : ""}`}
                  variant="outlined"
                  onClick={handleUpdateVariantSelection}
                />
              ) : null}
              <br />
              {selectedImei.length !== 0 ? (
                <Chip
                  style={{ marginTop: "5px" }}
                  label={selectedImei}
                  variant="outlined"
                  onClick={handleUpdateImeiSelection}
                />
              ) : null}
            </Box>
          )}
        </ListItem>
        <motion.div
          animate={{
            height: isListOpen ? "170px" : "0px",
            transition: {
              duration: 1,
              type: "spring",
              damping: 15,
            },
          }}
        >
          <List
            style={{
              height: `${isListOpen ? "150px" : "0"}`,
              display: `${isListOpen ? "block" : "none"}`,
            }}
            className="list_data__mltm"
            ref={scrollRefInventoryList}
          >
            {selectedProduct.length === 0 &&
              data.map((item, index) => (
                <ListItem
                  onClick={() => {
                    handleSelectProduct(item);
                  }}
                  key={index}
                  className={`list_item__mltm`}
                >
                  <Box
                    className={`${
                      index + 1 < data.length ? "btb_item__mltm" : ""
                    }`}
                  >
                    <Box display="flex">
                      <ListItemAvatar>
                        <img
                          alt=""
                          src={baseUrl + item.image}
                          width="30px"
                          height="30px"
                          style={{
                            borderRadius: "5px",
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box className="item_text__mltm">
                            {item.name} ({item.quantity})
                          </Box>
                        }
                        secondary=""
                      />
                    </Box>
                  </Box>
                </ListItem>
              ))}
            {selectedProduct.length !== 0 &&
              selectedVariant.length === 0 &&
              selectedProduct.variants.map((variant, index) => (
                <>
                  {variant.quantity > 0 && (
                    <ListItem
                      onClick={() => {
                        handleSelectVariant(variant);
                      }}
                      key={index}
                      className={`list_item__mltm`}
                    >
                      <Box
                        className={`${
                          index + 1 < data.length ? "btb_item__mltm" : ""
                        }`}
                      >
                        <Box display="flex">
                          <ListItemText
                            primary={
                              <Box className="item_text__mltm">{`${
                                variant.color
                              }${
                                variant.storage !== "" &&
                                variant.storage !== null
                                  ? ","
                                  : ""
                              }${
                                variant.storage !== null ? variant.storage : ""
                              }${
                                variant.ram !== "" && variant.ram !== null
                                  ? ","
                                  : ""
                              }${variant.ram !== null ? variant.ram : ""} ( ${
                                variant.min_sale_price
                              } AED ) x ${variant.quantity}`}</Box>
                            }
                            secondary=""
                          />
                        </Box>
                      </Box>
                    </ListItem>
                  )}
                </>
              ))}

            {selectedProduct.length !== 0 &&
              selectedVariant.length !== 0 &&
              selectedVariant.imei !== null &&
              selectedVariant.imei.split(",").map((imei, index) => (
                <ListItem
                  onClick={() => {
                    setSelectedImei(imei);
                  }}
                  key={index}
                  className={`list_item__mltm`}
                >
                  <Box
                    className={`${
                      index + 1 < data.length ? "btb_item__mltm" : ""
                    }`}
                  >
                    <Box display="flex">
                      <ListItemText
                        primary={<Box className="item_text__mltm">{imei}</Box>}
                        secondary=""
                      />
                    </Box>
                  </Box>
                </ListItem>
              ))}
            {loader ? (
              <ListItem>
                <Box marginLeft="45%" paddingTop="10px">
                  <CircularProgress size="30px" />
                </Box>
              </ListItem>
            ) : (
              <>
                {data.length === 0 ? (
                  <Box className="prontf__mltm">Product not found</Box>
                ) : null}
              </>
            )}
          </List>
        </motion.div>
      </List>
    </Box>
  );
};

export default ProductMultiLevelMenu;

const SearchInput = (props) => {
  const handleSearchField = (e) => {
    let search_text = e.target.value;
    props.onChange(search_text);
  };

  return (
    <Paper
      className="base_sf__mltm"
      style={{ backgroundColor: `${props.isListOpen ? "#f3f5f7" : "white"}` }}
      component="form"
    >
      {props.isListOpen ? (
        <IconButton sx={{ marginTop: "-1.5px" }} aria-label="menu">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_605_307)">
              <path
                d="M19.7561 18.5775L14.782 13.6033C16.1375 11.9455 16.804 9.83008 16.6435 7.69464C16.4831 5.55919 15.508 3.56711 13.92 2.13044C12.3319 0.69376 10.2525 -0.0775884 8.11167 -0.0240619C5.97088 0.0294646 3.93256 0.903771 2.41832 2.41801C0.904076 3.93225 0.0297698 5.97058 -0.0237567 8.11137C-0.0772832 10.2522 0.694065 12.3316 2.13074 13.9197C3.56742 15.5077 5.5595 16.4828 7.69494 16.6432C9.83038 16.8037 11.9458 16.1372 13.6036 14.7817L18.5778 19.7558C18.735 19.9076 18.9455 19.9916 19.164 19.9897C19.3825 19.9878 19.5915 19.9002 19.746 19.7457C19.9005 19.5912 19.9881 19.3822 19.99 19.1637C19.9919 18.9452 19.9079 18.7347 19.7561 18.5775ZM8.33364 15C7.0151 15 5.72617 14.609 4.62984 13.8765C3.53351 13.1439 2.67903 12.1027 2.17444 10.8846C1.66986 9.66638 1.53784 8.32594 1.79507 7.03273C2.05231 5.73953 2.68724 4.55164 3.61959 3.61929C4.55194 2.68694 5.73983 2.052 7.03304 1.79477C8.32624 1.53753 9.66669 1.66955 10.8849 2.17414C12.103 2.67872 13.1442 3.53321 13.8768 4.62953C14.6093 5.72586 15.0003 7.01479 15.0003 8.33333C14.9983 10.1008 14.2953 11.7954 13.0455 13.0452C11.7957 14.295 10.1011 14.998 8.33364 15Z"
                fill="#1E2738"
              />
            </g>
            <defs>
              <clipPath id="clip0_605_307">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </IconButton>
      ) : null}
      <InputBase
        className="input_sf__mltm"
        sx={{ ml: 1, flex: 1 }}
        placeholder={`${props.isListOpen ? "Search" : "Select Product"}`}
        onChange={handleSearchField}
        name="search"
        id="search"
        key="search"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        onClick={() => {
          props.setIsListOpen(true);
        }}
      />
    </Paper>
  );
};
